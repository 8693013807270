import { Tabs } from 'antd';
import CompanyForm from 'src/components/account/company/CompanyForm';
import { useUserContext } from 'src/contexts/user-context';
import styles from './Account.module.scss';
import ProfileForm from 'src/components/account/profile/ProfileForm';

const Account = () => {
  const {
    userContextState: { isAdmin },
  } = useUserContext();

  return (
    <Tabs
      className={styles.container}
      tabPosition="left"
      size="small"
      defaultActiveKey="1"
      destroyInactiveTabPane
      items={[
        {
          label: 'My profile',
          children: <ProfileForm />,
          className: styles.component,
          key: '1',
        },
        ...(isAdmin
          ? [
              {
                label: 'My company',
                children: <CompanyForm />,
                className: styles.component,
                key: '2',
              },
            ]
          : []),
        {
          label: 'Password',
          children: 'Password',
          key: '3',
        },
        {
          label: 'Notifications',
          children: 'Notifications',
          key: '4',
        },
        {
          label: 'Language & region',
          children: 'Language & region',
          key: '5',
        },
        {
          label: 'Privacy & security',
          children: 'Privacy & security',
          key: '6',
        },
      ]}
    />
  );
};

export default Account;
