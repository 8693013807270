import { useEffect } from 'react';
import { useUserContext } from 'src/contexts/user-context';
import useIdentity from './use-identity';
import { UserRoles } from 'src/types/general';
import { useNavigate } from 'react-router-dom';
import { ProtectedRoutes } from 'src/constants/routes';

const useAdminRoute = () => {
  const {
    userContextState: { isAdmin },
  } = useUserContext();

  const { getPermissions } = useIdentity();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin) {
      getPermissions().then((res) => {
        const permissionsList = res.result?.data;

        if (permissionsList) {
          const hasAdminRole = permissionsList
            .map((el) => el.name)
            .includes(UserRoles.ADMIN);

          if (!hasAdminRole) {
            navigate(ProtectedRoutes.DASHBOARD);
          }
        }
      });
    }
  }, [isAdmin]);
};

export default useAdminRoute;
