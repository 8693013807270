import { Button, Card, Divider, Statistic, Typography, theme } from 'antd';
import { IconAdd } from 'src/components/icons/add/IconAdd';
import { IconSync } from 'src/components/icons/sync/IconSync';
import useFiles from 'src/hooks/use-files';
import { IImportValidationResult } from 'src/types/general';
import { ImportEntity, ImportStatus } from 'src/types/import';
import styles from './ImportModalThirdStep.module.scss';
import { IconDownload } from 'src/components/icons/download/IconDownload';
import clsx from 'clsx';

type TImportModalThirdStepProps = Omit<IImportValidationResult, 'id'> & {
  className?: string;
  importId: string | null;
  status: ImportStatus;
  mode: ImportEntity;
  error?: {
    created: number;
    updated: number;
  };
};

const ImportModalThirdStep: React.FC<TImportModalThirdStepProps> = ({
  className,
  importId,
  status,
  totalEntities,
  entitiesToCreate,
  entitiesToUpdate,
  failedEntities,
  issues,
  error,
  mode,
}) => {
  const {
    token: { colorError, colorTextTertiary },
  } = theme.useToken();
  const { downloadErrors } = useFiles();

  const createTitle = error
    ? `${error.created} / ${entitiesToCreate}`
    : entitiesToCreate;
  const updateTitle = error
    ? `${error.updated} / ${entitiesToUpdate}`
    : entitiesToUpdate;

  const isError = status === ImportStatus.FAILED_VALIDATION;

  return (
    <div className={clsx(styles.component, className)}>
      <div className={styles.container}>
        {!error && (
          <Card className={styles.card} bordered={false} hoverable={false}>
            <Statistic
              title="Total Products"
              value={totalEntities}
              valueStyle={{ color: colorTextTertiary }}
            />
          </Card>
        )}
        <Card className={styles.card} bordered={false} hoverable={false}>
          <Statistic
            formatter={(value) => (
              <span title={value as string} className={styles.statistic}>
                {value}
              </span>
            )}
            title={`Create${error ? 'd' : ''}`}
            value={createTitle}
            prefix={<IconAdd />}
          />
        </Card>
        <Card className={styles.card} bordered={false} hoverable={false}>
          <Statistic
            className={styles.statistic}
            formatter={(value) => (
              <span title={value as string} className={styles.statistic}>
                {value}
              </span>
            )}
            title={`Update${error ? 'd' : ''}`}
            value={updateTitle}
            prefix={<IconSync />}
          />
        </Card>
      </div>
      {isError && (
        <Card
          title="Issues"
          extra={
            <Button
              icon={<IconDownload width="14" height="14" />}
              danger
              size="small"
              type="link"
              onClick={() => {
                if (!importId) {
                  return;
                }

                downloadErrors(importId, mode);
              }}
            >
              Download Issues Report
            </Button>
          }
          className={styles.card}
          bordered={false}
          hoverable={false}
        >
          <div className={styles.body}>
            <Statistic
              title="Products"
              value={failedEntities}
              valueStyle={{ color: colorError }}
            />
            <Statistic
              title="Issues"
              value={issues}
              valueStyle={{ color: colorError }}
            />
            <Divider type="vertical" className={styles.divider} />
            <Typography.Text className={styles.errorDescription}>
              Please download the report to correct all issues listed there,
              then re-upload updated file to continue.
            </Typography.Text>
          </div>
        </Card>
      )}
    </div>
  );
};

export default ImportModalThirdStep;
