import {
  Alert,
  Button,
  ConfigProvider,
  Form,
  Input,
  Typography,
  theme,
  type FormInstance,
} from 'antd';

import clsx from 'clsx';
import { EMAIL_REGEX } from 'src/constants/regex';
import { INCORECT_EMAIL, REQUIRED } from 'src/constants/validation';
import FormItem from '../../form-item/FormItem';
import styles from './SignInForm.module.scss';

const { Text } = Typography;

export type TSignInFormProps = {
  step: number;
  form: FormInstance<unknown>;
  company: string;
  email: string;
  password: string;
  submit: () => void;
  postError: string;
  loading: boolean;
  setStep: (value: number) => void;
  resetEmail: string;
  submitLabel: string;
  description: string;
  resendEmail: () => void;
};

const SignInForm = ({
  step,
  form,
  company,
  email,
  password,
  submit,
  postError,
  loading,
  setStep,
  resetEmail,
  submitLabel,
  description,
  resendEmail,
}: TSignInFormProps) => {
  const {
    token: { marginXS, margin, colorErrorBg, colorErrorBorder },
  } = theme.useToken();
  return (
    <div>
      <div style={{ gap: margin }} className={styles.container}>
        {!!description && (
          <Text className={styles.description}>{description}</Text>
        )}
        <Form form={form} layout="vertical" requiredMark={false}>
          {step === 1 && (
            <FormItem
              label="Company"
              name="company"
              isFeedbackShown={!company}
              rules={[
                {
                  validator: (_, value) => {
                    if (!value.trim()) {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  },
                  message: REQUIRED,
                },
              ]}
              hasFeedback
            >
              <Input
                autoFocus
                size="large"
                suffix={!company ? undefined : <span />}
              />
            </FormItem>
          )}
          {step === 2 && (
            <>
              <FormItem
                label="Email"
                name="email"
                isFeedbackShown={!email}
                rules={[
                  { required: true, message: REQUIRED },
                  { max: 150 },
                  {
                    validator: async (_, value) => {
                      const isValid = EMAIL_REGEX.test(value as string);

                      if (!isValid) {
                        return Promise.reject(INCORECT_EMAIL);
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
                hasFeedback
              >
                <Input
                  autoFocus
                  size="large"
                  suffix={!email ? undefined : <span />}
                />
              </FormItem>
              <FormItem
                label="Password"
                name="password"
                className={styles.password}
                isFeedbackShown={!password}
                rules={[{ max: 64 }, { required: true, message: REQUIRED }]}
                hasFeedback
              >
                <Input.Password
                  size="large"
                  suffix={!password ? undefined : <span />}
                />
              </FormItem>
              <Button
                onClick={() => {
                  setStep(3);
                }}
                className={styles.link}
                size="small"
                type="link"
              >
                Forgot password?
              </Button>
            </>
          )}
          {step === 3 && (
            <FormItem
              label="Email"
              name="resetEmail"
              isFeedbackShown={!resetEmail}
              rules={[
                { required: true, message: REQUIRED },
                { max: 150 },
                {
                  validator: async (_, value) => {
                    const isValid = EMAIL_REGEX.test(value as string);

                    if (!isValid) {
                      return Promise.reject(INCORECT_EMAIL);
                    }

                    return Promise.resolve();
                  },
                },
              ]}
              hasFeedback
            >
              <Input
                autoFocus
                size="large"
                suffix={!resetEmail ? undefined : <span />}
              />
            </FormItem>
          )}
          {step === 4 && (
            <div className={clsx(styles.container, styles.gap)}>
              <Text className={styles.description}>
                We sent a password reset link to{' '}
                <Text className={styles.email}>{resetEmail}</Text>. Please check
                your email and follow the instructions.
              </Text>
              <Text className={styles.description}>
                Did not receive the email within a few minutes?
                <Button onClick={resendEmail} size="small" type="link">
                  Click to resend
                </Button>
              </Text>
            </div>
          )}
          {postError && (
            <ConfigProvider
              theme={{
                token: {
                  colorWarningBg: colorErrorBg,
                  colorWarningBorder: colorErrorBorder,
                },
              }}
            >
              <Alert
                style={{
                  marginBottom: margin,
                }}
                className={styles.error}
                showIcon
                message={postError}
                type="warning"
              />
            </ConfigProvider>
          )}
          {!!submitLabel && (
            <Button
              style={{
                marginTop: marginXS,
              }}
              className={styles.submit}
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
              onClick={submit}
            >
              {submitLabel}
            </Button>
          )}
        </Form>
      </div>
    </div>
  );
};

export default SignInForm;
