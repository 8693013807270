import { Form } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProtectedRoutes } from 'src/constants/routes';
import { SignUpFormFields } from 'src/constants/sign-up';
import {
  REFRESH_TOKEN,
  UserContextActions,
  useUserContext,
} from 'src/contexts/user-context';
import { getErrorText } from 'src/utils/get-error-text';
import useIdentity from './use-identity';
import { TGetAccessTokenResponse } from 'src/types/backend';
import { TResponse, TValidationError } from 'src/contexts/network-context';

export type TSignUpForm = Record<SignUpFormFields, string>;

const useSignUpForm = (
  invitationId: string,
  email: string,
  initialStep = 1,
  companyName: string | null
) => {
  const { userContextDispatch } = useUserContext();
  const { registerUser, registerCompany } = useIdentity();
  const navigate = useNavigate();

  const [step, setStep] = useState(initialStep);
  const [loading, setLoading] = useState(false);
  const [postError, setPostError] = useState('');
  const [mounted, setMounted] = useState(false);
  const [mountedStep, setMountedStep] = useState(initialStep);
  const [validationStatus, setValidationStatus] = useState({
    minLength: false,
    hasDigitOrSymbol: false,
    hasUpperLower: false,
  });
  const [form] = Form.useForm<TSignUpForm>();
  const company = Form.useWatch(SignUpFormFields.COMPANY, form);
  const industry = Form.useWatch(SignUpFormFields.INDUSTRY, form);
  const country = Form.useWatch(SignUpFormFields.COUNTRY, form);
  const firstName = Form.useWatch(SignUpFormFields.FIRST_NAME, form);
  const lastName = Form.useWatch(SignUpFormFields.LAST_NAME, form);
  const password = Form.useWatch(SignUpFormFields.PASSWORD, form);
  const passwordConfirm = Form.useWatch(
    SignUpFormFields.PASSWORD_CONFIRM,
    form
  );

  const isPasswordValid = useMemo(
    () => Object.values(validationStatus).every(Boolean),
    [validationStatus]
  );

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) {
      setTimeout(() => {
        setMountedStep(step);
        setMounted(true);
      }, 225);
    }
  }, [mounted, step]);

  useEffect(() => {
    form.setFieldsValue({
      [SignUpFormFields.COMPANY]: companyName || '',
      [SignUpFormFields.EMAIL]: email,
    });
  }, []);

  const setStepWithTransition = (value: number) => {
    setStep(value);
    setMounted(false);
  };

  const submit = () => {
    form
      .validateFields()
      .then(async () => {
        if (step === 2 && !isPasswordValid) {
          return;
        }
        setLoading(true);
        setPostError('');
        if (step === 1) {
          setStepWithTransition(2);
        } else {
          let result: TResponse<TGetAccessTokenResponse, TValidationError>;
          if (initialStep === 1) {
            result = await registerCompany({
              invitationId,
              email: email.toLowerCase(),
              password: password.trim(),
              firstName,
              lastName,
              companyName: company,
              country,
              industryId: industry,
            });
          } else {
            result = await registerUser({
              invitationId,
              email: email.toLowerCase(),
              password: password.trim(),
            });
          }

          if (result.errors?.response) {
            setPostError(getErrorText(result.errors.response.data.errors)[0]);
          } else if (result.result?.status === 200) {
            userContextDispatch({
              type: UserContextActions.SET_ACCESS_TOKEN,
              value: result.result.data.accessToken,
            });
            localStorage.setItem(
              REFRESH_TOKEN,
              result.result.data.refreshToken
            );
            navigate(ProtectedRoutes.HIVE);
          }
        }
        setLoading(false);
      })
      .catch((errr) => {
        console.error(errr);
      });
  };

  const goBack = () => {
    setStepWithTransition(1);
    setPostError('');
  };

  const validatePassword = (value: string) => {
    setValidationStatus({
      minLength: value.length >= 8,
      hasDigitOrSymbol: /[0-9!@#$%^&*]/.test(value),
      hasUpperLower: /[a-z]/.test(value) && /[A-Z]/.test(value),
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    validatePassword(value);
    if (passwordConfirm) {
      form.validateFields([SignUpFormFields.PASSWORD_CONFIRM]);
    }
  };

  const getValidationStatus = (status: boolean) => {
    if (!password) {
      return 'default';
    }
    return status ? 'success' : 'error';
  };

  return {
    step: mountedStep,
    goBack,
    loading,
    postError,
    form,
    company,
    password,
    submit,
    mounted,
    industry,
    country,
    firstName,
    lastName,
    passwordConfirm,
    isPasswordValid,
    getValidationStatus,
    validationStatus,
    handleChange,
  };
};

export default useSignUpForm;
