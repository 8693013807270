import Table from 'src/components/table/Table';
import { ImportEntity } from 'src/types/import';
import useSecondStep from './hooks/use-second-step';

type TImportModalSecondStepProps = {
  className?: string;
  importId: string | null;
  setLoading: (value: boolean) => void;
  selectedValues: Record<string, string>;
  setSelectedValues: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  error: boolean;
  mode: ImportEntity;
};

const ImportModalSecondStep: React.FC<TImportModalSecondStepProps> = ({
  className,
  importId,
  setLoading,
  selectedValues,
  setSelectedValues,
  error,
  mode,
}) => {
  const { tableWidth, columns, parsedColumns } = useSecondStep({
    importId,
    setLoading,
    selectedValues,
    setSelectedValues,
    mode,
  });

  return (
    <Table
      className={className}
      dataSource={parsedColumns}
      columns={columns}
      emptyTextDescription={'No columns to map'}
      width={tableWidth}
      height={error ? '370px' : '426px'}
      isPagination={false}
    />
  );
};

export default ImportModalSecondStep;
