import { AxiosHeaders } from 'axios';
import dayjs from 'dayjs';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import { EXPORT_FAILED } from 'src/constants/validation';
import { useMessageContext } from 'src/contexts/message-context';
import {
  HTTP,
  TResponse,
  TValidationError,
  useNetworkContext,
} from 'src/contexts/network-context';
import {
  TAssignCustomerParams,
  TEditAssignedCustomerParams,
  TExportProductsParams,
  TGetProductCustomersParams,
  TGetProductCustomersResponse,
  TGetProductOfferDetailsResponse,
  TGetProductOfferPurchaseHistoryParams,
  TGetProductOfferPurchaseHistoryResponse,
  TGetProductResponse,
  TGetProductsParams,
  TGetProductsResponse,
  TGetSuppliersProductsParams,
  TGetSuppliersProductsResponse,
} from 'src/types/backend';
import { IEpd, IProduct } from 'src/types/general';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from 'src/utils/date-formatter';
import { downloadFile } from 'src/utils/download';
import { getParams, getParamsWithSearch } from 'src/utils/get-params';
import { getTimezone } from 'src/utils/get-timezone';

export type TDeleteProduct = (productId: string) => Promise<void>;
export type TGetProducts = (
  data: TGetProductsParams
) => Promise<TResponse<TGetProductsResponse, unknown>>;
export type TGetSuppliersProducts = (
  data: TGetSuppliersProductsParams
) => Promise<TResponse<TGetSuppliersProductsResponse, unknown>>;
export type TGetProduct = (
  productId: string
) => Promise<TResponse<TGetProductResponse, unknown>>;
export type TCreateProduct = (
  data: Partial<IProduct>
) => Promise<TResponse<unknown, TValidationError>>;
export type TUpdateProduct = (
  data: Partial<IProduct>
) => Promise<TResponse<unknown, TValidationError>>;
export type TUploadFile = (
  data: FormData
) => Promise<TResponse<unknown, unknown>>;
export type TDownloadFile = (
  fileName: string
) => Promise<TResponse<unknown, unknown>>;
export type TGetProductCustomers = (
  id: string,
  data: TGetProductCustomersParams
) => Promise<TResponse<TGetProductCustomersResponse, unknown>>;
export type TAssignCustomers = (
  productId: string,
  data: TAssignCustomerParams
) => Promise<TResponse<unknown, TValidationError>>;
export type TEditAssignedCustomer = (
  productId: string,
  offerId: string,
  data: TEditAssignedCustomerParams
) => Promise<TResponse<unknown, unknown>>;
export type TDeleteAssignedCustomer = (
  productId: string,
  offerId: string
) => Promise<TResponse<unknown, unknown>>;
export type TGetProductOfferDetails = (
  productOfferId: string
) => Promise<TResponse<TGetProductOfferDetailsResponse, unknown>>;
export type TGetProductOfferPurchaseHistory = (
  productOfferId: string,
  data: TGetProductOfferPurchaseHistoryParams
) => Promise<TResponse<TGetProductOfferPurchaseHistoryResponse, unknown>>;
export type TFetchFile = (
  link: string,
  productId: string
) => Promise<TResponse<IEpd, TValidationError>>;

const useProducts = () => {
  const { fetch } = useNetworkContext();
  const { showErrorMessage } = useMessageContext();

  const getProducts: TGetProducts = async (data) => {
    const result = await fetch<TGetProductsResponse, unknown>({
      url: '/product/paged',
      method: HTTP.GET,
      params: {
        Page: data.pagination?.current,
        PageSize: data.pagination?.pageSize,
        ...(data.sortField
          ? {
              SortBy:
                data.sortField === 'epdFile'
                  ? 'epdFile.fileName'
                  : data.sortField,
              Descending: data.sortOrder === SortOrder.DESC,
            }
          : {}),
        Search: data.search,
      },
    });

    return result;
  };

  const getSuppliersProducts: TGetSuppliersProducts = async (data) => {
    const params = getParamsWithSearch(data);
    if (data.filters) {
      data.filters['supplier.name']?.forEach((el) => {
        params.append('supplier.id', `${el}`);
      });
    }
    const result = await fetch<TGetSuppliersProductsResponse, unknown>({
      url: '/product/productOffers/paged',
      method: HTTP.GET,
      params,
    });

    return result;
  };

  const getProduct: TGetProduct = async (productId) => {
    const result = await fetch<TGetProductResponse, unknown>({
      url: `/product/${productId}`,
      method: HTTP.GET,
    });

    return result;
  };

  const createProduct: TCreateProduct = async (data) => {
    const result = await fetch<unknown, TValidationError>({
      url: '/product',
      method: HTTP.POST,
      data,
    });
    return result;
  };

  const uploadFile: TUploadFile = async (data) => {
    const result = await fetch({
      url: '/epd/upload',
      method: HTTP.POST,
      data,
    });
    return result;
  };

  const updateProduct: TUpdateProduct = async (data) => {
    const result = await fetch<unknown, TValidationError>({
      url: `/product/${data.id}`,
      method: HTTP.PUT,
      data,
    });
    return result;
  };

  const deleteProduct: TDeleteProduct = async (productId) => {
    await fetch({
      url: `/product/${productId}`,
      method: HTTP.DELETE,
    });
  };

  const getProductCustomers: TGetProductCustomers = async (id, data) => {
    const params = getParams(data);

    const result = await fetch<TGetProductCustomersResponse, unknown>({
      url: `/product/${id}/productOffers/paged`,
      method: HTTP.GET,
      params,
    });

    return result;
  };

  const assignCustomers: TAssignCustomers = async (productId, data) => {
    const result = await fetch<unknown, TValidationError>({
      url: `/product/${productId}/productOffers`,
      method: HTTP.POST,
      data,
    });

    return result;
  };

  const editAssignedCustomer: TEditAssignedCustomer = async (
    productId,
    offerId,
    data
  ) => {
    const result = await fetch({
      url: `/product/${productId}/productOffer/${offerId}`,
      method: HTTP.PUT,
      data,
    });

    return result;
  };

  const deleteAssignedCustomer: TDeleteAssignedCustomer = async (
    productId,
    offerId
  ) => {
    const result = await fetch({
      url: `/product/${productId}/productOffer/${offerId}`,
      method: HTTP.DELETE,
    });

    return result;
  };

  const getProductOfferDetails: TGetProductOfferDetails = async (
    productOfferId
  ) => {
    const result = await fetch<TGetProductOfferDetailsResponse, unknown>({
      url: `/product/productOffer/${productOfferId}`,
      method: HTTP.GET,
    });

    return result;
  };

  const getProductOfferPurchaseHistory: TGetProductOfferPurchaseHistory =
    async (productOfferId, data) => {
      const params = getParams(data);

      const result = await fetch<
        TGetProductOfferPurchaseHistoryResponse,
        unknown
      >({
        url: `/product/productOffer/${productOfferId}/purchasedItems/paged`,
        method: HTTP.GET,
        params,
      });

      return result;
    };

  const fetchFile: TFetchFile = async (link, productId) => {
    const result = await fetch<IEpd, TValidationError>({
      url: '/epd/fetch',
      method: HTTP.POST,
      data: {
        link,
        productId,
      },
    });
    return result;
  };

  const exportProducts = async (data: TExportProductsParams) => {
    const params = getParams(data);
    fetch<Blob, unknown>({
      url: `/product/export`,
      responseType: 'blob',
      method: HTTP.POST,
      params,
      headers: new AxiosHeaders({
        'X-User-Timezone': getTimezone(),
      }),
    })
      .then((response) => {
        const company = response.result?.headers['company-name'];

        const date = dayjs();
        downloadFile(
          response,
          `${company}_ProductCatalog_${date.format(DEFAULT_DATE_FORMAT)}_${date.format(DEFAULT_TIME_FORMAT)}`
        );
      })
      .catch(() => {
        showErrorMessage(EXPORT_FAILED);
      });
  };

  return {
    getProducts,
    deleteProduct,
    getProduct,
    createProduct,
    updateProduct,
    uploadFile,
    getProductCustomers,
    assignCustomers,
    editAssignedCustomer,
    deleteAssignedCustomer,
    getSuppliersProducts,
    getProductOfferDetails,
    getProductOfferPurchaseHistory,
    fetchFile,
    exportProducts,
  };
};

export default useProducts;
