import { Typography, theme } from 'antd';
import clsx from 'clsx';
import { Logo } from 'src/components/logo/Logo';
import useLoginForm from 'src/hooks/use-login-form';
import SignInForm from '../form/SignInForm';
import styles from './SignInModal.module.scss';
import SignInModalHeader from './header/SignInModalHeader';

const { Title } = Typography;

type TSignInModalProps = {
  className?: string;
};

const SignInModal = ({ className }: TSignInModalProps) => {
  const {
    token: {
      colorBgContainer,
      boxShadowTertiary,
      borderRadiusLG,
      paddingXL,
      paddingLG,
    },
  } = theme.useToken();

  const {
    step,
    goBack,
    loading,
    postError,
    form,
    company,
    email,
    password,
    submit,
    mounted,
    setStep,
    resetEmail,
    submitLabel,
    description,
    resendEmail,
  } = useLoginForm();

  const isLogoVisible = step === 1 || step === 2;

  const title = step === 3 ? 'Forgot password?' : 'Check your email';

  return (
    <div
      className={clsx(
        styles.container,
        {
          [styles.transitionIn]: mounted,
        },
        className
      )}
      style={{
        backgroundColor: colorBgContainer,
        boxShadow: boxShadowTertiary,
        borderRadius: borderRadiusLG,
        gap: paddingLG,
        padding: paddingXL,
      }}
    >
      <SignInModalHeader step={step} goBack={goBack} />
      <div className={styles.logoContainer}>
        {isLogoVisible ? (
          <>
            <Title level={3} className={clsx(styles.title, styles.withLogo)}>
              Welcome to
            </Title>
            <Logo className={styles.logo} width="188" height="47" />
          </>
        ) : (
          <Title level={3} className={styles.title}>
            {title}
          </Title>
        )}
      </div>
      <SignInForm
        step={step}
        form={form}
        company={company}
        email={email}
        password={password}
        resetEmail={resetEmail}
        submit={submit}
        postError={postError}
        loading={loading}
        setStep={setStep}
        submitLabel={submitLabel}
        description={description}
        resendEmail={resendEmail}
      />
    </div>
  );
};

export default SignInModal;
