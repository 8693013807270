import { InboxOutlined } from '@ant-design/icons';
import { Button, StepsProps, Typography } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import AutoUploadXLSXFile from 'src/components/table/file/with-xlsx-upload/AutoUploadXLSXFile';
import useFirstStep from './hooks/use-first-step';
import styles from './ImportModalFirstStep.module.scss';
import { useEffect } from 'react';
import { EXPORT_PRODUCTS, EXPORT_PRODUCT_OFFERS } from 'src/constants/local-storage';
import { ImportEntity } from 'src/types/import';

type TImportModalFirstStepProps = {
  className?: string;
  importId: string | null;
  progress: number;
  setStepWithReset: (step: number, status?: StepsProps['status']) => void;
  setProgress: (value: number) => void;
  setImportId: (value: string) => void;
  setDisabled: (value: boolean) => void;
  mode: ImportEntity;
  customerId?: string;
};

const ImportModalFirstStep: React.FC<TImportModalFirstStepProps> = ({
  className,
  importId,
  setStepWithReset,
  setImportId,
  setProgress,
  setDisabled,
  progress,
  mode,
  customerId,
}) => {
  const {
    uploadFile,
    onImportDelete,
    abortUploadXlsx,
    draggerProps,
    file,
    fileError,
    fileErrorMessage,
    fileDisabled,
    fileLoading,
  } = useFirstStep({
    importId,
    setProgress,
    setImportId,
    setStepWithReset,
    mode,
    customerId,
  });

  useEffect(() => {
    setDisabled(fileDisabled || fileLoading);
  }, [fileDisabled, fileLoading]);

  const isProductOffer = mode === ImportEntity.ProductOffer;

  if (file) {
    return (
      <AutoUploadXLSXFile
        className={className}
        file={file}
        onDelete={onImportDelete}
        disabled={fileDisabled}
        uploadFile={uploadFile}
        abortUpload={abortUploadXlsx}
        error={fileError}
        loading={fileLoading}
        errorMessage={fileErrorMessage}
        progress={progress}
      />
    );
  }

  return (
    <div className={className}>
      <Typography.Text className={styles.description}>
        Please{' '}
        <Button
          className={styles.warn}
          size="small"
          type="link"
          onClick={() => {
            // Note: make sure there's message listener for the respected export
            const broadcast = new BroadcastChannel(
              isProductOffer ? EXPORT_PRODUCT_OFFERS : EXPORT_PRODUCTS
            );
            broadcast.postMessage('export');
            broadcast.close();
          }}
        >
          export {isProductOffer ? 'assigned products' : 'product catalog'}{' '}
          (.xlsx)
        </Button>{' '}
        and use it as a template.
      </Typography.Text>

      <Dragger {...draggerProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Support for a single upload.</p>
      </Dragger>

      <Typography.Text className={styles.note}>
        Only .xlsx file allowed. Maximum size 3 MB
      </Typography.Text>
    </div>
  );
};

export default ImportModalFirstStep;
