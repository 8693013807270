import { useEffect } from 'react';
import { USER_BROADCAST } from 'src/constants/local-storage';
import { UserContextActions, useUserContext } from 'src/contexts/user-context';
import useProfile from './use-profile';

const useUserInfo = () => {
  const { userContextDispatch } = useUserContext();

  const { getUserInfo } = useProfile();

  useEffect(() => {
    const fetchUserInfo = async () => {
      userContextDispatch({
        type: UserContextActions.FETCH_USER,
      });

      const result = await getUserInfo();

      userContextDispatch({
        type: UserContextActions.SET_USER,
        value: result.result?.data,
      });
    };

    fetchUserInfo();

    const broadcast = new BroadcastChannel(USER_BROADCAST);
    broadcast.onmessage = fetchUserInfo;

    return () => {
      broadcast.close();
    };
  }, []);
};

export default useUserInfo;
