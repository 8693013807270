import { Button, Layout, Menu, theme } from 'antd';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconBee } from 'src/components/icons/bee/IconBee';
import { IconBulb } from 'src/components/icons/bulb/IconBulb';
import { IconCampaign } from 'src/components/icons/campaign/IconCampaign';
import { IconChevronLeft } from 'src/components/icons/chevron-left/IconChevronLeft';
import { IconDashboard } from 'src/components/icons/dashboard/IconDashboard';
import { IconDescription } from 'src/components/icons/description/IconDescription';
import { IconHandshake } from 'src/components/icons/handshake/IconHandshake';
import { IconHive } from 'src/components/icons/hive/IconHive';
import { IconHub } from 'src/components/icons/hub/IconHub';
import { IconInventory } from 'src/components/icons/inventory/IconInventory';
import { IconNature } from 'src/components/icons/nature/IconNature';
import { Logo } from 'src/components/logo/Logo';
import { LogoOnly } from 'src/components/logo/logo-only/LogoOnly';
import User from 'src/components/navigation/user/User';
import { ProtectedRoutes } from 'src/constants/routes';
import { useUserContext } from 'src/contexts/user-context';
import usePermissions from 'src/hooks/use-permissions';
import useUserInfo from 'src/hooks/use-user-info';
import styles from './NavigationBar.module.scss';

const { Sider } = Layout;

const NavigationBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { padding, paddingMD, sizeMD, boxShadowTertiary },
  } = theme.useToken();

  const sizeMDString = `${sizeMD}`;

  const {
    userContextState: { isAdmin },
  } = useUserContext();

  const navigate = useNavigate();
  const location = useLocation();

  usePermissions();
  useUserInfo();

  const defaultSelectedKeys = useMemo(() => {
    if (location.pathname === ProtectedRoutes.DASHBOARD) {
      return ['1'];
    }
    if (location.pathname.startsWith(ProtectedRoutes.HIVE)) {
      return ['2'];
    }
    if (location.pathname.startsWith(ProtectedRoutes.SUPPLIERS)) {
      return ['3'];
    }
    if (location.pathname.startsWith(ProtectedRoutes.CUSTOMERS)) {
      return ['4'];
    }
    if (location.pathname.startsWith(ProtectedRoutes.PRODUCTS)) {
      return ['5'];
    }
    if (location.pathname.startsWith(ProtectedRoutes.SOURCES)) {
      return ['6'];
    }
    if (location.pathname.startsWith(ProtectedRoutes.INSIGHTS)) {
      return ['7'];
    }
    if (location.pathname.startsWith(ProtectedRoutes.NEWS_AND_SUPPORT)) {
      return ['8'];
    }
    if (location.pathname.startsWith(ProtectedRoutes.HIVE_CONTROL_CENTRE)) {
      return ['9'];
    }
    return [];
  }, [location.pathname]);  

  const adminRoute = (element: ItemType<MenuItemType>) =>
    isAdmin ? [element] : [];

  return (
    <div className={styles.container}>
      <Sider
        className={styles.sider}
        collapsedWidth={60}
        width={210}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className={styles.elevated}>
          <div
            className={clsx(styles.logoContainer, {
              [styles.centered]: collapsed,
            })}
            style={{
              paddingLeft: padding,
              paddingRight: padding,
              paddingTop: paddingMD,
              paddingBottom: paddingMD,
            }}
          >
            {collapsed ? (
              <LogoOnly width="24" height="25" />
            ) : (
              <Logo width="96" height="18.5" />
            )}
          </div>
          <Menu
            inlineIndent={padding}
            mode="inline"
            selectedKeys={defaultSelectedKeys}
            items={[
              {
                key: '1',
                icon: (
                  <IconDashboard height={sizeMDString} width={sizeMDString} />
                ),
                label: 'Dashboard',
                onClick: () => {
                  navigate(ProtectedRoutes.DASHBOARD);
                },
              },
              {
                key: '2',
                icon: <IconHive height={sizeMDString} width={sizeMDString} />,
                label: 'My Hive',
                onClick: () => {
                  navigate(ProtectedRoutes.HIVE);
                },
              },
              {
                key: '3',
                icon: <IconHub height={sizeMDString} width={sizeMDString} />,
                label: 'My Suppliers',
                onClick: () => {
                  navigate(ProtectedRoutes.SUPPLIERS);
                },
              },
              {
                key: '4',
                icon: (
                  <IconHandshake height={sizeMDString} width={sizeMDString} />
                ),
                label: 'My Customers',
                onClick: () => {
                  navigate(ProtectedRoutes.CUSTOMERS);
                },
              },
              {
                key: '5',
                icon: (
                  <IconInventory height={sizeMDString} width={sizeMDString} />
                ),
                label: 'My Products',
                onClick: () => {
                  navigate(ProtectedRoutes.PRODUCTS);
                },
              },
              {
                key: '6',
                icon: (
                  <IconDescription height={sizeMDString} width={sizeMDString} />
                ),
                label: 'My Sources',
                onClick: () => {
                  navigate(ProtectedRoutes.SOURCES);
                },
              },
              {
                key: '7',
                icon: <IconBulb height={sizeMDString} width={sizeMDString} />,
                label: 'My Hive Insights',
                onClick: () => {
                  navigate(ProtectedRoutes.INSIGHTS);
                },
              },
            ]}
          />
        </div>

        {!collapsed && (
          <LogoOnly width="160" height="166" className={styles.illustration} />
        )}
        <div>
          <Menu
            inlineIndent={padding}
            selectable={false}
            mode="inline"
            selectedKeys={defaultSelectedKeys}
            className={styles.elevated}
            items={[
              {
                key: '8',
                icon: (
                  <IconCampaign height={sizeMDString} width={sizeMDString} />
                ),
                label: 'News and Support',
                onClick: () => {
                  navigate(ProtectedRoutes.NEWS_AND_SUPPORT);
                },
              },
              ...adminRoute({
                key: '9',
                icon: <IconNature height={sizeMDString} width={sizeMDString} />,
                label: 'Hive Control Centre',
                onClick: () => {
                  navigate(ProtectedRoutes.HIVE_CONTROL_CENTRE);
                },
              }),
            ]}
          />
          <User
            active={location.pathname.startsWith(ProtectedRoutes.ACCOUNT)}
            hidden={collapsed}
          />
        </div>
      </Sider>
      <Button
        type="default"
        icon={<IconChevronLeft />}
        onClick={() => setCollapsed(!collapsed)}
        className={clsx(styles.collapse, {
          [styles.rotate]: collapsed,
        })}
        style={{ boxShadow: boxShadowTertiary }}
      />
      <IconBee
        className={clsx(styles.bee, {
          [styles.rotate]: collapsed,
        })}
      />
    </div>
  );
};

export default NavigationBar;
