import dayjs from 'dayjs';

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

export const DEFAULT_TIME_FORMAT = 'hh:mm:ss A';

export const DEFAULT_DATE_N_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

type TFormatDateParams = {
  date?: Date | null;
  format?: string;
};

type TFormatStringToDateParams = {
  date?: string;
  format?: string;
};

export const formatDate = ({
  date,
  format = DEFAULT_DATE_FORMAT,
}: TFormatDateParams) => dayjs(date).format(format);

export const formatStringToDate = ({
  date,
  format = DEFAULT_DATE_FORMAT,
}: TFormatStringToDateParams) => dayjs(date, format).toDate();
