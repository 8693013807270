import React, {
  Dispatch,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useReducer,
  useState,
  type FunctionComponent,
} from 'react';
import Modal from 'src/components/modal/Modal';
import { BROADCAST_NAME } from 'src/constants/local-storage';
import useCompanies from 'src/hooks/use-companies';
import useProducts from 'src/hooks/use-products';
import useSources from 'src/hooks/use-sources';
import { useMessageContext } from './message-context';
import useUsers from 'src/hooks/use-users';
import { UserStatus } from 'src/constants/users';
import { ActionType } from '../types/actions';

export type TModal = {
  title: string;
  description: React.ReactNode;
  actionText: string;
  action: ActionType;
  dataId?: string;
  subDataId?: string;
  dataName?: string;
  danger?: boolean;
};

type TModalContextState = {
  modal: TModal | null;
};

export enum ModalContextActions {
  SHOW_MODAL = 'SHOW_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
}

export type TModalContextStateAction =
  | {
      type: ModalContextActions.CLOSE_MODAL;
    }
  | {
      type: ModalContextActions.SHOW_MODAL;
      value: TModal;
    };

export type TModalContextValue = {
  modalContextState: TModalContextState;
  modalContextDispatch: Dispatch<TModalContextStateAction>;
};

const modalContextReducer = (
  state: TModalContextState,
  action: TModalContextStateAction
) => {
  const { type } = action;

  switch (type) {
    case ModalContextActions.CLOSE_MODAL: {
      return {
        modal: null,
      };
    }
    case ModalContextActions.SHOW_MODAL: {
      if (typeof action.value === 'undefined') {
        return state;
      }

      return {
        modal: action.value,
      };
    }

    default:
      return state;
  }
};

const modalContexInitialState: TModalContextState = {
  modal: null,
};

const ModalContext = createContext<TModalContextValue>({
  modalContextState: modalContexInitialState,
  modalContextDispatch: () => null,
});

type ModalContextProviderProps = PropsWithChildren<{
  initialState?: TModalContextState;
}>;

export const ModalContextProvider: FunctionComponent<
  ModalContextProviderProps
> = ({ initialState = modalContexInitialState, children }): JSX.Element => {
  const { showInfoMessage, showSuccessMessage } = useMessageContext();
  const { deleteProduct, deleteAssignedCustomer } = useProducts();
  const { deleteInvoice } = useSources();
  const { decoupleSupplier, deleteAssignedProduct } = useCompanies();
  const { updateUserStatus, inviteUser } = useUsers();

  const [modalContextState, modalContextDispatch] = useReducer(
    modalContextReducer,
    { ...initialState }
  );

  const contextValue = useMemo(
    () => ({ modalContextState, modalContextDispatch }),
    [modalContextState, modalContextDispatch]
  );

  const [loading, setLoading] = useState(false);

  const modal = modalContextState.modal;

  const secondaryAction = () => {
    modalContextDispatch({
      type: ModalContextActions.CLOSE_MODAL,
    });
  };

  const primaryAction = async () => {
    if (!modal) {
      return;
    }

    setLoading(true);

    switch (modal.action) {
      case ActionType.ARCHIVE:
        if (modal.dataId) {
          await deleteProduct(modal.dataId);
          showInfoMessage(`Product "${modal.dataName}" archived`);
        }
        break;
      case ActionType.DECOUPLE_SUPPLIER:
        if (modal.dataId) {
          await decoupleSupplier(modal.dataId);
          showInfoMessage(`Supplier "${modal.dataName}" decoupled`);
        }
        break;
      case ActionType.UNASSIGN_CUSTOMERS_PRODUCT:
        if (modal.dataId && modal.subDataId) {
          await deleteAssignedProduct(modal.dataId, modal.subDataId);
          showInfoMessage(`Product "${modal.dataName}" unassigned`);
        }
        break;
      case ActionType.DECOUPLE_PRODUCTS_CUSTOMER:
        if (modal.dataId && modal.subDataId) {
          await deleteAssignedCustomer(modal.dataId, modal.subDataId);
          showInfoMessage(`Customer "${modal.dataName}" decoupled`);
        }
        break;
      case ActionType.DELETE_INVOICE:
        if (modal.dataId) {
          await deleteInvoice(modal.dataId);
          showInfoMessage(`Invoice "${modal.dataName}" deleted`);
        }
        break;
      case ActionType.DEACTIVATE_USER:
        if (modal.dataId && modal.subDataId) {
          await updateUserStatus({
            id: modal.dataId,
            status: Number(modal.subDataId) as UserStatus,
          });
          showInfoMessage('User deactivated');
        }
        break;
      case ActionType.INVITE_USER:
        if (modal.dataId && modal.subDataId) {
          await updateUserStatus({
            id: modal.dataId,
            status: Number(modal.subDataId) as UserStatus,
          });
          showSuccessMessage('Invitation sent');
        }
        break;
      case ActionType.RESEND_INVITATION:
        if (modal.dataId) {
          await inviteUser(modal.dataId);
          showSuccessMessage('Invitation sent');
        }
        break;
    }

    const broadcast = new BroadcastChannel(BROADCAST_NAME);
    broadcast.postMessage('refresh');
    broadcast.close();
    setLoading(false);
    secondaryAction();
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      <Modal
        modal={modal}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
        secondaryActionTitle="Cancel"
        primaryIsLoading={loading}
      />
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error(
      '"ModalContext" context is not in scope. Use this hook in a component that is wrapped with the <ModalContextProvider /> component.'
    );
  }

  return context;
};
