import { useEffect, useState } from 'react';
import useDebouncer from './use-debouncer';
import useUsers from './use-users';
import { DefaultOptionType } from 'antd/es/select';
import { Typography } from 'antd';
import styles from 'src/styles/actions.module.scss';

const useUserSelect = () => {
  const { getUsers } = useUsers();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [users, setUsers] = useState<DefaultOptionType[]>([]);

  useDebouncer(setDebouncedSearch, search);

  useEffect(() => {
    const fetchUsers = async () => {
      if (debouncedSearch && debouncedSearch.length < 3) {
        return;
      }

      setLoading(true);

      const users = await getUsers({
        pagination: {
          pageSize: 10,
          current: 1,
        },
        search: debouncedSearch,
        active: true,
      });

      if (users.result?.data) {
        setUsers(
          users.result.data.data.map((user) => {
            const fullName =
              user.fullName || `${user.firstName} ${user.lastName}`;
            return {
              value: user.id,
              label: (
                <div className={styles.filter}>
                  <Typography.Text className={styles.filterTitle}>
                    {fullName}
                  </Typography.Text>
                  <Typography.Text
                    className={styles.filterDescription}
                  >{`${user.jobTitle || '-'} | ${user.email || '-'}`}</Typography.Text>
                </div>
              ),
              title: fullName,
            };
          })
        );
      }

      setLoading(false);
    };
    fetchUsers();
  }, [debouncedSearch]);

  return { loading, setSearch, users };
};

export default useUserSelect;
