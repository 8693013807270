import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ResetPasswordValidationModal from 'src/components/reset-password/validation-modal/ResetPasswordValidationModal';
import { PublicRoutes } from 'src/constants/routes';
import useIdentity from 'src/hooks/use-identity';
import styles from './ResetPassword.module.scss';
import ResetPasswordModal from 'src/components/reset-password/modal/ResetPasswordModal';

const ResetPassword: React.FC = () => {
  const { validateResetToken } = useIdentity();

  const [searchParams] = useSearchParams();
  const passwordResetToken = searchParams.get('passwordResetToken');
  const email = searchParams.get('email');

  const navigate = useNavigate();

  const [validating, setValidating] = useState(true);
  const [failed, setFailed] = useState(false);
  const [company, setCompany] = useState<string | null>(null);

  useEffect(() => {
    const validate = async () => {
      if (!passwordResetToken || !email) {
        navigate(PublicRoutes.SIGNIN);
        return;
      }
      setValidating(true);
      const res = await validateResetToken(passwordResetToken, email);
      setValidating(false);
      if (!res.result?.data) {
        setFailed(true);
        return;
      }
      const validationInfo = res.result.data;
      if (!validationInfo.isValid) {
        setFailed(true);
      }
      setCompany(validationInfo.company);
    };

    validate();
  }, [passwordResetToken, email]);

  return (
    <div className={styles.container}>
      {validating && <Spin size="large" className={styles.spin} />}
      {!validating && failed && (
        <ResetPasswordValidationModal company={company} />
      )}
      {!validating && !failed && !!passwordResetToken && !!email && (
        <ResetPasswordModal resetToken={passwordResetToken} email={email} />
      )}
    </div>
  );
};

export default ResetPassword;
