import {
  Alert,
  Badge,
  BadgeProps,
  Button,
  ConfigProvider,
  Form,
  Input,
  theme,
  type FormInstance,
} from 'antd';

import clsx from 'clsx';
import { NOT_MATCHING, REQUIRED } from 'src/constants/validation';
import { TSignUpForm } from 'src/hooks/use-signup-form';
import FormItem from '../../form-item/FormItem';
import styles from './ResetPasswordForm.module.scss';
import { SignUpFormFields } from 'src/constants/sign-up';

export type TResetPasswordFormProps = {
  form: FormInstance<TSignUpForm>;
  password: string;
  passwordConfirm: string;
  submit: () => void;
  postError: string;
  loading: boolean;
  isPasswordValid: boolean;
  getValidationStatus: (value: boolean) => BadgeProps['status'];
  validationStatus: Record<string, boolean>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
};

const ResetPasswordForm = ({
  form,
  password,
  passwordConfirm,
  submit,
  postError,
  loading,
  handleChange,
  validationStatus,
  getValidationStatus,
  isPasswordValid,
}: TResetPasswordFormProps) => {
  const {
    token: { marginXS, margin, colorErrorBg, colorErrorBorder },
  } = theme.useToken();

  const isMatching = password === passwordConfirm;

  return (
    <Form form={form} layout="vertical" requiredMark={false}>
      <FormItem
        className={styles.noMargin}
        label="New password"
        name={SignUpFormFields.PASSWORD}
        validateStatus={
          isPasswordValid
            ? 'success'
            : typeof password === 'undefined'
              ? undefined
              : 'error'
        }
        isFeedbackShown={!password || !isPasswordValid || isMatching}
        rules={[{ required: true, message: REQUIRED }, { max: 64 }]}
        hasFeedback
      >
        <Input.Password
          size="large"
          autoFocus
          onChange={handleChange}
          suffix={password ? undefined : <span />}
        />
      </FormItem>
      <Badge
        className={clsx(styles.badge, styles.top, {
          [styles.inactive]: !password,
        })}
        status={getValidationStatus(validationStatus.minLength)}
        text="Minimum 8 characters"
      />
      <Badge
        className={clsx(styles.badge, {
          [styles.inactive]: !password,
        })}
        status={getValidationStatus(validationStatus.hasDigitOrSymbol)}
        text="Contains at least 1 digit (0-9) or special symbol"
      />
      <Badge
        className={clsx(styles.badge, styles.bottom, {
          [styles.inactive]: !password,
        })}
        status={getValidationStatus(validationStatus.hasUpperLower)}
        text="Contains both lower (a-z) and upper case letters (A-Z)"
      />
      <FormItem
        label="Confirm password"
        name={SignUpFormFields.PASSWORD_CONFIRM}
        isFeedbackShown={typeof passwordConfirm !== 'undefined' || isMatching}
        rules={[
          { required: true, message: REQUIRED },
          { max: 64 },
          {
            validator: (_, value) => {
              if (value === password || !value) {
                return Promise.resolve();
              }
              return Promise.reject();
            },
            message: NOT_MATCHING,
          },
        ]}
        hasFeedback
      >
        <Input.Password
          size="large"
          suffix={!passwordConfirm && !isMatching ? undefined : <span />}
        />
      </FormItem>

      {postError && (
        <ConfigProvider
          theme={{
            token: {
              colorWarningBg: colorErrorBg,
              colorWarningBorder: colorErrorBorder,
            },
          }}
        >
          <Alert
            style={{
              marginBottom: margin,
            }}
            className={styles.error}
            showIcon
            message={postError}
            type="warning"
          />
        </ConfigProvider>
      )}
      <Button
        style={{
          marginTop: marginXS,
        }}
        className={styles.submit}
        size="large"
        type="primary"
        loading={loading}
        onClick={submit}
      >
        Set New Password
      </Button>
    </Form>
  );
};

export default ResetPasswordForm;
