import { ImportEntity } from 'src/types/import';
import ModalWrapper from '../modal/wrapper/ModalWrapper';
import ImportModalContent from './content/ImportModalContent';

type TImportModalProps = {
  onClose: () => void;
  visible: boolean;
  mode: ImportEntity;
  customerId?: string;
};

const ImportModal: React.FC<TImportModalProps> = ({
  onClose,
  visible,
  mode,
  customerId,
}) => {
  return (
    <ModalWrapper
      visible={visible}
      close={onClose}
      title="Import products"
      width={532}
      maxHeight={640}
    >
      <ImportModalContent
        customerId={customerId}
        mode={mode}
        onClose={onClose}
      />
    </ModalWrapper>
  );
};

export default ImportModal;
