export const COMPANY_NOT_FOUND =
  'This company is not configured to use HivePix. Please check the name and try again, or contact your administrator.';

export const WRONG_CREDENTIALS = 'Wrong email or password.';

export const ACCESS_DENIED = 'Access denied. Please contact your administrator for assistance';

export const REQUIRED = 'Required field';

export const DUPLICATE_CUSTOMER = 'Duplicate customer';
export const DUPLICATE_PRODUCT = 'Duplicate product';

export const NO_PRODUCTS_PURCHASED = 'No products purchased';
export const NO_PRODUCTS_ADDED = 'No products added';
export const NO_PRODUCTS_ASSIGNED = 'No products assigned';

export const NO_RESULTS = 'No results found';

export const NO_SUPPLIERS_ADDED = 'No suppliers added';

export const NO_CUSTOMERS_ADDED = 'No customers added';

export const NO_INVOICES_UPLOADED = 'No invoices uploaded';

export const ONE_FILE = 'Upload at least 1 file';

export const NO_DATA_GENERATED = 'No data generated';

export const NO_PROJECT = 'No projects imported';

export const EXPORT_FAILED = 'Export failed.';

export const NO_USERS = 'No users created';

export const INCORRECT_URL = 'Incorrect URL format';

export const INCORECT_EMAIL = 'Incorrect email format';
export const EXISTING_EMAIL = 'Account with this email already exists';

export const NO_PARSED_RESULTS =
  'Invoice data is not recognised. Check the uploaded file';

export const NOT_MATCHING = 'Password does not match';

export const EXISTING_COMPANY = 'Company with this name already exists';

export const NO_REGION = 'No regions available for the selected country.';

export const RESET_FAIL = 'Failed to send email.';

export const IMPORT_FIELDS_REQUIRED =
  'At least Hive ID and Product name attributes should be mapped';
