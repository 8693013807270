import { useNavigate } from 'react-router-dom';
import Table from 'src/components/table/Table';
import TableHeader from 'src/components/table/header/TableHeader';
import { NO_CUSTOMERS_ADDED, NO_RESULTS } from 'src/constants/validation';
import useCustomersList from 'src/hooks/use-customers-list';
import { ProtectedRoutes, RouteTitle } from 'src/constants/routes';
import { TMappedCustomerRecord } from 'src/utils/map-companies-table';
import styles from './CustomerList.module.scss';
import { IconMail } from 'src/components/icons/mail/IconMail';
import InviteModal from 'src/components/invite-modal/InviteModal';
import { useState } from 'react';
import { useUserContext } from 'src/contexts/user-context';

const CustomerList = () => {
  const navigate = useNavigate();

  const {
    setSearch,
    columns,
    handleTableChange,
    loading,
    width,
    data,
    debouncedSearch,
    total,
    search,
    current,
  } = useCustomersList();

  const {
    userContextState: { isAdmin },
  } = useUserContext();

  const [invite, setInvite] = useState(false);

  return (
    <div>
      <TableHeader
        search={search}
        title={RouteTitle[ProtectedRoutes.CUSTOMERS]}
        bold={false}
        searchPlaceholder={'GLN, customer name, address'}
        setSearch={setSearch}
        onlySecondaryAction
        noActions={!isAdmin}
        secondaryActionTitle="Invite Customer"
        secondaryActionIcon={<IconMail height="16" width="16" />}
        secondaryAction={() => {
          setInvite(true);
        }}
      />
      <Table<TMappedCustomerRecord>
        emptyTextDescription={debouncedSearch ? NO_RESULTS : NO_CUSTOMERS_ADDED}
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        rowClassName={() => {
          return styles.interactive;
        }}
        onRow={(record) => ({
          onClick: () => {
            navigate(`${ProtectedRoutes.CUSTOMERS}/${record.id}`);
          },
        })}
        loading={loading}
        width={width}
        height={'calc(100vh - 264px)'}
        total={total}
        current={current}
      />
      <InviteModal
        isCustomer
        visible={invite}
        onClose={() => {
          setInvite(false);
        }}
      />
    </div>
  );
};

export default CustomerList;
