import { Empty, Input } from 'antd';
import AntSelect, { DefaultOptionType } from 'antd/es/select';
import { Country } from 'country-state-city';
import { useMemo, useState } from 'react';
import { isWindows } from 'src/constants/platform';
import { UserFormFields, userFormLabels } from 'src/constants/users';
import { NO_RESULTS } from 'src/constants/validation';
import { getDialCode } from 'src/utils/get-dial-code';
import FormItem from '../form-item/FormItem';
import Select from '../select/Select';
import Highlighter from '../table/highlighter/Highlighter';
import styles from './PhoneInput.module.scss';

type TPhoneInputProps = {
  max: number;
};

const PhoneInput = ({ max }: TPhoneInputProps) => {
  const [search, setSearch] = useState('');

  const options = useMemo(
    () =>
      Country.getAllCountries().map((el) => {
        const dialCode = getDialCode(el.phonecode);

        return {
          value: el.isoCode,
          title: dialCode,
          countryName: `${el.name} ${dialCode}`,
          label: (
            <div className={styles.label}>
              <Highlighter
                value={`${isWindows ? '' : el.flag + ' '}${el.name}`}
                search={search}
              ></Highlighter>
              <Highlighter
                search={search}
                value={dialCode}
                className={styles.dialCode}
              ></Highlighter>
            </div>
          ),
        } as DefaultOptionType;
      }),
    [search]
  );

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    if (['-', '.', 'e', '+'].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <FormItem
      label={userFormLabels[UserFormFields.PHONE_NUMBER]}
      name={UserFormFields.PHONE_NUMBER}
      rules={[{ max, message: 'Phone must be up to 16 characters' }]}
    >
      <Input
        className={styles.input}
        type="number"
        onKeyDown={handleKeyPress}
        addonBefore={
          <FormItem name={UserFormFields.COUNTRY_CODE} noStyle>
            <Select
              popupMatchSelectWidth={false}
              optionLabelProp="label"
              dropdownStyle={{ width: 330 }}
              showSearch
              onSearch={(value) => {
                setSearch(value);
              }}
              optionFilterProp="countryName"
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={NO_RESULTS}
                />
              }
            >
              {options.map((el, i) => (
                <AntSelect.Option
                  countryName={el.countryName}
                  key={i}
                  value={el.value}
                  label={el.title}
                >
                  {el.label}
                </AntSelect.Option>
              ))}
            </Select>
          </FormItem>
        }
      />
    </FormItem>
  );
};

export default PhoneInput;
