import { DefaultOptionType } from 'antd/es/select';
import { Country } from 'country-state-city';
import { useEffect, useMemo, useState } from 'react';
import Highlighter from 'src/components/table/highlighter/Highlighter';
import { isWindows } from 'src/constants/platform';
import useIdentity from './use-identity';

const useCompanySelects = () => {
  const { getIndustries } = useIdentity();
  const [industries, setIndustries] = useState<DefaultOptionType[]>([]);
  const [industriesLoading, setIndustriesLoading] = useState(false);
  const [search, setSearch] = useState('');

  const countries = useMemo(
    () =>
      Country.getAllCountries().map((el) => {
        return {
          value: el.name,
          label: (
            <Highlighter
              value={`${isWindows ? '' : el.flag + ' '}${el.name}`}
              search={search}
            />
          ),
        } as DefaultOptionType;
      }),
    [search]
  );

  useEffect(() => {
    const fetchIndustries = async () => {
      setIndustriesLoading(true);

      const res = await getIndustries();

      setIndustriesLoading(false);

      if (!res.result?.data) {
        return;
      }
      setIndustries(
        res.result.data.map((el) => ({ value: el.id, label: el.name }))
      );
    };
    fetchIndustries();
  }, []);

  return {
    industries,
    countries,
    setSearch,
    industriesLoading,
  };
};

export default useCompanySelects;
