import { Form } from 'antd';
import { DefaultOptionType } from 'antd/es/cascader';
import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import { BROADCAST_NAME } from 'src/constants/local-storage';
import { useMessageContext } from 'src/contexts/message-context';
import { ICustomer, ISupplier } from 'src/types/general';
import useCompanies from './use-companies';
import useDebouncer from './use-debouncer';
import useFiles from './use-files';
import { TAutoUploadInvoiceFile } from './use-upload-invoice';

export type TInvoiceForm = {
  assignee: 'customer' | 'supplier';
  company: string;
};

const useInvoiceUploadForm = (
  onClose: () => void,
  uploadedIds: string[],
  files: TAutoUploadInvoiceFile[],
  setLoading: React.Dispatch<SetStateAction<boolean>>
) => {
  const { getCustomers, getSuppliers } = useCompanies();

  const { processInvoice } = useFiles();

  const { showInfoMessage, showSuccessMessage } = useMessageContext();

  const [extraShown, setExtraShown] = useState(true);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [loadingList, setLoadingList] = useState<boolean[]>([]);
  const [failedList, setFailedList] = useState<boolean[]>([]);
  const [form] = Form.useForm<TInvoiceForm>();
  const assignee = Form.useWatch('assignee', form);
  const company = Form.useWatch('company', form);
  const isCustomer = assignee === 'customer';
  const isFilePicked = !!files.length;
  const isFilesLimit = files.length === 20;
  const pickerDisabled = !company || isFilesLimit;
  const isAllErrors =
    failedList.filter((el) => el).length === files.length && !!files.length;
  const primaryDisabled = loadingList.some((el) => el) || deleteLoading;
  const alertShown = isAllErrors && showAlert;

  useDebouncer(setDebouncedSearch, search);

  useEffect(() => {
    fetchOptions(debouncedSearch);
  }, [debouncedSearch]);

  useEffect(() => {
    if (!debouncedSearch) {
      fetchOptions(debouncedSearch);
    }
    form.setFieldValue('company', undefined);
  }, [isCustomer]);

  const fetchSuppliers = async (search: string) => {
    const res = await getSuppliers({
      search,
      sortField: 'gln',
      sortOrder: SortOrder.ASC,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    });
    const suppliers = res.result?.data.data;

    if (suppliers) {
      setSuppliers(suppliers);
    }
  };

  const fetchCustomers = async (search: string) => {
    const res = await getCustomers({
      search,
      sortField: 'gln',
      sortOrder: SortOrder.ASC,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      includeDeleted: false,
    });
    const customers = res.result?.data.data;
    if (customers) {
      setCustomers(customers);
    }
  };

  const options: DefaultOptionType[] = useMemo(() => {
    if (isCustomer) {
      return customers.map((customers) => ({
        label: customers.name,
        value: customers.id,
      }));
    }
    return suppliers.map((supplier) => ({
      label: supplier.name,
      value: supplier.id,
    }));
  }, [customers, suppliers, isCustomer]);

  const fetchOptions = async (search: string) => {
    setOptionsLoading(true);
    if (isCustomer) {
      await fetchCustomers(search);
    } else {
      await fetchSuppliers(search);
    }
    setOptionsLoading(false);
  };

  const submit = () => {
    form
      .validateFields()
      .then(async () => {
        if (isAllErrors) {
          setShowAlert(true);
          return;
        }
        setLoading(true);
        setShowAlert(false);
        const isSingleFile = files.length === 1 && uploadedIds.length === 1;
        const isAllFiles = files.length === uploadedIds.length;
        await processInvoice(uploadedIds);
        const broadcast = new BroadcastChannel(BROADCAST_NAME);
        broadcast.postMessage('refresh');
        broadcast.close();
        if (isSingleFile) {
          showSuccessMessage(`Invoice "${files[0].file.name}" added`);
        } else if (isAllFiles) {
          showSuccessMessage(`${files.length} invoices added`);
        } else {
          showInfoMessage(
            `${uploadedIds.length} of ${files.length} invoices added`
          );
        }
        setLoading(false);
        onClose();
      })
      .catch(() => {});
  };

  return {
    isFilePicked,
    optionsLoading,
    pickerDisabled,
    deleteLoading,
    isCustomer,
    isFilesLimit,
    primaryDisabled,
    extraShown,
    form,
    options,
    setSearch,
    setLoadingList,
    setFailedList,
    setExtraShown,
    setDeleteLoading,
    debouncedSearch,
    customers,
    suppliers,
    company,
    setShowAlert,
    alertShown,
    submit,
  };
};

export default useInvoiceUploadForm;

// refactor so I dont have to clean up after each open
