import { FormInstance } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { ColumnsType } from 'antd/es/table';
import { ColumnFilterItem } from 'antd/es/table/interface';
import { IconFilter } from 'src/components/icons/filter/IconFilter';
import { IconSort, SortOrder } from 'src/components/icons/sort/IconSort';
import Cell from 'src/components/table/cell/Cell';
import TableFilters from 'src/components/table/filters/TableFilters';
import { BROADCAST_NAME } from 'src/constants/local-storage';
import {
  ProductOffersColumns,
  defaultAssginedProductsColumns,
  defaultSuppliersProductsColumns,
  getProductOffersColumnLabel,
  getProductOffersColumnRender,
  getProductOffersColumnWidth,
  productOffersColumnAlign,
  productOffersColumnFixed,
  productOffersColumnSortOrder,
} from 'src/constants/product-offers';
import { NO_SUPPLIERS_ADDED } from 'src/constants/validation';
import {
  ModalContextActions,
  TModalContextStateAction,
} from 'src/contexts/modal-context';
import { ActionType } from 'src/types/actions';
import { TEditAssignedProduct } from 'src/hooks/use-companies';
import styles from 'src/styles/actions.module.scss';
import { IProductOffer } from 'src/types/general';
import { ArrayElement } from 'src/types/generic';
import { IMappedProductOffer } from 'src/types/mappings';

export type TMappedProductOfferRecord = IMappedProductOffer & {
  key: string;
  isEditing: boolean;
};

type TGetAssignedProductColumnsParams = {
  modalDispatch?: React.Dispatch<TModalContextStateAction>;
  setEditRow?: (index: number, record: TMappedProductOfferRecord) => void;
  setLoading?: (value: boolean) => void;
  unsetEditRow?: (index: number) => void;
  edit?: TEditAssignedProduct;
  search?: string;
  searchLoading?: boolean;
  isFilterSearch?: boolean;
  withSupplier?: boolean;
  customerId?: string;
  isHistoryAction?: boolean;
  actionsHidden: boolean;
  isEditing?: boolean;
  currencies: DefaultOptionType[];
  uoms: DefaultOptionType[];
  form?: FormInstance;
  filters?: ColumnFilterItem[];
  setFilterSearch?: (value: string) => void;
  showErrorMessage?: (text: string) => void;
  showSuccessMessage?: (text: string) => void;
};

export const getMappedProductOffersData = (
  data: IProductOffer[]
): TMappedProductOfferRecord[] => {
  return data.map((row) => {
    const res: TMappedProductOfferRecord = {
      key: row.id,
      [ProductOffersColumns.NAME]: row.product.name,
      [ProductOffersColumns.STATUS]: row.product.isDeleted,
      [ProductOffersColumns.SUPPLIER]: row.supplier.name,
      [ProductOffersColumns.PRICE]: row.productOfferPrice?.price || null,
      [ProductOffersColumns.CURRENCY]: row.productOfferPrice?.currency || 0,
      [ProductOffersColumns.QUANTITY]: row.quantity,
      [ProductOffersColumns.UOM]: row.unitOfMeasurementId,
      [ProductOffersColumns.START_DATE]:
        row.productOfferPrice?.startDate || null,
      [ProductOffersColumns.EXPIRATION_DATE]:
        row.productOfferPrice?.expirationDate || null,
      [ProductOffersColumns.CREATED_AT]: row.createdAt,
      [ProductOffersColumns.MATERIAL_NUMBER]: row.product.materialNumber,
      [ProductOffersColumns.PART_NUMBER]: row.product.partNumber,
      [ProductOffersColumns.BARCODE]: row.product.barCode,
      [ProductOffersColumns.ACTIONS]: {
        canDelete: row.canDelete,
        canEdit: row.canEdit,
      },
      isEditing: false,
    };
    return res;
  });
};

export const getAssignedProductsColumnSetting = function ({
  modalDispatch,
  setEditRow,
  unsetEditRow,
  edit,
  customerId,
  actionsHidden,
  setLoading,
  currencies,
  uoms,
  isEditing,
  form,
  showSuccessMessage,
  showErrorMessage,
  isHistoryAction,
  search,
  withSupplier,
  filters,
  setFilterSearch,
  isFilterSearch,
  searchLoading,
}: TGetAssignedProductColumnsParams) {
  const columns = withSupplier
    ? defaultSuppliersProductsColumns
    : defaultAssginedProductsColumns;

  const render = getProductOffersColumnRender({
    search: search || '',
    currencies,
    uoms,
    disabled: !!isEditing,
    archiveTooltip: 'Unassign',
    save: async (e, key) => {
      e.stopPropagation();
      if (
        !customerId ||
        !setLoading ||
        !edit ||
        !showSuccessMessage ||
        !showErrorMessage
      ) {
        return;
      }
      setLoading(true);

      const data = form?.getFieldsValue();

      const res = await edit(customerId, key, {
        price: data[ProductOffersColumns.PRICE] || null,
        currency: data[ProductOffersColumns.PRICE]
          ? data[ProductOffersColumns.CURRENCY]
          : null,
        unitOfMeasurementId: data[ProductOffersColumns.QUANTITY]
          ? data[ProductOffersColumns.UOM]
          : null,
        quantity: data[ProductOffersColumns.QUANTITY] || null,
        expirationDate: data[ProductOffersColumns.EXPIRATION_DATE],
        startDate: data[ProductOffersColumns.START_DATE],
      });
      if (!res.errors) {
        showSuccessMessage('Changes saved');
        const broadcast = new BroadcastChannel(BROADCAST_NAME);
        broadcast.postMessage('refresh');
        broadcast.close();
      } else {
        showErrorMessage('Changes not saved');
      }
    },
    cancel: (e, index) => {
      e.stopPropagation();
      if (!unsetEditRow) {
        return;
      }
      unsetEditRow(index);
    },
    edit: (e, index, record) => {
      e.stopPropagation();
      if (!setEditRow) {
        return;
      }
      setEditRow(index, record);
    },
    archive: (e, record) => {
      e.stopPropagation();
      if (!modalDispatch) {
        return;
      }
      modalDispatch({
        type: ModalContextActions.SHOW_MODAL,
        value: {
          title: 'Unassign product from customer',
          description: `Product ${record['product.name']} will be removed from the list.`,
          actionText: 'Unassign',
          action: ActionType.UNASSIGN_CUSTOMERS_PRODUCT,
          dataId: customerId,
          subDataId: record.key,
          dataName: record['product.name'] || '-',
        },
      });
    },
  });
  const result: ColumnsType<TMappedProductOfferRecord> = columns.map(
    (property): ArrayElement<ColumnsType<TMappedProductOfferRecord>> => {
      switch (property) {
        case ProductOffersColumns.NAME:
        case ProductOffersColumns.STATUS:
        case ProductOffersColumns.PRICE:
        case ProductOffersColumns.CURRENCY:
        case ProductOffersColumns.QUANTITY:
        case ProductOffersColumns.UOM:
        case ProductOffersColumns.START_DATE:
        case ProductOffersColumns.EXPIRATION_DATE:
        case ProductOffersColumns.CREATED_AT:
        case ProductOffersColumns.MATERIAL_NUMBER:
        case ProductOffersColumns.PART_NUMBER:
        case ProductOffersColumns.BARCODE:
          return {
            title: getProductOffersColumnLabel(withSupplier)[property],
            width: getProductOffersColumnWidth(!!isHistoryAction, withSupplier)[
              property
            ],
            key: property,
            fixed: productOffersColumnFixed[property],
            ellipsis: true,
            align: productOffersColumnAlign[property],
            defaultSortOrder: productOffersColumnSortOrder[property],
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: render[property],
          };
        case ProductOffersColumns.SUPPLIER:
          return {
            title: getProductOffersColumnLabel(withSupplier)[property],
            width: getProductOffersColumnWidth(!!isHistoryAction, withSupplier)[
              property
            ],
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            filterDropdown: (props) => (
              <TableFilters
                {...props}
                filters={filters}
                setSearch={setFilterSearch}
                loading={searchLoading}
                isFilterSearch={!!isFilterSearch}
                placeholder="GLN, supplier name, id"
                emptyDescription={NO_SUPPLIERS_ADDED}
              />
            ),
            filters,
            filterMultiple: true,
            filterIcon: (filtered) => (
              <IconFilter
                width="16"
                height="16"
                color={filtered ? styles.iconFillActive : undefined}
              />
            ),
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return <Cell isValue={!!value}>{value}</Cell>;
            },
          };
        case 'actions':
          if (actionsHidden) {
            return { hidden: true };
          }
          return {
            width: getProductOffersColumnWidth(!!isHistoryAction, withSupplier)[
              property
            ],
            key: property,
            fixed: productOffersColumnFixed[property],
            dataIndex: property,
            render: (value, record, index) =>
              render[property](value, record, index, isHistoryAction),
          };

        default:
          return { hidden: true };
      }
    }
  );
  return result;
};
