import { Avatar, Button, ConfigProvider, Divider, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import { IconAccount } from 'src/components/icons/account/IconAccount';
import { IconBusinessCenter } from 'src/components/icons/business-center/IconBusinessCenter';
import { IconCheckbook } from 'src/components/icons/checkbook/IconCheckbook';
import { IconDomain } from 'src/components/icons/domain/IconDomain';
import { IconLogout } from 'src/components/icons/logout/IconLogout';
import { IconMail } from 'src/components/icons/mail/IconMail';
import { ProtectedRoutes } from 'src/constants/routes';
import { getUserIcon } from 'src/constants/user';
import { userRolesLabels } from 'src/constants/users';
import { UserContextActions, useUserContext } from 'src/contexts/user-context';
import { UserRoles } from 'src/types/general';
import styles from './UserOverlay.module.scss';

type TUserOverlayProps = {
  email: string;
  name: string;
  jobTitle?: string | null;
  company: string;
  role: UserRoles;
  close: () => void;
};

const UserOverlay = ({
  email,
  name,
  jobTitle,
  company,
  role,
  close,
}: TUserOverlayProps) => {
  const { userContextDispatch } = useUserContext();

  const navigate = useNavigate();

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemMarginInline: 0,
            activeBarBorderWidth: 0,
          },
        },
      }}
    >
      <section className={styles.component}>
        <div className={styles.userContainer}>
          <Avatar size="large" shape="square" icon={getUserIcon(role, '24')} />
          <div className={styles.infoContainer}>
            <span className={styles.name}>{name}</span>
            <span className={styles.info}>
              <IconMail height="12" width="12" />
              {email}
            </span>
            {!!jobTitle && (
              <span className={styles.info}>
                <IconBusinessCenter height="12" width="12" />
                {jobTitle}
              </span>
            )}
            <span className={styles.info}>
              <IconDomain height="12" width="12" />
              {company}
            </span>
            <Tag className={styles.role}>{userRolesLabels[role]}</Tag>
          </div>
        </div>
        <Divider className={styles.divider} />
        <div className={styles.menu}>
          <Button
            className={styles.button}
            icon={<IconAccount height="20" width="20" />}
            type="text"
            onClick={() => {
              navigate(ProtectedRoutes.ACCOUNT);
              close();
            }}
          >
            Manage Account
          </Button>
          <Button
            className={styles.button}
            icon={<IconCheckbook height="20" width="20" />}
            type="text"
            onClick={() => {}}
          >
            My Hive Subscription
          </Button>
        </div>
        <Divider className={styles.divider} />
        <Button
          className={styles.button}
          icon={<IconLogout height="20" width="20" />}
          type="text"
          danger
          onClick={() => {
            userContextDispatch({
              type: UserContextActions.LOGOUT,
            });
          }}
        >
          Sign Out
        </Button>
      </section>
    </ConfigProvider>
  );
};

export default UserOverlay;
