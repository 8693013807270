import { Form } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CompanyFormFields } from 'src/constants/company';
import { BROADCAST_NAME } from 'src/constants/local-storage';
import { DEFAULT_COUNTRY_ISO } from 'src/constants/users';
import { useMessageContext } from 'src/contexts/message-context';
import { ICompanyInfo } from 'src/types/general';
import { getDialCodeByIso, getPhoneNumber } from 'src/utils/get-dial-code';
import useAddressSelects from './use-address-selects';
import useCompanySelects from './use-company-selects';
import useProfile from './use-profile';

const useCompanyProfileForm = () => {
  const { getCompanyInfo, updateCompanyInfo, validateCompanyName } =
    useProfile();
  const { showSuccessMessage } = useMessageContext();

  const [form] = Form.useForm<ICompanyInfo>();

  const name = Form.useWatch(CompanyFormFields.NAME, form);
  const industry = Form.useWatch(CompanyFormFields.INDUSTRY, form);
  const country = Form.useWatch(CompanyFormFields.COUNTRY, form);
  const region = Form.useWatch(CompanyFormFields.REGION, form);
  const postCode = Form.useWatch(CompanyFormFields.POSTAL_CODE, form);
  const city = Form.useWatch(CompanyFormFields.CITY, form);
  const addressLine1 = Form.useWatch(
    CompanyFormFields.FIRST_ADDRESS_LINE,
    form
  );
  const addressLine2 = Form.useWatch(
    CompanyFormFields.SECOND_ADDRESS_LINE,
    form
  );
  const dialCountryCode = Form.useWatch(CompanyFormFields.COUNTRY_CODE, form);
  const phoneNumber = Form.useWatch(CompanyFormFields.PHONE_NUMBER, form);
  const primaryContactId = Form.useWatch(
    CompanyFormFields.PRIMARY_CONTACT,
    form
  );
  const primaryContactFirstName = Form.useWatch(
    CompanyFormFields.PRIMARY_CONTACT_FIRST_NAME,
    form
  );
  const primaryContactLastName = Form.useWatch(
    CompanyFormFields.PRIMARY_CONTACT_LAST_NAME,
    form
  );
  const primaryContactJobTitle = Form.useWatch(
    CompanyFormFields.PRIMARY_CONTACT_JOB_TITLE,
    form
  );
  const primaryContactEmail = Form.useWatch(
    CompanyFormFields.PRIMARY_CONTACT_EMAIL,
    form
  );
  const secondaryContactId = Form.useWatch(
    CompanyFormFields.SECONDARY_CONTACT,
    form
  );
  const secondaryContactFirstName = Form.useWatch(
    CompanyFormFields.SECONDARY_CONTACT_FIRST_NAME,
    form
  );
  const secondaryContactLastName = Form.useWatch(
    CompanyFormFields.SECONDARY_CONTACT_LAST_NAME,
    form
  );
  const secondaryContactJobTitle = Form.useWatch(
    CompanyFormFields.SECONDARY_CONTACT_JOB_TITLE,
    form
  );
  const secondaryContactEmail = Form.useWatch(
    CompanyFormFields.SECONDARY_CONTACT_EMAIL,
    form
  );

  const { industries, countries, setSearch } = useCompanySelects();

  const {
    states,
    cities,
    setStateSearch,
    getStateByCity,
    countryCode,
    stateSearch,
  } = useAddressSelects(country, region, city);

  const dialCode = useMemo(() => {
    return getDialCodeByIso(dialCountryCode || null);
  }, [dialCountryCode]);

  const [isPrimaryManual, setIsPrimaryManual] = useState(false);
  const [isSecondaryManual, setIsSecondaryManual] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValue = useRef<ICompanyInfo>();

  useEffect(() => {
    if (!phoneNumber) {
      form.setFieldValue(
        CompanyFormFields.COUNTRY_CODE,
        countryCode || DEFAULT_COUNTRY_ISO
      );
    }
  }, [phoneNumber, countryCode]);

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true);
      const res = await getCompanyInfo();

      setLoading(false);

      const companyInfo = res.result?.data;
      if (!companyInfo) {
        return;
      }

      const initialState = {
        ...companyInfo,
        phoneNumber: getPhoneNumber(
          companyInfo.phoneNumber,
          companyInfo.countryCode
        ),
      };

      initialValue.current = { ...initialState };
      form.setFieldsValue({ ...initialState });

      if (!companyInfo.primaryContactId && companyInfo.primaryEmail) {
        setIsPrimaryManual(true);
      }
      if (!companyInfo.secondaryContactId && companyInfo.secondaryEmail) {
        setIsSecondaryManual(true);
      }
    };
    fetchDetails();
    const broadcast = new BroadcastChannel(BROADCAST_NAME);
    broadcast.onmessage = fetchDetails;

    return () => {
      broadcast.close();
    };
  }, []);

  const isChanged = useMemo(() => {
    if (initialValue.current?.name !== name) {
      return true;
    }
    if (initialValue.current?.industryId !== industry) {
      return true;
    }
    if (initialValue.current?.country !== country) {
      return true;
    }
    if (initialValue.current?.region !== region) {
      return true;
    }
    if (initialValue.current?.postalCode !== postCode) {
      return true;
    }
    if (initialValue.current?.city !== city) {
      return true;
    }
    if (initialValue.current?.addressLine1 !== addressLine1) {
      return true;
    }
    if (initialValue.current?.addressLine2 !== addressLine2) {
      return true;
    }
    if (initialValue.current?.countryCode !== dialCountryCode) {
      return true;
    }
    if (initialValue.current?.phoneNumber !== phoneNumber) {
      return true;
    }
    if (isPrimaryManual) {
      if (
        initialValue.current?.primaryContactFirstName !==
        primaryContactFirstName
      ) {
        return true;
      }
      if (
        initialValue.current?.primaryContactLastName !== primaryContactLastName
      ) {
        return true;
      }
      if (
        initialValue.current?.primaryContactJobTitle !== primaryContactJobTitle
      ) {
        return true;
      }
      if (initialValue.current?.primaryEmail !== primaryContactEmail) {
        return true;
      }
    } else {
      if (initialValue.current?.primaryContactId !== primaryContactId) {
        return true;
      }
    }
    if (isSecondaryManual) {
      if (
        initialValue.current?.secondaryContactFirstName !==
        secondaryContactFirstName
      ) {
        return true;
      }
      if (
        initialValue.current?.secondaryContactLastName !==
        secondaryContactLastName
      ) {
        return true;
      }
      if (
        initialValue.current?.secondaryContactJobTitle !==
        secondaryContactJobTitle
      ) {
        return true;
      }
      if (initialValue.current?.secondaryEmail !== secondaryContactEmail) {
        return true;
      }
    } else {
      if (initialValue.current?.secondaryContactId !== secondaryContactId) {
        return true;
      }
    }
    return false;
  }, [
    name,
    industry,
    country,
    region,
    postCode,
    city,
    addressLine1,
    addressLine2,
    dialCode,
    phoneNumber,
    primaryContactId,
    primaryContactEmail,
    primaryContactFirstName,
    primaryContactLastName,
    primaryContactJobTitle,
    secondaryContactJobTitle,
    secondaryContactId,
    secondaryContactFirstName,
    secondaryContactLastName,
    secondaryContactEmail,
    initialValue.current,
  ]);

  const resetForm = () => {
    if (!initialValue.current) {
      return;
    }
    form.setFieldsValue(initialValue.current);
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(async (data) => {
        setLoading(true);
        const primaryContactInfo = {
          [CompanyFormFields.PRIMARY_CONTACT]: data.primaryContactId || null,
          [CompanyFormFields.PRIMARY_CONTACT_FIRST_NAME]: data.primaryContactId
            ? null
            : data.primaryContactFirstName,
          [CompanyFormFields.PRIMARY_CONTACT_LAST_NAME]: data.primaryContactId
            ? null
            : data.primaryContactLastName,
          [CompanyFormFields.PRIMARY_CONTACT_JOB_TITLE]: data.primaryContactId
            ? null
            : data.primaryContactJobTitle,
          [CompanyFormFields.PRIMARY_CONTACT_EMAIL]: data.primaryContactId
            ? null
            : data.primaryEmail,
        };
        const secondaryContactInfo = {
          [CompanyFormFields.SECONDARY_CONTACT]:
            data.secondaryContactId || null,
          [CompanyFormFields.SECONDARY_CONTACT_FIRST_NAME]:
            data.secondaryContactId ? null : data.secondaryContactFirstName,
          [CompanyFormFields.SECONDARY_CONTACT_LAST_NAME]:
            data.secondaryContactId ? null : data.secondaryContactLastName,
          [CompanyFormFields.SECONDARY_CONTACT_JOB_TITLE]:
            data.secondaryContactId ? null : data.secondaryContactJobTitle,
          [CompanyFormFields.SECONDARY_CONTACT_EMAIL]: data.secondaryContactId
            ? null
            : data.secondaryEmail,
        };
        const result = await updateCompanyInfo({
          ...data,
          ...primaryContactInfo,
          ...secondaryContactInfo,
          [CompanyFormFields.PHONE_NUMBER]: phoneNumber
            ? `${dialCode}${phoneNumber.trim()}`
            : null,
          [CompanyFormFields.COUNTRY_CODE]: phoneNumber
            ? dialCountryCode
            : DEFAULT_COUNTRY_ISO,
        });

        if (!result.errors) {
          const broadcast = new BroadcastChannel(BROADCAST_NAME);
          broadcast.postMessage('refresh');
          broadcast.close();
          showSuccessMessage('Changes saved');
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return {
    resetForm,
    submitForm,
    form,
    industries,
    countries,
    cities,
    states,
    country,
    region,
    setSearch,
    setStateSearch,
    getStateByCity,
    dialCode,
    isPrimaryManual,
    setIsPrimaryManual,
    isSecondaryManual,
    setIsSecondaryManual,
    isChanged,
    loading,
    initialCompanyName: initialValue.current?.name,
    stateSearch,
    validateCompanyName,
  };
};

export default useCompanyProfileForm;
