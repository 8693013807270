export enum ImportStatus {
  NOT_STARTED = 0,
  FILE_UPLOADED = 100,
  HEADERS_PARSED = 200,
  DATA_VALIDATION = 400,
  FAILED_VALIDATION = 500,
  SUCCEED_VALIDATION = 600,
  IMPORTING = 700,
  IMPORT_FAILED = 800,
  IMPORT_SUCCEED = 900,
}

export enum ImportEntity {
  None = 0,
  Product = 1,
  ProductOffer = 2,
}

export enum ImportRequiredFields {
  PRODUCT_ID = 'Id',
  PRODUCT_NAME = 'Name',
  PRODUCT_OFFER_ID = 'Product.Id',
  PRODUCT_OFFER_NAME = 'Product.Name',

  // product offers
}
