import { Form } from 'antd';
import { DefaultOptionType } from 'antd/es/cascader';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import { BROADCAST_NAME } from 'src/constants/local-storage';
import { useMeasurementsContext } from 'src/contexts/measurements-context';
import { useMessageContext } from 'src/contexts/message-context';
import { ICustomer, IProduct } from 'src/types/general';
import { getErrorText } from 'src/utils/get-error-text';
import useCompanies from './use-companies';
import useProducts from './use-products';

type TAssignFormData = {
  object: string | null;
  listPrice: number | null;
  currency: number | null;
  quantity: number | null;
  uom: string | null;
  priceEffective: dayjs.Dayjs | null;
  priceExpiration: dayjs.Dayjs | null;
};

type TuseAssignModalFormParams = {
  productId?: string;
  customerId?: string;
  close: () => void;
  isCustomer?: boolean;
};

const useAssignModalForm = ({
  customerId,
  productId,
  close,
  isCustomer = true,
}: TuseAssignModalFormParams) => {
  const [form] = Form.useForm();

  const { getCustomers, assignProducts } = useCompanies();
  const { assignCustomers, getProducts } = useProducts();
  const { showSuccessMessage, showErrorMessage } = useMessageContext();
  const {
    loading: initialLoading,
    uoms,
    currencies,
    getAllMeasurements,
  } = useMeasurementsContext();
  const [options, setOptions] = useState<Array<DefaultOptionType[]>>([[]]);
  const [productData, setProductData] = useState<Array<IProduct[]>>([[]]);
  const [customerData, setCustomerData] = useState<Array<ICustomer[]>>([[]]);
  const [optionLoading, setOptionLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string[]>([]);
  const [focused, setFocused] = useState<boolean>(false);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const listData = Form.useWatch<TAssignFormData[]>('data', form);

  const initialValue = useMemo(() => {
    const value: TAssignFormData = {
      object: null,
      quantity: null,
      listPrice: null,
      priceEffective: null,
      priceExpiration: null,
      uom: uoms.find((uom) => uom.label === 'pcs')?.value as string,
      currency: currencies.find((curr) => curr.label === 'AUD')
        ?.value as number,
    };
    return value;
  }, [uoms, currencies]);

  useEffect(() => {
    getAllMeasurements();
  }, []);

  useEffect(() => {
    form.setFieldValue('data', [{ ...initialValue }]);
  }, [initialValue]);

  const fetchProducts = async (search: string) => {
    setOptionLoading(true);
    const res = await getProducts({
      search,
      sortField: 'createdAt',
      sortOrder: SortOrder.DESC,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    });
    const products = res.result?.data.data;
    if (products) {
      setProductData((data) => {
        data[focusedIndex || 0] = products;
        return [...data];
      });
    }
    setOptionLoading(false);
  };

  const fetchCustomers = async (search: string) => {
    setOptionLoading(true);
    const res = await getCustomers({
      search,
      sortField: 'gln',
      sortOrder: SortOrder.ASC,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      includeDeleted: false,
    });
    const customers = res.result?.data.data;
    if (customers) {
      setCustomerData((data) => {
        data[focusedIndex || 0] = customers;
        return [...data];
      });
    }
    setOptionLoading(false);
  };

  const fetchOptions = (search: string) => {
    if (!!search && search.length < 3) {
      return;
    }
    if (isCustomer) {
      fetchCustomers(search);
    } else {
      fetchProducts(search);
    }
  };

  useEffect(() => {
    setOptions(() => {
      if (isCustomer) {
        return customerData.map((product) =>
          product.map((el) => ({
            title: el.name,
            label: el.name,
            value: el.id,
          }))
        );
      } else {
        return productData.map((product) =>
          product.map((el) => ({
            title: el.name,
            label: el.name,
            value: el.id,
          }))
        );
      }
    });
  }, [customerData, productData]);

  useEffect(() => {
    if (focusedIndex === null) {
      return;
    }
    fetchOptions(search[focusedIndex]);
  }, [search, focusedIndex]);

  useEffect(() => {
    if (!listData) {
      return;
    }

    const buff = listData.slice();
    const row = buff[focusedIndex || 0];
    if (!row?.priceEffective || !row?.priceExpiration) {
      return;
    }
    const priceEffectiveDate = row.priceEffective?.startOf('day').toDate();
    const priceExpirationDate = row.priceExpiration?.startOf('day').toDate();

    if (priceEffectiveDate >= priceExpirationDate) {
      row.priceExpiration = row.priceEffective.add(1, 'day');
      form.setFieldValue('data', buff);
    }
  }, [listData]);

  const uniqueValidator = (row: number) => {
    return (_: unknown, value: string) => {
      const allValues: { data: TAssignFormData[] } = form.getFieldsValue();

      if (!value) {
        return Promise.resolve();
      }

      const duplicates = Object.keys(allValues.data).some((key) => {
        return key !== `${row}` && allValues.data[+key].object === value;
      });

      if (duplicates) {
        return Promise.reject();
      }
      return Promise.resolve();
    };
  };

  const onSelect = (value: string, i: number) => {
    if (isCustomer) {
      const selectedData = customerData[i].find((el) => el.id === value);
      if (selectedData) {
        const buff = [...listData];
        buff[i].object = selectedData.id;
        form.setFieldValue('data', buff);
      }
    } else {
      const selectedData = productData[i].find((el) => el.id === value);
      if (selectedData) {
        const buff = [...listData];
        buff[i].listPrice = selectedData.rrpPrice || null;
        buff[i].currency = selectedData.currency || initialValue.currency;
        buff[i].quantity = selectedData.quantity || null;
        buff[i].uom = selectedData.unitOfMeasurementId || initialValue.uom;
        buff[i].object = selectedData.id;
        form.setFieldValue('data', buff);
      }
    }
    setSearch((search) => {
      const buff = search.slice();
      buff[i] = '';
      return buff;
    });
    setFocusedIndex(null);
    form.validateFields();
  };

  const onMagicButton = (isExpiration: boolean = false) => {
    const buff = [...listData];
    listData.forEach((_, i) => {
      if (isExpiration) {
        buff[i].priceExpiration = listData[0].priceExpiration;
      } else {
        buff[i].priceEffective = listData[0].priceEffective;
      }
    });
    form.setFieldValue('data', buff);
  };

  const mutation = isCustomer ? assignCustomers : assignProducts;

  const submit = () => {
    form
      .validateFields()
      .then(async () => {
        setLoading(true);
        if (!customerId && !productId) {
          return;
        }
        const res = await mutation(
          isCustomer ? productId! : customerId!,
          listData.map((el) => ({
            productId: productId || el.object || '',
            customerId: customerId || el.object || '',
            price: el.listPrice || null,
            currency: el.listPrice ? el.currency || null : null,
            startDate: el.priceEffective?.toDate() || null,
            expirationDate: el.priceExpiration?.toDate() || null,
            unitOfMeasurementId: el.quantity ? el.uom : null,
            quantity: el.quantity || null,
          }))
        );
        setLoading(false);

        const pluralSuffix = listData.length === 1 ? '' : 's';
        if (!res.errors) {
          showSuccessMessage(
            `${listData.length} ${isCustomer ? 'customer' : 'product'}${pluralSuffix} assigned`
          );
          const broadcast = new BroadcastChannel(BROADCAST_NAME);
          broadcast.postMessage('refresh');
          broadcast.close();
        } else {
          const text = getErrorText(res.errors.response?.data.errors);
          if (text.length) {
            text.forEach((err) => {
              showErrorMessage(err);
            });
          } else {
            showErrorMessage(
              `${isCustomer ? 'Customer' : 'Product'}${pluralSuffix} not assigned`
            );
          }
        }
        close();
      })
      .catch(() => {});
  };

  const removeRow = (index: number) => {
    if (isCustomer) {
      setCustomerData((data) => {
        const res = data.slice();
        res.splice(index, 1);
        return [...res];
      });
    } else {
      setProductData((data) => {
        const res = data.slice();
        res.splice(index, 1);
        return [...res];
      });
    }
  };

  return {
    form,
    initialValue,
    scrollRef,
    options,
    listData,
    uniqueValidator,
    optionLoading,
    focusedIndex,
    setSearch,
    customerData,
    productData,
    setFocused,
    setFocusedIndex,
    focused,
    currencies,
    uoms,
    onMagicButton,
    onSelect,
    loading,
    submit,
    initialLoading,
    setCustomerData,
    setProductData,
    search,
    removeRow,
  };
};

export default useAssignModalForm;
