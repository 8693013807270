import { RcFile } from 'antd/es/upload';
import { useState } from 'react';
import useFiles from './use-files';

export type TAutoUploadInvoiceFile = {
  file: RcFile;
  error: boolean;
  uploadedId?: string;
};

const useUploadInvoice = (onClose: () => void) => {
  const { deleteInvoice } = useFiles();
  const [loading, setLoading] = useState(false);
  const [uploadedIds, setUploadedIds] = useState<string[]>([]);
  const [files, setFiles] = useState<TAutoUploadInvoiceFile[]>([]);

  const cleanUp = () => {
    setUploadedIds([]);
    setFiles([]);
  };

  const closeModal = async () => {
    cleanUp();
    try {
      setLoading(true);
      await Promise.all(uploadedIds.map((id) => deleteInvoice(id)));
      setLoading(false);
      onClose();
    } catch (err) {
      onClose();
    }
  };

  return {
    loading,
    setUploadedIds,
    closeModal,
    uploadedIds,
    setLoading,
    files,
    setFiles,
    cleanUp,
  };
};

export default useUploadInvoice;
