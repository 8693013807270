import { Avatar, Button, Divider, Form, Input, Skeleton } from 'antd';
import FormItem from 'src/components/form-item/FormItem';
import Loader from 'src/components/loader/Loader';
import PhoneInput from 'src/components/phone-input/PhoneInput';
import { ProfileFormFields, profileFormLabels } from 'src/constants/profile';
import { getUserIcon } from 'src/constants/user';
import { REQUIRED } from 'src/constants/validation';
import useUserProfileForm from 'src/hooks/use-user-profile-form';
import styles from './ProfileForm.module.scss';
import { requiredValidator } from 'src/utils/validators';

const ProfileForm = () => {
  const { resetForm, submitForm, form, dialCode, isChanged, loading, role } =
    useUserProfileForm();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Form
          form={form}
          layout="vertical"
          requiredMark
          className={styles.form}
        >
          <FormItem label="Profile picture">
            {role ? (
              <Avatar
                className={styles.logo}
                size={80}
                shape="circle"
                icon={getUserIcon(role, '56')}
              />
            ) : (
              <Skeleton
                avatar={{ size: 80, shape: 'circle' }}
                paragraph={false}
                title={false}
              />
            )}
          </FormItem>

          <Divider className={styles.divider} />

          <div className={styles.row}>
            <FormItem
              className={styles.rowItem}
              name={ProfileFormFields.FIRST_NAME}
              label={profileFormLabels[ProfileFormFields.FIRST_NAME]}
              rules={[
                { required: true, validator: requiredValidator },
                { max: 50 },
              ]}
            >
              <Input />
            </FormItem>

            <FormItem
              className={styles.rowItem}
              name={ProfileFormFields.LAST_NAME}
              label={profileFormLabels[ProfileFormFields.LAST_NAME]}
              rules={[
                { required: true, validator: requiredValidator },
                { max: 50 },
              ]}
            >
              <Input />
            </FormItem>
          </div>
          <FormItem
            name={ProfileFormFields.JOB_TITLE}
            label={profileFormLabels[ProfileFormFields.JOB_TITLE]}
            rules={[{ max: 150 }]}
          >
            <Input />
          </FormItem>
          <FormItem
            name={ProfileFormFields.EMAIL}
            label={profileFormLabels[ProfileFormFields.EMAIL]}
            rules={[{ required: true, message: REQUIRED }]}
          >
            <Input disabled />
          </FormItem>
          <PhoneInput max={16 - dialCode.length} />
        </Form>
      </div>
      {isChanged && (
        <div className={styles.buttonsContainer}>
          <Button onClick={resetForm}>Cancel</Button>
          <Button onClick={submitForm} type="primary">
            Save
          </Button>
        </div>
      )}
      <Loader visible={loading} />
    </div>
  );
};

export default ProfileForm;
