import { Form } from 'antd';
import AssignCustomersModal from 'src/components/assign-modal/assign-customer-modal/AssignCustomersModal';
import { IconAdd } from 'src/components/icons/add/IconAdd';
import { IconDownload } from 'src/components/icons/download/IconDownload';
import { IconInventory } from 'src/components/icons/inventory/IconInventory';
import ObjectHeader from 'src/components/object-header/ObjectHeader';
import ProductInfo from 'src/components/products/info/ProductInfo';
import Table from 'src/components/table/Table';
import TableHeader from 'src/components/table/header/TableHeader';
import { NO_PRODUCTS_PURCHASED } from 'src/constants/validation';
import useProductDetails from 'src/hooks/use-product-details';
import { TMappedProductOfferRecord } from 'src/utils/map-assigned-product-table';
import { TMappedPurchaseHistoryRecord } from 'src/utils/map-purchase-history-table';
import styles from './ProductDetails.module.scss';

const ProductDetails = () => {
  const {
    product,
    productId,
    columns,
    data,
    handleTableChange,
    loading,
    width,
    total,
    form,
    open,
    setOpen,
    currencies,
    purchasedColumns,
    productOffer,
    purchasedData,
    isSupplier,
  } = useProductDetails();

  return (
    <>
      {isSupplier ? (
        <ObjectHeader
          Icon={<IconInventory />}
          title={productOffer?.product.name || '-'}
          status={productOffer?.product.isDeleted ? 'Archived' : 'Active'}
          statusColor={productOffer?.product.isDeleted ? 'red' : undefined}
          Details={
            <ProductInfo
              currencies={currencies}
              barCode={productOffer?.product.barCode}
              materialNumber={productOffer?.product.materialNumber}
              partNumber={productOffer?.product.partNumber}
              epdFile={productOffer?.product.epdFile}
              externalEpdUrl={productOffer?.product.externalEpdUrl}
              rrpPrice={productOffer?.productOfferPrice?.price}
              currency={productOffer?.productOfferPrice?.currency}
              quantity={productOffer?.quantity}
              unitOfMeasurement={productOffer?.unitOfMeasurement}
              supplier={productOffer?.supplier.name}
            />
          }
          noActions
        />
      ) : (
        <ObjectHeader
          Icon={<IconInventory />}
          title={product?.name || '-'}
          Details={<ProductInfo currencies={currencies} {...product} />}
          noActions
        />
      )}
      <div className={styles.container}>
        {isSupplier ? (
          <TableHeader
            title="Purchase history"
            bold={false}
            isSearch={false}
            customActionTitle={'Export to .xlsx'}
            customActionIcon={<IconDownload width="16" height="16" />}
            noActions
          />
        ) : (
          <TableHeader
            title="Assigned customers"
            bold={false}
            primaryAction={() => {
              setOpen(true);
            }}
            primaryActionTitle="Assign Customers"
            primaryActionIcon={<IconAdd width="16" height="16" />}
            isSearch={false}
            onlyPrimaryAction
            noActions={loading}
            customActionTitle={'Export to .xlsx'}
            customActionIcon={<IconDownload width="16" height="16" />}
          />
        )}
        {isSupplier ? (
          <Table<TMappedPurchaseHistoryRecord>
            emptyTextDescription={NO_PRODUCTS_PURCHASED}
            columns={purchasedColumns}
            dataSource={purchasedData}
            onChange={handleTableChange}
            loading={loading}
            width={width}
            height={'calc(100vh - 434px)'}
            total={total}
          />
        ) : (
          <Form form={form} layout="vertical">
            <Table<TMappedProductOfferRecord>
              emptyTextDescription="No customers assigned"
              columns={columns}
              dataSource={data}
              onChange={handleTableChange}
              loading={loading}
              width={width}
              height={'calc(100vh - 434px)'}
              total={total}
            />
          </Form>
        )}
      </div>
      <AssignCustomersModal
        productId={productId}
        visible={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default ProductDetails;
