import { Progress } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useEffect } from 'react';
import File from '../File';
import styles from './AutoUploadXLSXFile.module.scss';
import clsx from 'clsx';

type TAutoUploadInvoiceFileProps = {
  className?: string;
  file: RcFile;
  onDelete: () => void;
  disabled: boolean;
  uploadFile: () => void;
  abortUpload: () => void;
  error: boolean;
  loading: boolean;
  errorMessage: string;
  progress?: number;
};

const AutoUploadXLSXFile = ({
  className,
  file,
  onDelete,
  disabled,
  uploadFile,
  abortUpload,
  error,
  errorMessage,
  loading,
  progress,
}: TAutoUploadInvoiceFileProps) => {
  useEffect(() => {
    uploadFile();
    return () => {
      abortUpload();
    };
  }, []);

  return (
    <div className={clsx(styles.component, className)}>
      <File
        file={{
          fileName: file.name || '',
          blobName: file.name || '',
          createdAt: new Date(),
        }}
        isError={error}
        disabled={disabled}
        retry={uploadFile}
        errorMessage={errorMessage}
        onLocalDelete={() => {
          onDelete();
        }}
      />

      {loading && (
        <Progress
          className={styles.progress}
          percent={progress}
          showInfo={false}
        />
      )}
    </div>
  );
};

export default AutoUploadXLSXFile;
