export enum ProtectedRoutes {
  DASHBOARD = '/',
  HIVE = '/my-hive',
  SUPPLIERS = '/my-suppliers',
  CUSTOMERS = '/my-customers',
  PRODUCTS = '/my-products',
  INSIGHTS = '/my-insights',
  SOURCES = '/my-sources',
  NEWS_AND_SUPPORT = '/support',
  HIVE_CONTROL_CENTRE = '/control-centre',
  ACCOUNT = '/account',
}

export enum PublicRoutes {
  SIGNIN = '/sign-in',
  SIGNUP = '/sign-up',
  RESET_PASSWORD = '/password-reset',
}

export const RouteTitle = {
  [ProtectedRoutes.DASHBOARD]: 'Dashboard',
  [ProtectedRoutes.HIVE]: 'My Hive',
  [ProtectedRoutes.SUPPLIERS]: 'My Suppliers',
  [ProtectedRoutes.CUSTOMERS]: 'My Customers',
  [ProtectedRoutes.PRODUCTS]: 'My Products',
  [ProtectedRoutes.INSIGHTS]: 'My Hive Insights',
  [ProtectedRoutes.NEWS_AND_SUPPORT]: 'News and Support',
  [ProtectedRoutes.SOURCES]: 'My Sources',
  [ProtectedRoutes.HIVE_CONTROL_CENTRE]: 'Hive Control Centre',
  [ProtectedRoutes.ACCOUNT]: 'Manage Account',
};

export const LOGIN_PATHS: string[] = [
  PublicRoutes.SIGNIN,
  PublicRoutes.SIGNUP,
  PublicRoutes.RESET_PASSWORD,
];

export const PROTECTED_PATHS: string[] = [
  ProtectedRoutes.DASHBOARD,
  ProtectedRoutes.HIVE,
  ProtectedRoutes.SUPPLIERS,
  ProtectedRoutes.CUSTOMERS,
  ProtectedRoutes.PRODUCTS,
  ProtectedRoutes.INSIGHTS,
  ProtectedRoutes.SOURCES,
  ProtectedRoutes.NEWS_AND_SUPPORT,
  ProtectedRoutes.HIVE_CONTROL_CENTRE,
  ProtectedRoutes.ACCOUNT,
];
