import { AxiosHeaders } from 'axios';
import dayjs from 'dayjs';
import { EXPORT_FAILED } from 'src/constants/validation';
import { HTTP, useNetworkContext } from 'src/contexts/network-context';
import {
  TGetProjectIdsParams,
  TGetProjectIdsResponse,
  TGetProjectNamesParams,
  TGetProjectNamesResponse,
  TGetSustainabilityReportParams,
  TGetSustainabilityReportResponse,
} from 'src/types/backend';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from 'src/utils/date-formatter';
import { downloadFile } from 'src/utils/download';
import { getSustainabilityReportParams } from 'src/utils/get-sustainability-report-params';
import { getTimezone } from 'src/utils/get-timezone';
import useMessage from './use-message';

const useReports = () => {
  const { fetch } = useNetworkContext();
  const { showErrorMessage } = useMessage();

  const getSustainabilityReport = async (
    data: TGetSustainabilityReportParams
  ) => {
    const params = getSustainabilityReportParams(data);
    const result = await fetch<TGetSustainabilityReportResponse, unknown>({
      url: '/report/sustainability/paged',
      method: HTTP.GET,
      params,
    });

    return result;
  };

  const getProjectIds = async (data: TGetProjectIdsParams) => {
    const params = new URLSearchParams();
    params.append('Page', `${data.pagination?.current}`);
    params.append('PageSize', `${data.pagination?.pageSize}`);
    params.append('SearchByPersonalId', data.search);

    const result = await fetch<TGetProjectIdsResponse, unknown>({
      url: '/report/projects/personalId/paged',
      method: HTTP.GET,
      params,
    });

    return result;
  };

  const getProjectNames = async (data: TGetProjectNamesParams) => {
    const params = new URLSearchParams();
    params.append('Page', `${data.pagination?.current}`);
    params.append('PageSize', `${data.pagination?.pageSize}`);
    params.append('SearchByName', data.search);

    const result = await fetch<TGetProjectNamesResponse, unknown>({
      url: '/report/projects/name/paged',
      method: HTTP.GET,
      params,
    });

    return result;
  };

  const exportReport = async (
    data: TGetSustainabilityReportParams,
    settings: {
      key: string;
      value: string;
    }[]
  ) => {
    const params = getSustainabilityReportParams(data);
    fetch<Blob, unknown>({
      url: `/report/sustainability/export`,
      responseType: 'blob',
      method: HTTP.POST,
      data: settings,
      params,
      headers: new AxiosHeaders({
        'X-User-Timezone': getTimezone(),
      }),
    })
      .then((response) => {
        const company = response.result?.headers['company-name'];

        const date = dayjs();
        downloadFile(
          response,
          `${company}_Sustainability Report_${date.format(DEFAULT_DATE_FORMAT)}_${date.format(DEFAULT_TIME_FORMAT)}`
        );
      })
      .catch(() => {
        showErrorMessage(EXPORT_FAILED);
      });
  };

  return {
    getSustainabilityReport,
    getProjectIds,
    getProjectNames,
    exportReport,
  };
};

export default useReports;
