import { Form } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from 'src/constants/routes';
import { SignUpFormFields } from 'src/constants/sign-up';
import { getErrorText } from 'src/utils/get-error-text';
import useIdentity from './use-identity';

const useResetPasswordForm = (resetToken: string, email: string) => {
  const { resetPassword } = useIdentity();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [postError, setPostError] = useState('');
  const [mounted, setMounted] = useState(false);
  const [validationStatus, setValidationStatus] = useState({
    minLength: false,
    hasDigitOrSymbol: false,
    hasUpperLower: false,
  });
  const [form] = Form.useForm();

  const password = Form.useWatch(SignUpFormFields.PASSWORD, form);
  const passwordConfirm = Form.useWatch(
    SignUpFormFields.PASSWORD_CONFIRM,
    form
  );

  const isPasswordValid = useMemo(
    () => Object.values(validationStatus).every(Boolean),
    [validationStatus]
  );

  useEffect(() => {
    setMounted(true);
  }, []);

  const submit = () => {
    form
      .validateFields()
      .then(async () => {
        if (!isPasswordValid) {
          return;
        }

        setLoading(true);
        setPostError('');

        let result = await resetPassword({
          email: email.toLowerCase(),
          password: password.trim(),
          token: resetToken,
        });

        if (result.errors?.response) {
          setPostError(getErrorText(result.errors.response.data.errors)[0]);
        } else if (result.result?.data.company) {
          navigate(
            `${PublicRoutes.SIGNIN}?company=${result.result?.data.company}`
          );
        }

        setLoading(false);
      })
      .catch((errr) => {
        console.error(errr);
      });
  };

  const validatePassword = (value: string) => {
    setValidationStatus({
      minLength: value.length >= 8,
      hasDigitOrSymbol: /[0-9!@#$%^&*]/.test(value),
      hasUpperLower: /[a-z]/.test(value) && /[A-Z]/.test(value),
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    validatePassword(value);
    if (passwordConfirm) {
      form.validateFields([SignUpFormFields.PASSWORD_CONFIRM]);
    }
  };

  const getValidationStatus = (status: boolean) => {
    if (!password) {
      return 'default';
    }
    return status ? 'success' : 'error';
  };

  return {
    loading,
    postError,
    form,
    password,
    submit,
    mounted,
    passwordConfirm,
    isPasswordValid,
    getValidationStatus,
    validationStatus,
    handleChange,
  };
};

export default useResetPasswordForm;
