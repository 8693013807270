import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import { TIconProps } from 'src/types/icons';
import styles from './IconHoneycomb.module.scss';

type IconHoneycombProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconHoneycomb: FunctionComponent<IconHoneycombProps> = ({
  className,
  width = 128,
  height = 128,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M100.254 79.1585L112 58.7561L100.254 38.3354H77.3732L65.6094 17.9146H42.1168L30.3705 38.3354L42.1168 58.7561H40.8739L29.1276 79.1585L40.8739 99.5792H63.7543L75.5182 120H99.0108L110.757 99.5792L99.0108 79.1585H100.254Z"
        fill="#FAD20A"
      />
      <path
        d="M106.111 51.5391L92.8036 28.4172H69.9205L58.1707 8H31.5443L18.2376 31.121L29.3699 50.4646L17 71.9564L30.3067 95.0782H53.1897L64.9395 115.495H91.566L104.873 92.3745L93.742 73.0309L106.111 51.5391ZM89.6794 33.8247L99.8741 51.5391L89.6794 69.2526H69.3022L59.1083 51.5391L69.3022 33.8247H89.6794ZM24.4737 31.121L34.6685 13.4075H55.0457L64.9308 30.5837L54.4273 48.8362H34.6685L24.4737 31.121ZM33.4317 89.6707L23.237 71.9564L33.4317 54.2429H54.4273L64.0028 70.8819L53.1906 89.6707H33.4317ZM98.6374 92.3745L88.4426 110.088H68.0654L57.8716 92.3745L68.0654 74.6601H88.4426L98.6374 92.3745ZM38.2588 18.1911L29.9017 32.3311L28.3519 31.4141L37.2311 16.3886H54.7527V18.1911H38.2588ZM62.0191 52.1035L70.8984 37.078H88.4199V38.8805H71.9261L63.569 53.0205L62.0191 52.1035ZM69.6599 77.6693H87.1814V79.4717H70.6876L62.3305 93.6117L60.7806 92.6947L69.6599 77.6693ZM35.4294 57.8426H52.9509V59.6451H36.4571L28.1 73.7851L26.5501 72.8681L35.4294 57.8426Z"
        fill="#111111"
      />
    </svg>
  );
};
