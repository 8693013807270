import { Button, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IconSort, SortOrder } from 'src/components/icons/sort/IconSort';
import { IconUnlink } from 'src/components/icons/unlink/IconUnlink';
import Cell from 'src/components/table/cell/Cell';
import Email from 'src/components/table/email/Email';
import Highlighter from 'src/components/table/highlighter/Highlighter';
import {
  ModalContextActions,
  TModalContextStateAction,
} from 'src/contexts/modal-context';
import { ActionType } from 'src/types/actions';
import styles from 'src/styles/actions.module.scss';
import { ICompany, ICustomer, ISupplier } from 'src/types/general';
import { ArrayElement } from 'src/types/generic';

type TKey = keyof ICompany | keyof ICustomer | keyof ISupplier;

const properties: Array<TKey> = [
  'gln',
  'supplierPersonalId',
  'name',
  'industry',
  'isDeleted',
  'address',
  'phoneNumber',
  'primaryContact',
  'primaryEmail',
  'secondaryContact',
  'secondaryEmail',
  'connectionId',
];

export type TMappedCompanyRecord = ICompany & {
  key: string;
  isSelected: boolean;
};
export type TMappedCustomerRecord = ICustomer & {
  key: string;
};
export type TMappedSupplierRecord = ISupplier & {
  key: string;
};

type TGetSupplierColumnSettingParams = {
  modalDispatch: React.Dispatch<TModalContextStateAction>;
  search: string;
  isSupplier?: boolean;
  isCustomer?: boolean;
  noAction?: boolean;
};

export const getMappedSupplierData = (
  data: ISupplier[]
): TMappedSupplierRecord[] => {
  return data.map((row) => {
    const res: TMappedSupplierRecord = {
      key: row.id,
      ...row,
    };
    return res;
  });
};

export const getMappedCompanyData = (
  data: ICompany[],
  selectedId: string | null
): TMappedCompanyRecord[] => {
  return data.map((row, index) => {
    const res: TMappedCompanyRecord = {
      key: row.id,
      isSelected: selectedId === row.name,
      ...row,
    };
    return res;
  });
};

export const getMappedCustomerData = (
  data: ICustomer[]
): TMappedCustomerRecord[] => {
  return data.map((row) => {
    const res: TMappedCustomerRecord = {
      key: row.id,
      ...row,
    };
    return res;
  });
};

export const getCompanyColumnSetting = function <T>({
  modalDispatch,
  search,
  isSupplier = false,
  isCustomer = false,
  noAction,
}: TGetSupplierColumnSettingParams) {
  const result = properties.map((property): ArrayElement<ColumnsType<T>> => {
    switch (property) {
      case 'gln':
        return {
          title: 'GLN',
          width: 128,
          key: property,
          fixed: 'left',
          ellipsis: true,
          dataIndex: property,
          sorter: true,
          showSorterTooltip: false,
          defaultSortOrder: SortOrder.ASC,
          sortIcon: (props) => (
            <IconSort
              width="16"
              height="16"
              order={props.sortOrder as SortOrder}
            />
          ),
          render: (value) => {
            return (
              <Cell isValue={!!value}>
                <Highlighter value={value} search={search} />
              </Cell>
            );
          },
        };
      case 'supplierPersonalId':
        if (!isSupplier) {
          return { hidden: true };
        }

        return {
          title: 'Supplier ID',
          width: 155,
          ellipsis: true,
          key: property,
          dataIndex: property,
          sorter: true,
          showSorterTooltip: false,
          sortIcon: (props) => (
            <IconSort
              width="16"
              height="16"
              order={props.sortOrder as SortOrder}
            />
          ),
          render: (value) => {
            return (
              <Cell isValue={!!value}>
                <Highlighter value={value} search={search} />
              </Cell>
            );
          },
        };
      case 'name':
        return {
          title: `${isCustomer ? 'Customer' : 'Supplier'} name`,
          width: 200,
          ellipsis: true,
          key: property,
          dataIndex: property,
          sorter: true,
          showSorterTooltip: false,
          sortIcon: (props) => (
            <IconSort
              width="16"
              height="16"
              order={props.sortOrder as SortOrder}
            />
          ),
          render: (value) => {
            return (
              <Cell isValue={!!value}>
                <Highlighter value={value} search={search} />
              </Cell>
            );
          },
        };

      case 'isDeleted':
        if (!isCustomer) {
          return { hidden: true };
        }
        return {
          title: 'Status',
          width: 91,
          ellipsis: true,
          key: property,
          dataIndex: property,
          sorter: true,
          showSorterTooltip: false,
          sortIcon: (props) => (
            <IconSort
              width="16"
              height="16"
              order={props.sortOrder as SortOrder}
            />
          ),
          render: (value) => {
            return (
              <Tag color={value ? 'orange' : undefined}>
                {value ? 'Decoupled' : 'Coupled'}
              </Tag>
            );
          },
        };
      case 'industry':
        return {
          title: 'Industry',
          width: 155,
          ellipsis: true,
          key: property,
          dataIndex: property,
          sorter: true,
          showSorterTooltip: false,
          sortIcon: (props) => (
            <IconSort
              width="16"
              height="16"
              order={props.sortOrder as SortOrder}
            />
          ),
          render: (value) => {
            return <Cell isValue={!!value}>{value}</Cell>;
          },
        };
      case 'address':
        return {
          title: 'Address',
          width: 240,
          ellipsis: true,
          key: property,
          dataIndex: property,
          sorter: true,
          showSorterTooltip: false,
          sortIcon: (props) => (
            <IconSort
              width="16"
              height="16"
              order={props.sortOrder as SortOrder}
            />
          ),
          render: (value) => {
            return (
              <Cell isValue={!!value}>
                <Highlighter value={value} search={search} />
              </Cell>
            );
          },
        };
      case 'phoneNumber':
        return {
          title: 'Phone number',
          width: 155,
          ellipsis: true,
          key: property,
          dataIndex: property,
          sorter: true,
          showSorterTooltip: false,
          sortIcon: (props) => (
            <IconSort
              width="16"
              height="16"
              order={props.sortOrder as SortOrder}
            />
          ),
          render: (value) => {
            return <Cell isValue={!!value}>{value}</Cell>;
          },
        };
      case 'primaryContact':
        return {
          title: 'Primary contact',
          width: 155,
          ellipsis: true,
          key: property,
          dataIndex: property,
          sorter: true,
          showSorterTooltip: false,
          sortIcon: (props) => (
            <IconSort
              width="16"
              height="16"
              order={props.sortOrder as SortOrder}
            />
          ),
          render: (value) => {
            return <Cell isValue={!!value}>{value}</Cell>;
          },
        };
      case 'primaryEmail':
        return {
          title: 'Primary email',
          width: 155,
          ellipsis: true,
          key: property,
          dataIndex: property,
          sorter: true,
          showSorterTooltip: false,
          sortIcon: (props) => (
            <IconSort
              width="16"
              height="16"
              order={props.sortOrder as SortOrder}
            />
          ),
          render: (value) => {
            return (
              <Cell isValue={!!value}>
                <Email>{value}</Email>
              </Cell>
            );
          },
        };
      case 'secondaryContact':
        return {
          title: 'Secondary contact',
          width: 166,
          ellipsis: true,
          key: property,
          dataIndex: property,
          sorter: true,
          showSorterTooltip: false,
          sortIcon: (props) => (
            <IconSort
              width="16"
              height="16"
              order={props.sortOrder as SortOrder}
            />
          ),
          render: (value) => {
            return <Cell isValue={!!value}>{value}</Cell>;
          },
        };
      case 'secondaryEmail':
        return {
          title: 'Secondary email',
          width: 155,
          ellipsis: true,
          key: property,
          dataIndex: property,
          sorter: true,
          showSorterTooltip: false,
          sortIcon: (props) => (
            <IconSort
              width="16"
              height="16"
              order={props.sortOrder as SortOrder}
            />
          ),
          render: (value) => {
            return (
              <Cell isValue={!!value}>
                <Email>{value}</Email>
              </Cell>
            );
          },
        };
      case 'connectionId':
        if (!isSupplier || noAction) {
          return { hidden: true };
        }

        return {
          key: property,
          dataIndex: property,
          width: 32,
          fixed: 'right',
          render: (_, record) => {
            return (
              <Tooltip title="Decouple">
                <Button
                  size="small"
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    modalDispatch({
                      type: ModalContextActions.SHOW_MODAL,
                      value: {
                        title: 'Decouple supplier',
                        description: `Supplier ”${(record as TMappedSupplierRecord).name}” will be removed from the list.
                        You can add it again on My Suppliers page.`,
                        actionText: 'Decouple',
                        action: ActionType.DECOUPLE_SUPPLIER,
                        dataId: (record as TMappedSupplierRecord).connectionId,
                        dataName: (record as TMappedSupplierRecord).name || '-',
                      },
                    });
                  }}
                  icon={
                    <IconUnlink
                      color={styles.iconFill}
                      height="16"
                      width="16"
                    />
                  }
                />
              </Tooltip>
            );
          },
        };
      default:
        return { hidden: true };
    }
  });
  return result;
};
