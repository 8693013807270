import { Typography, theme } from 'antd';
import clsx from 'clsx';
import { Logo } from 'src/components/logo/Logo';
import useResetPasswordForm from 'src/hooks/use-reset-password-form';
import ResetPasswordForm from '../form/ResetPasswordForm';
import styles from './ResetPasswordModal.module.scss';

const { Title } = Typography;

type TResetPasswordModalProps = {
  className?: string;
  resetToken: string;
  email: string;
};

const ResetPasswordModal = ({
  className,
  resetToken,
  email,
}: TResetPasswordModalProps) => {
  const {
    token: {
      colorBgContainer,
      boxShadowTertiary,
      borderRadiusLG,
      paddingXL,
      paddingLG,
    },
  } = theme.useToken();

  const {
    loading,
    postError,
    form,
    password,
    submit,
    mounted,
    passwordConfirm,
    isPasswordValid,
    getValidationStatus,
    validationStatus,
    handleChange,
  } = useResetPasswordForm(resetToken, email);

  return (
    <div
      className={clsx(
        styles.container,
        {
          [styles.transitionIn]: mounted,
        },
        className
      )}
      style={{
        backgroundColor: colorBgContainer,
        boxShadow: boxShadowTertiary,
        borderRadius: borderRadiusLG,
        gap: paddingLG,
        padding: paddingXL,
      }}
    >
      <div className={styles.logoContainer}>
        <Title level={3} className={styles.title}>
          Welcome to
        </Title>
        <Logo className={styles.logo} width="188" height="47" />
      </div>
      <ResetPasswordForm
        form={form}
        password={password}
        passwordConfirm={passwordConfirm}
        submit={submit}
        postError={postError}
        loading={loading}
        isPasswordValid={isPasswordValid}
        getValidationStatus={getValidationStatus}
        validationStatus={validationStatus}
        handleChange={handleChange}
      />
    </div>
  );
};

export default ResetPasswordModal;
