import { useState } from 'react';
import { IconAdd } from 'src/components/icons/add/IconAdd';
import { IconDownload } from 'src/components/icons/download/IconDownload';
import { IconUpload } from 'src/components/icons/upload/IconUpload';
import Table from 'src/components/table/Table';
import TableHeader from 'src/components/table/header/TableHeader';
import { NO_PRODUCTS_ADDED, NO_RESULTS } from 'src/constants/validation';
import useProductsTable from 'src/hooks/use-products-table';
import { TMappedMyProductListRecord } from 'src/utils/map-product-table';
import { Mode } from '../drawer/ProductsDrawer';
import styles from './ProductsTable.module.scss';

type TProductsTableProps = {
  openDrawer: (mode: Mode, productId?: string) => void;
  openImport: () => void;
};

const ProductsTable = ({ openDrawer, openImport }: TProductsTableProps) => {
  const {
    setSearch,
    columns,
    handleTableChange,
    loading,
    width,
    data,
    total,
    debouncedSearch,
    openAdd,
    openRead,
    search,
    current,
    exportAllProducts,
    isAdvancedUser,
  } = useProductsTable({ openDrawer });

  return (
    <>
      <TableHeader
        searchPlaceholder={'Product name, number, code'}
        setSearch={setSearch}
        primaryActionTitle={'Import Products'}
        primaryActionIcon={<IconUpload width="16" height="16" />}
        primaryAction={openImport}
        search={search}
        onlySecondaryAction={isAdvancedUser}
        secondaryActionTitle={'Add Product'}
        secondaryActionIcon={<IconAdd width="16" height="16" />}
        secondaryAction={openAdd}
        customActionTitle={'Export to .xlsx'}
        customActionIcon={<IconDownload width="16" height="16" />}
        cusotmAction={exportAllProducts}
      />
      <Table<TMappedMyProductListRecord>
        emptyTextDescription={debouncedSearch ? NO_RESULTS : NO_PRODUCTS_ADDED}
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        rowClassName={() => {
          return styles.interactive;
        }}
        onRow={(record) => ({
          onClick: () => {
            openRead(record);
          },
        })}
        loading={loading}
        width={width}
        height={'calc(100vh - 326px)'}
        total={total}
        current={current}
      />
    </>
  );
};

export default ProductsTable;
