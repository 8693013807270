import {
  AutoComplete,
  Avatar,
  Button,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  Row,
  Switch,
  Typography,
} from 'antd';
import FormItem from 'src/components/form-item/FormItem';
import { IconCheckbook } from 'src/components/icons/checkbook/IconCheckbook';
import { IconHoneycomb } from 'src/components/icons/honeycomb/IconHoneycomb';
import Loader from 'src/components/loader/Loader';
import PhoneInput from 'src/components/phone-input/PhoneInput';
import Select from 'src/components/select/Select';
import { CompanyFormFields, companyFormLabels } from 'src/constants/company';
import { EMAIL_REGEX } from 'src/constants/regex';
import {
  EXISTING_COMPANY,
  INCORECT_EMAIL,
  NO_REGION,
  NO_RESULTS,
  REQUIRED,
} from 'src/constants/validation';
import useCompanyProfileForm from 'src/hooks/use-company-profile-form';
import useUserSelect from 'src/hooks/use-user-select';
import styles from './CompanyForm.module.scss';
import useUsers from 'src/hooks/use-users';
import { requiredValidator } from 'src/utils/validators';

const CompanyForm = () => {
  const {
    resetForm,
    submitForm,
    form,
    industries,
    countries,
    cities,
    states,
    country,
    setSearch,
    setStateSearch,
    getStateByCity,
    dialCode,
    isPrimaryManual,
    setIsPrimaryManual,
    isSecondaryManual,
    setIsSecondaryManual,
    isChanged,
    loading,
    region,
    initialCompanyName,
    stateSearch,
    validateCompanyName,
  } = useCompanyProfileForm();

  const {
    loading: primaryLoading,
    setSearch: setPrimarySearch,
    users: primaryUsers,
  } = useUserSelect();
  const {
    loading: secondaryLoading,
    setSearch: setSecondarySearch,
    users: secondaryUsers,
  } = useUserSelect();

  const { validateContactEmail } = useUsers();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Form
          form={form}
          layout="vertical"
          requiredMark
          className={styles.form}
        >
          <FormItem label="Company logo">
            <Avatar
              className={styles.logo}
              size={80}
              shape="square"
              icon={<IconHoneycomb height="56" width="56" />}
            />
          </FormItem>

          <Divider className={styles.divider} />

          <FormItem
            name={CompanyFormFields.NAME}
            label={companyFormLabels[CompanyFormFields.NAME]}
            validateDebounce={500}
            rules={[
              { required: true, validator: requiredValidator },
              { max: 50 },
              {
                validator: async (_, value) => {
                  if (
                    value?.toLowerCase() === initialCompanyName?.toLowerCase()
                  ) {
                    return Promise.resolve();
                  }
                  const validationResult = await validateCompanyName(value);

                  if (validationResult.errors) {
                    return Promise.reject(EXISTING_COMPANY);
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input />
          </FormItem>

          <FormItem
            name={CompanyFormFields.INDUSTRY}
            label={companyFormLabels[CompanyFormFields.INDUSTRY]}
            rules={[
              {
                required: true,
                message: REQUIRED,
              },
            ]}
          >
            <Select
              popupMatchSelectWidth={false}
              options={industries}
              placeholder="Select"
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={NO_RESULTS}
                />
              }
            />
          </FormItem>

          <div className={styles.row}>
            <FormItem
              className={styles.rowItem}
              name={CompanyFormFields.COUNTRY}
              label={companyFormLabels[CompanyFormFields.COUNTRY]}
              rules={[
                {
                  required: true,
                  message: REQUIRED,
                },
              ]}
            >
              <Select
                popupMatchSelectWidth={false}
                showSearch
                placeholder="Select"
                optionLabelProp="value"
                onSelect={(value) => {
                  if (value !== country) {
                    form.setFieldValue(CompanyFormFields.REGION, '');
                    form.setFieldValue(CompanyFormFields.CITY, '');
                  }
                }}
                onSearch={(value) => {
                  setSearch(value);
                }}
                options={countries}
                optionFilterProp="value"
                notFoundContent={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={NO_RESULTS}
                  />
                }
              />
            </FormItem>

            <FormItem
              className={styles.rowItem}
              name={CompanyFormFields.REGION}
              label={companyFormLabels[CompanyFormFields.REGION]}
            >
              <Select
                allowClear
                popupMatchSelectWidth={false}
                showSearch
                placeholder="Select"
                onSearch={(value) => {
                  setStateSearch(value);
                }}
                options={states}
                optionFilterProp="value"
                notFoundContent={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={stateSearch ? NO_RESULTS : NO_REGION}
                  />
                }
              />
            </FormItem>
          </div>

          <div className={styles.row}>
            <FormItem
              className={styles.rowItem}
              name={CompanyFormFields.POSTAL_CODE}
              label={companyFormLabels[CompanyFormFields.POSTAL_CODE]}
              rules={[{ max: 20 }]}
            >
              <Input />
            </FormItem>

            <FormItem
              className={styles.rowItem}
              name={CompanyFormFields.CITY}
              label={companyFormLabels[CompanyFormFields.CITY]}
              rules={[{ max: 50 }]}
            >
              <AutoComplete
                allowClear
                popupMatchSelectWidth={false}
                options={cities}
                optionFilterProp="value"
                onSelect={(value) => {
                  if (!region) {
                    form.setFieldValue(
                      CompanyFormFields.REGION,
                      getStateByCity(value)
                    );
                  }
                }}
                notFoundContent={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={NO_RESULTS}
                  />
                }
              />
            </FormItem>
          </div>

          <FormItem
            name={CompanyFormFields.FIRST_ADDRESS_LINE}
            label={companyFormLabels[CompanyFormFields.FIRST_ADDRESS_LINE]}
            rules={[
              {
                max: 100,
              },
            ]}
          >
            <Input />
          </FormItem>

          <FormItem
            name={CompanyFormFields.SECOND_ADDRESS_LINE}
            label={companyFormLabels[CompanyFormFields.SECOND_ADDRESS_LINE]}
            rules={[
              {
                max: 100,
              },
            ]}
          >
            <Input />
          </FormItem>

          <PhoneInput max={16 - dialCode.length} />

          <Divider orientation="left" plain className={styles.divider}>
            Contacts
          </Divider>

          <Row align="middle" justify="space-between" className={styles.label}>
            <Col>
              <Typography.Text className={styles.title}>
                Primary contact
              </Typography.Text>
            </Col>

            <Col>
              <Row align="middle" className={styles.toggle}>
                <Typography.Text>Specify manually</Typography.Text>

                <Switch
                  checked={isPrimaryManual}
                  onChange={setIsPrimaryManual}
                />
              </Row>
            </Col>
          </Row>
          {isPrimaryManual ? (
            <>
              <div className={styles.row}>
                <FormItem
                  className={styles.rowItem}
                  name={CompanyFormFields.PRIMARY_CONTACT_FIRST_NAME}
                  label={
                    companyFormLabels[
                      CompanyFormFields.PRIMARY_CONTACT_FIRST_NAME
                    ]
                  }
                  rules={[
                    {
                      required: true,
                      validator: requiredValidator,
                    },
                    { max: 50 },
                  ]}
                >
                  <Input />
                </FormItem>

                <FormItem
                  className={styles.rowItem}
                  name={CompanyFormFields.PRIMARY_CONTACT_LAST_NAME}
                  label={
                    companyFormLabels[
                      CompanyFormFields.PRIMARY_CONTACT_LAST_NAME
                    ]
                  }
                  rules={[
                    {
                      required: true,
                      validator: requiredValidator,
                    },
                    { max: 50 },
                  ]}
                >
                  <Input />
                </FormItem>
              </div>
              <FormItem
                className={styles.rowItem}
                name={CompanyFormFields.PRIMARY_CONTACT_JOB_TITLE}
                label={
                  companyFormLabels[CompanyFormFields.PRIMARY_CONTACT_JOB_TITLE]
                }
                rules={[{ max: 150 }]}
              >
                <Input />
              </FormItem>

              <FormItem
                className={styles.rowItem}
                name={CompanyFormFields.PRIMARY_CONTACT_EMAIL}
                label={
                  companyFormLabels[CompanyFormFields.PRIMARY_CONTACT_EMAIL]
                }
                validateDebounce={500}
                rules={[
                  { required: true, message: REQUIRED },
                  { max: 150 },
                  {
                    validator: async (_, value) => {
                      if (!value) {
                        return Promise.resolve();
                      }
                      const isValid = EMAIL_REGEX.test(value as string);
                      if (!isValid) {
                        return Promise.reject(INCORECT_EMAIL);
                      }
                      const validationResult =
                        await validateContactEmail(value);

                      if (validationResult.errors) {
                        return Promise.reject(
                          'Account with this email already exists. Please select user from the list.'
                        );
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </FormItem>
            </>
          ) : (
            <FormItem name={CompanyFormFields.PRIMARY_CONTACT}>
              <Select
                allowClear
                filterOption={false}
                loading={primaryLoading}
                optionLabelProp="title"
                showSearch
                placeholder="Select from users list"
                onSearch={(value) => {
                  setPrimarySearch(value);
                }}
                options={primaryUsers}
                notFoundContent={
                  <div className={styles.notFoundContainer}>
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={NO_RESULTS}
                    />
                    <Divider className={styles.notFoundDivider} />
                    <Button
                      className={styles.notFoundButton}
                      type="text"
                      icon={<IconCheckbook height="16" width="16" />}
                      onClick={() => {
                        setIsPrimaryManual(true);
                      }}
                    >
                      Specify contact manually
                    </Button>
                  </div>
                }
              />
            </FormItem>
          )}

          <Row align="middle" justify="space-between" className={styles.label}>
            <Col>
              <Typography.Text className={styles.title}>
                Secondary contact
              </Typography.Text>
            </Col>

            <Col>
              <Row align="middle" className={styles.toggle}>
                <Typography.Text>Specify manually</Typography.Text>

                <Switch
                  checked={isSecondaryManual}
                  onChange={setIsSecondaryManual}
                />
              </Row>
            </Col>
          </Row>

          {isSecondaryManual ? (
            <>
              <div className={styles.row}>
                <FormItem
                  className={styles.rowItem}
                  name={CompanyFormFields.SECONDARY_CONTACT_FIRST_NAME}
                  label={
                    companyFormLabels[
                      CompanyFormFields.SECONDARY_CONTACT_FIRST_NAME
                    ]
                  }
                  rules={[
                    {
                      required: true,
                      validator: requiredValidator,
                    },
                    { max: 50 },
                  ]}
                >
                  <Input />
                </FormItem>

                <FormItem
                  className={styles.rowItem}
                  name={CompanyFormFields.SECONDARY_CONTACT_LAST_NAME}
                  label={
                    companyFormLabels[
                      CompanyFormFields.SECONDARY_CONTACT_LAST_NAME
                    ]
                  }
                  rules={[
                    {
                      required: true,
                      validator: requiredValidator,
                    },
                    { max: 50 },
                  ]}
                >
                  <Input />
                </FormItem>
              </div>

              <FormItem
                className={styles.rowItem}
                name={CompanyFormFields.SECONDARY_CONTACT_JOB_TITLE}
                label={
                  companyFormLabels[
                    CompanyFormFields.SECONDARY_CONTACT_JOB_TITLE
                  ]
                }
                rules={[{ max: 150 }]}
              >
                <Input />
              </FormItem>

              <FormItem
                className={styles.rowItem}
                name={CompanyFormFields.SECONDARY_CONTACT_EMAIL}
                label={
                  companyFormLabels[CompanyFormFields.SECONDARY_CONTACT_EMAIL]
                }
                validateDebounce={500}
                rules={[
                  { required: true, message: REQUIRED },
                  { max: 150 },
                  {
                    validator: async (_, value) => {
                      if (!value) {
                        return Promise.resolve();
                      }
                      const isValid = EMAIL_REGEX.test(value as string);
                      if (!isValid) {
                        return Promise.reject(INCORECT_EMAIL);
                      }
                      const validationResult =
                        await validateContactEmail(value);

                      if (validationResult.errors) {
                        return Promise.reject(
                          'Account with this email already exists. Please select user from the list.'
                        );
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </FormItem>
            </>
          ) : (
            <FormItem name={CompanyFormFields.SECONDARY_CONTACT}>
              <Select
                allowClear
                filterOption={false}
                loading={secondaryLoading}
                optionLabelProp="title"
                showSearch
                placeholder="Select from users list"
                onSearch={(value) => {
                  setSecondarySearch(value);
                }}
                options={secondaryUsers}
                notFoundContent={
                  <div className={styles.notFoundContainer}>
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={NO_RESULTS}
                    />
                    <Divider className={styles.notFoundDivider} />
                    <Button
                      className={styles.notFoundButton}
                      type="text"
                      icon={<IconCheckbook height="16" width="16" />}
                      onClick={() => {
                        setIsSecondaryManual(true);
                      }}
                    >
                      Specify contact manually
                    </Button>
                  </div>
                }
              />
            </FormItem>
          )}
        </Form>
      </div>
      {isChanged && (
        <div className={styles.buttonsContainer}>
          <Button onClick={resetForm}>Cancel</Button>
          <Button onClick={submitForm} type="primary">
            Save
          </Button>
        </div>
      )}
      <Loader visible={loading} />
    </div>
  );
};

export default CompanyForm;
