import {
  Alert,
  Badge,
  BadgeProps,
  Button,
  ConfigProvider,
  Empty,
  Form,
  Input,
  Typography,
  theme,
  type FormInstance,
} from 'antd';

import clsx from 'clsx';
import Select from 'src/components/select/Select';
import { SignUpFormFields, signInFormLabels } from 'src/constants/sign-up';
import {
  EXISTING_COMPANY,
  NOT_MATCHING,
  NO_RESULTS,
  REQUIRED,
} from 'src/constants/validation';
import useIdentity from 'src/hooks/use-identity';
import { TSignUpForm } from 'src/hooks/use-signup-form';
import useCompanySelects from 'src/hooks/use-company-selects';
import { IValidateTokenResult } from 'src/types/general';
import { requiredValidator } from 'src/utils/validators';
import FormItem from '../../form-item/FormItem';
import styles from './SignUpForm.module.scss';

const { Text } = Typography;

export type TSignUpFormProps = {
  step: number;
  form: FormInstance<TSignUpForm>;
  company: string;
  invitationId: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirm: string;
  submit: () => void;
  postError: string;
  loading: boolean;
  userInfo: IValidateTokenResult;
  isPasswordValid: boolean;
  getValidationStatus: (value: boolean) => BadgeProps['status'];
  validationStatus: Record<string, boolean>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
};

const SignUpForm = ({
  step,
  form,
  company,
  firstName,
  lastName,
  password,
  passwordConfirm,
  submit,
  postError,
  loading,
  userInfo,
  handleChange,
  validationStatus,
  getValidationStatus,
  isPasswordValid,
  invitationId,
}: TSignUpFormProps) => {
  const { validateCompanyName } = useIdentity();

  const {
    token: { marginXS, margin, colorErrorBg, colorErrorBorder },
  } = theme.useToken();

  const isMatching = password === passwordConfirm;

  const { industries, countries, setSearch } = useCompanySelects();

  return (
    <div>
      <div style={{ gap: margin }} className={styles.container}>
        {((step === 2 && !userInfo.isCompanyRegistration) || step === 1) && (
          <Text className={styles.description}>
            You were invited by {userInfo.senderEmail} from{' '}
            {userInfo.senderCompany}.
          </Text>
        )}
        <Form form={form} layout="vertical" requiredMark={false}>
          {userInfo.isCompanyRegistration && (
            <div
              className={clsx({
                [styles.hidden]: step !== 1,
              })}
            >
              <FormItem
                label={signInFormLabels[SignUpFormFields.COMPANY]}
                name={SignUpFormFields.COMPANY}
                isFeedbackShown={!company}
                preserve
                rules={[
                  { required: true, message: REQUIRED },
                  { max: 50 },
                  {
                    validator: async (_, value) => {
                      if (!value) {
                        return Promise.resolve();
                      }

                      if (!value.trim().length) {
                        return Promise.reject(REQUIRED);
                      }

                      const validationResult = await validateCompanyName(
                        value,
                        invitationId
                      );

                      if (validationResult.errors) {
                        return Promise.reject(EXISTING_COMPANY);
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
                validateDebounce={500}
                hasFeedback
              >
                <Input
                  autoFocus={userInfo.isCompanyRegistration}
                  size="large"
                  suffix={!company ? undefined : <span />}
                />
              </FormItem>
              <FormItem
                label={signInFormLabels[SignUpFormFields.INDUSTRY]}
                name={SignUpFormFields.INDUSTRY}
                preserve
                rules={[
                  {
                    required: true,
                    message: REQUIRED,
                  },
                ]}
              >
                <Select
                  popupMatchSelectWidth={false}
                  size="large"
                  options={industries}
                  placeholder="Select"
                  notFoundContent={
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={NO_RESULTS}
                    />
                  }
                />
              </FormItem>
              <FormItem
                label={signInFormLabels[SignUpFormFields.COUNTRY]}
                name={SignUpFormFields.COUNTRY}
                preserve
                rules={[
                  {
                    required: true,
                    message: REQUIRED,
                  },
                ]}
              >
                <Select
                  popupMatchSelectWidth={false}
                  size="large"
                  showSearch
                  placeholder="Select"
                  optionLabelProp="value"
                  onSearch={(value) => {
                    setSearch(value);
                  }}
                  options={countries}
                  optionFilterProp="value"
                  notFoundContent={
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={NO_RESULTS}
                    />
                  }
                />
              </FormItem>
            </div>
          )}
          {step === 2 && (
            <>
              {userInfo.isCompanyRegistration && (
                <div className={styles.row}>
                  <FormItem
                    className={styles.rowItem}
                    label={signInFormLabels[SignUpFormFields.FIRST_NAME]}
                    name={SignUpFormFields.FIRST_NAME}
                    isFeedbackShown={!firstName}
                    rules={[
                      {
                        required: true,
                        validator: requiredValidator,
                        message: REQUIRED,
                      },
                      { max: 50 },
                    ]}
                    hasFeedback
                  >
                    <Input
                      size="large"
                      autoFocus
                      suffix={!firstName ? undefined : <span />}
                    />
                  </FormItem>
                  <FormItem
                    className={styles.rowItem}
                    label={signInFormLabels[SignUpFormFields.LAST_NAME]}
                    name={SignUpFormFields.LAST_NAME}
                    isFeedbackShown={!lastName}
                    rules={[
                      {
                        required: true,
                        validator: requiredValidator,
                        message: REQUIRED,
                      },
                      { max: 50 },
                    ]}
                    hasFeedback
                  >
                    <Input
                      size="large"
                      suffix={!lastName ? undefined : <span />}
                    />
                  </FormItem>
                </div>
              )}
              <FormItem
                label={signInFormLabels[SignUpFormFields.EMAIL]}
                name={SignUpFormFields.EMAIL}
              >
                <Input size="large" disabled />
              </FormItem>
              <FormItem
                className={styles.noMargin}
                label={signInFormLabels[SignUpFormFields.PASSWORD]}
                name={SignUpFormFields.PASSWORD}
                validateStatus={
                  isPasswordValid
                    ? 'success'
                    : typeof password === 'undefined'
                      ? undefined
                      : 'error'
                }
                isFeedbackShown={!password || !isPasswordValid || isMatching}
                rules={[{ required: true, message: REQUIRED }, { max: 64 }]}
                hasFeedback
              >
                <Input.Password
                  size="large"
                  autoFocus={!userInfo.isCompanyRegistration}
                  onChange={handleChange}
                  suffix={password ? undefined : <span />}
                />
              </FormItem>
              <Badge
                className={clsx(styles.badge, styles.top, {
                  [styles.inactive]: !password,
                })}
                status={getValidationStatus(validationStatus.minLength)}
                text="Minimum 8 characters"
              />
              <Badge
                className={clsx(styles.badge, {
                  [styles.inactive]: !password,
                })}
                status={getValidationStatus(validationStatus.hasDigitOrSymbol)}
                text="Contains at least 1 digit (0-9) or special symbol"
              />
              <Badge
                className={clsx(styles.badge, styles.bottom, {
                  [styles.inactive]: !password,
                })}
                status={getValidationStatus(validationStatus.hasUpperLower)}
                text="Contains both lower (a-z) and upper case letters (A-Z)"
              />
              <FormItem
                label={signInFormLabels[SignUpFormFields.PASSWORD_CONFIRM]}
                name={SignUpFormFields.PASSWORD_CONFIRM}
                isFeedbackShown={
                  typeof passwordConfirm !== 'undefined' || isMatching
                }
                rules={[
                  { required: true, message: REQUIRED },
                  { max: 64 },
                  {
                    validator: (_, value) => {
                      if (value === password || !value) {
                        return Promise.resolve();
                      }
                      return Promise.reject();
                    },
                    message: NOT_MATCHING,
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  size="large"
                  suffix={
                    !passwordConfirm && !isMatching ? undefined : <span />
                  }
                />
              </FormItem>
            </>
          )}
          {postError && (
            <ConfigProvider
              theme={{
                token: {
                  colorWarningBg: colorErrorBg,
                  colorWarningBorder: colorErrorBorder,
                },
              }}
            >
              <Alert
                style={{
                  marginBottom: margin,
                }}
                className={styles.error}
                showIcon
                message={postError}
                type="warning"
              />
            </ConfigProvider>
          )}
          <Button
            style={{
              marginTop: marginXS,
            }}
            className={styles.submit}
            size="large"
            type="primary"
            loading={loading}
            onClick={submit}
          >
            {step === 1 ? 'Proceed' : 'Sign up'}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default SignUpForm;
