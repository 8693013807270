import { Tabs, TabsProps } from 'antd';
import { useMemo } from 'react';
import ImportModal from 'src/components/import-modal/ImportModal';
import ProductsDrawer from 'src/components/products/drawer/ProductsDrawer';
import SuppliersProductsTable from 'src/components/products/suppliers-table/SuppliersProductsTable';
import ProductsTable from 'src/components/products/table/ProductsTable';
import useProductsList from 'src/hooks/use-products-list';
import { ImportEntity } from 'src/types/import';

const ProductList = () => {
  const {
    productId,
    mode,
    open,
    closeDrawer,
    defaultTab,
    handleTabChange,
    openDrawer,
    visible,
    setVisible,
  } = useProductsList();

  const productTabs: TabsProps['items'] = useMemo(
    () => [
      {
        key: '1',
        label: 'My company products',
        children: (
          <ProductsTable
            openDrawer={openDrawer}
            openImport={() => {
              setVisible(true);
            }}
          />
        ),
      },
      {
        key: '2',
        label: "My suppliers' products",
        children: <SuppliersProductsTable />,
      },
    ],
    []
  );

  return (
    <div>
      <Tabs
        activeKey={defaultTab}
        items={productTabs}
        onChange={handleTabChange}
      />

      <ProductsDrawer
        open={open}
        productId={productId}
        onClose={closeDrawer}
        mode={mode}
      />

      <ImportModal
        mode={ImportEntity.Product}
        visible={visible}
        onClose={() => setVisible(false)}
      />
    </div>
  );
};

export default ProductList;
