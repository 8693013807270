import {
  HTTP,
  TValidationError,
  useNetworkContext,
} from 'src/contexts/network-context';
import {
  TGetAccessTokenResponse,
  TGetIndustriesResponse,
  TGetPermissionsResponse,
  TLoginParams,
  TLoginResponse,
  TRegisterParams,
  TRegisterResponse,
  TResetPasswordParams,
  TResetPasswordResponse,
  TValidateResetTokenResponse,
} from 'src/types/backend';
import { IValidateTokenError, IValidateTokenResult } from 'src/types/general';

const useIdentity = () => {
  const { fetch } = useNetworkContext();

  const loginUser = async (data: TLoginParams) => {
    const result = await fetch<TLoginResponse, unknown>({
      url: '/identity/login',
      method: HTTP.POST,
      data,
    });

    return result;
  };

  const registerUser = async (data: TRegisterParams) => {
    const result = await fetch<TRegisterResponse, TValidationError>({
      url: '/identity/sign-up/user',
      method: HTTP.PUT,
      data,
    });

    return result;
  };

  const getPermissions = async () => {
    const result = await fetch<TGetPermissionsResponse, unknown>({
      url: '/identity/me/roles',
      method: HTTP.GET,
    });

    return result;
  };

  const validateSignInCompany = async (company: string) => {
    const result = await fetch<string, string>({
      url: '/identity/company/exist',
      method: HTTP.POST,
      data: {
        company,
      },
    });

    return result;
  };

  const getAccessToken = async (refreshToken: string) => {
    const result = await fetch<TGetAccessTokenResponse, unknown>({
      url: '/identity/refresh',
      method: HTTP.POST,
      data: {
        refreshToken,
      },
    });
    return result;
  };

  const validateToken = async (invitationId: string) => {
    const result = await fetch<IValidateTokenResult, IValidateTokenError>({
      url: '/identity/registration-token',
      method: HTTP.POST,
      data: { invitationId },
    });

    return result;
  };

  const getIndustries = async () => {
    const result = await fetch<TGetIndustriesResponse, unknown>({
      url: '/identity/sign-up/company/industry',
      method: HTTP.GET,
    });

    return result;
  };

  const registerCompany = async (data: TRegisterParams) => {
    const result = await fetch<TRegisterResponse, TValidationError>({
      url: '/identity/sign-up/company',
      method: HTTP.PUT,
      data,
    });

    return result;
  };

  const validateCompanyName = async (name: string, invitationId: string) => {
    const result = await fetch<TRegisterResponse, TValidationError>({
      url: '/identity/sign-up/company/exist',
      method: HTTP.POST,
      data: {
        name,
        invitationId,
      },
    });

    return result;
  };

  const sendResetEmail = async (email: string) => {
    const result = await fetch<unknown, string>({
      url: '/identity/forgot-password',
      method: HTTP.POST,
      data: {
        email,
      },
    });

    return result;
  };

  const validateResetToken = async (token: string, email: string) => {
    const result = await fetch<TValidateResetTokenResponse, unknown>({
      url: '/identity/reset-token',
      method: HTTP.POST,
      data: { token, email },
    });

    return result;
  };

  const resetPassword = async (data: TResetPasswordParams) => {
    const result = await fetch<TResetPasswordResponse, TValidationError>({
      url: '/identity/reset-password',
      method: HTTP.POST,
      data,
    });

    return result;
  };

  return {
    loginUser,
    validateSignInCompany,
    getAccessToken,
    getPermissions,
    validateToken,
    registerUser,
    getIndustries,
    registerCompany,
    validateCompanyName,
    sendResetEmail,
    validateResetToken,
    resetPassword,
  };
};

export default useIdentity;
