import { StepsProps, UploadProps } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useEffect, useState } from 'react';
import useFiles from 'src/hooks/use-files';
import { ImportEntity } from 'src/types/import';
import { getErrorText } from 'src/utils/get-error-text';

const FILE_LIMIT = 3145728; // Note: 3MB

type TUseFirstStepParams = {
  importId: string | null;
  setProgress: (value: number) => void;
  setImportId: (value: string) => void;
  setStepWithReset: (step: number, status?: StepsProps['status']) => void;
  mode: ImportEntity;
  customerId?: string;
};

const useFirstStep = ({
  importId,
  setProgress,
  setImportId,
  setStepWithReset,
  mode,
  customerId,
}: TUseFirstStepParams) => {
  const { uploadXlsx, abortUploadXlsx, deleteXlsx } = useFiles();

  const [file, setFile] = useState<RcFile | null>(null);
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const [fileError, setFileError] = useState<boolean>(false);
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [fileDisabled, setFileDisabled] = useState<boolean>(false);

  const draggerProps: UploadProps = {
    style: {
      ...(file
        ? {
            display: 'none',
          }
        : {
            maxHeight: '146px',
          }),
    },
    accept:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      setFile(file);

      return false;
    },
    fileList: [],
  };

  const onImportDelete = async () => {
    if (fileLoading) {
      abortUploadXlsx();
    } else if (importId) {
      setFileDisabled(true);

      await deleteXlsx(importId);

      setFileDisabled(false);
    }

    setImportId('');
  };

  useEffect(() => {
    // Note: clean up after file deletion

    if (!importId) {
      setStepWithReset(0);
      setFile(null);
    }
  }, [importId]);

  const uploadFile = async () => {
    if (!file) {
      return;
    }
    if (file.size > FILE_LIMIT) {
      setFileError(true);
      return;
    }
    setFileError(false);
    setFileErrorMessage('');
    setFileLoading(true);

    const body = new FormData();
    body.append('File', file);
    body.append('ImportEntity', String(mode));

    if (customerId) {
      body.append('CustomerCompanyId', customerId);
    }

    const res = await uploadXlsx(body, setProgress);

    setProgress(0);

    if (res.result?.data) {
      const id = res.result.data.id;

      setImportId(id);
      setStepWithReset(0, 'finish');
    }

    if (res.errors && res.errors.code !== 'ERR_CANCELED') {
      setFileError(true);
      setFileErrorMessage(getErrorText(res.errors.response!.data.errors)[0]);
    }

    setFileLoading(false);
  };

  return {
    uploadFile,
    onImportDelete,
    abortUploadXlsx,
    draggerProps,
    file,
    fileError,
    fileErrorMessage,
    fileDisabled,
    fileLoading,
  };
};

export default useFirstStep;
