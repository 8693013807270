import { Spin } from 'antd';
import styles from './Loader.module.scss';
import clsx from 'clsx';

type TLoaderProps = {
  visible: boolean;
  text?: string;
  className?: string;
};

const Loader = ({ visible, text = 'Loading', className }: TLoaderProps) => {
  if (!visible) {
    return null;
  }

  return (
    <div className={clsx(styles.component, className)}>
      <Spin tip={text}>{text}</Spin>
    </div>
  );
};

export default Loader;
