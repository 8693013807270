import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import { Mode } from 'src/components/products/drawer/ProductsDrawer';
import { ProtectedRoutes } from 'src/constants/routes';
import { useMeasurementsContext } from 'src/contexts/measurements-context';
import { useModalContext } from 'src/contexts/modal-context';
import { useUserContext } from 'src/contexts/user-context';
import { ITableParamsWithSearch } from 'src/types/generic';
import {
  TMappedMyProductListRecord,
  getMappedProductData,
  getProductColumnSetting,
} from 'src/utils/map-product-table';
import useProducts from './use-products';
import useTableSettings from './use-table-settings';
import useTableWidth from './use-table-width';
import { EXPORT_PRODUCTS } from 'src/constants/local-storage';

type TUseProductsTableParams = {
  openDrawer: (mode: Mode, productId?: string) => void;
};

const useProductsTable = ({ openDrawer }: TUseProductsTableParams) => {
  const {
    userContextState: { isAdmin, isAdvancedUser },
  } = useUserContext();

  const {
    uoms,
    currencies,
    loading: measurementsLoading,
  } = useMeasurementsContext();

  const { modalContextDispatch } = useModalContext();
  const { getProducts, exportProducts } = useProducts();

  const navigate = useNavigate();

  const [data, setData] = useState<TMappedMyProductListRecord[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const isActionAllowed = isAdmin || isAdvancedUser;

  const fetchProducts = async (tableParams: ITableParamsWithSearch) => {
    setLoading(true);
    const result = await getProducts(tableParams);
    if (result.result?.data.data) {
      setData(getMappedProductData(result.result.data.data));
      setTotal(result.result.data.totalCount);
    }
    setLoading(false);
  };

  const { tableParams, search, setSearch, handleTableChange } =
    useTableSettings({
      sortField: 'createdAt',
      sortOrder: SortOrder.DESC,
      fetcher: fetchProducts,
    });

  const openAdd = () => {
    openDrawer(Mode.CREATE);
  };

  const openRead = (record: TMappedMyProductListRecord) => {
    navigate(`${ProtectedRoutes.PRODUCTS}/${record.key}`);
  };

  const openEdit = (record: TMappedMyProductListRecord) => {
    openDrawer(Mode.EDIT, record.key);
  };

  const columns = useMemo(() => {
    const res = getProductColumnSetting({
      modalDispatch: modalContextDispatch,
      isActionAllowed,
      search: tableParams.search,
      openEdit,
      currencies,
      uoms,
    });
    return res;
  }, [tableParams, isActionAllowed, uoms, currencies]);

  const tableWidth = useTableWidth(columns);

  const exportAllProducts = () => {
    exportProducts({
      ...tableParams,
      pagination: {
        current: 1,
        pageSize: total,
      },
    });
  };

  // Note: used for export in ImportModal
  useEffect(() => {
    const broadcast = new BroadcastChannel(EXPORT_PRODUCTS);
    broadcast.onmessage = exportAllProducts;

    return () => {
      broadcast.close();
    };
  }, [exportAllProducts]);

  return {
    columns,
    data,
    total,
    handleTableChange,
    setSearch,
    loading: loading || measurementsLoading,
    width: tableWidth,
    debouncedSearch: tableParams.search,
    openAdd,
    openRead,
    search,
    current: tableParams.pagination?.current,
    exportAllProducts,
    isAdvancedUser,
  };
};

export default useProductsTable;
