import dayjs from 'dayjs';
import { EXPORT_FAILED } from 'src/constants/validation';
import {
  HTTP,
  TValidationError,
  useNetworkContext,
} from 'src/contexts/network-context';
import {
  TGetDefaultColumnsResponse,
  TGetSystemColumnsResponse,
  TGetXlsxColumnsResponse,
  TGetXlsxStatusResponse,
  TUploadInvoiceResponse,
} from 'src/types/backend';
import { IFile } from 'src/types/general';
import { ImportEntity } from 'src/types/import';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from 'src/utils/date-formatter';
import { downloadFile } from 'src/utils/download';

const useFiles = () => {
  const { fetch } = useNetworkContext();

  const abortController = new AbortController();

  const download = (
    file: Partial<IFile> | null | undefined,
    isInvoice: boolean
  ) => {
    if (!file?.blobName) {
      return;
    }
    fetch<Blob, unknown>({
      url: `/${isInvoice ? 'invoice' : 'epd'}/download/${file.blobName}`,
      responseType: 'blob',
    }).then((response) => {
      downloadFile(response, file.fileName || '');
    });
  };

  const uploadInvoice = async (
    data: FormData,
    setProgress: (value: number) => void
  ) => {
    const result = await fetch<TUploadInvoiceResponse, TValidationError>({
      url: '/invoice/upload',
      data,
      method: HTTP.POST,
      signal: abortController.signal,
      onUploadProgress: (progress) => {
        if (progress.progress) {
          setProgress(progress.progress * 100);
        }
      },
    });

    return result;
  };

  const deleteInvoice = async (invoiceId: string) => {
    const result = await fetch({
      url: `/invoice/${invoiceId}`,
      method: HTTP.DELETE,
    });

    return result;
  };

  const abortUploadInvoice = () => {
    abortController.abort();
  };

  const processInvoice = async (data: string[]) => {
    const result = await fetch({
      url: '/invoice/process',
      method: HTTP.PUT,
      data,
    });

    return result;
  };

  const deleteEpd = async (epdId: string) => {
    const result = await fetch({
      url: `/epd/delete/${epdId}`,
      method: HTTP.DELETE,
    });

    return result;
  };

  const uploadXlsx = async (
    data: FormData,
    setProgress: (value: number) => void
  ) => {
    const result = await fetch<TUploadInvoiceResponse, TValidationError>({
      url: '/import/upload',
      data,
      method: HTTP.POST,
      signal: abortController.signal,
      onUploadProgress: (progress) => {
        if (progress.progress) {
          setProgress(progress.progress * 100);
        }
      },
    });

    return result;
  };

  const processXlsx = async (importId: string) => {
    const result = await fetch({
      url: `/import/${importId}/process`,
      method: HTTP.POST,
    });

    return result;
  };

  const deleteXlsx = async (importId: string) => {
    const result = await fetch({
      url: `/import/${importId}`,
      method: HTTP.DELETE,
    });

    return result;
  };

  const abortUploadXlsx = () => {
    abortController.abort();
  };

  const getXlsxStatus = async (importId: string) => {
    const result = await fetch<TGetXlsxStatusResponse, unknown>({
      url: `/import/${importId}`,
      method: HTTP.GET,
    });

    return result;
  };

  const getXlsxColumns = async (importId: string) => {
    const result = await fetch<TGetXlsxColumnsResponse, unknown>({
      url: `/import/${importId}/mappings`,
      method: HTTP.GET,
    });

    return result;
  };

  const getSystemColumns = async (importEntity: ImportEntity) => {
    const result = await fetch<TGetSystemColumnsResponse, unknown>({
      url: `/import/${importEntity}/systemColumns`,
      method: HTTP.GET,
    });

    return result;
  };

  const getDefaultColumns = async (importEntity: ImportEntity) => {
    const result = await fetch<TGetDefaultColumnsResponse, unknown>({
      url: `/import/${importEntity}/defaultMappings`,
      method: HTTP.GET,
    });

    return result;
  };

  const processXlsxMappings = async (
    importId: string,
    data: { key: string; value: string }[]
  ) => {
    const result = await fetch({
      url: `/import/${importId}/validate`,
      method: HTTP.POST,
      data,
    });

    return result;
  };

  const downloadErrors = async (importId: string, mode: ImportEntity) => {
    fetch<Blob, unknown>({
      url: `/import/${importId}/issues`,
      responseType: 'blob',
      method: HTTP.GET,
    })
      .then((response) => {
        const date = dayjs();
        downloadFile(
          response,
          `Import_Issues_${mode === ImportEntity.Product ? 'Product_Catalog' : 'Assigned_Products'}_${date.format(DEFAULT_DATE_FORMAT)}_${date.format(DEFAULT_TIME_FORMAT)}`
        );
      })
      .catch(() => {
        console.log(EXPORT_FAILED);
      });
  };

  const importXlsx = async (importId: string) => {
    const result = await fetch({
      url: `/import/${importId}/start`,
      method: HTTP.POST,
    });

    return result;
  };

  return {
    download,
    uploadInvoice,
    abortUploadInvoice,
    deleteInvoice,
    processInvoice,
    deleteEpd,
    uploadXlsx,
    deleteXlsx,
    abortUploadXlsx,
    getXlsxStatus,
    processXlsx,
    getXlsxColumns,
    getSystemColumns,
    getDefaultColumns,
    processXlsxMappings,
    downloadErrors,
    importXlsx,
  };
};

export default useFiles;
