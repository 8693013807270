
export enum CompanyFormFields {
  NAME = 'name',
  GLN = 'gln',
  INDUSTRY = 'industryId',
  COUNTRY = 'country',
  REGION = 'region',
  POSTAL_CODE = 'postalCode',
  CITY = 'city',
  FIRST_ADDRESS_LINE = 'addressLine1',
  SECOND_ADDRESS_LINE = 'addressLine2',
  PHONE_NUMBER = 'phoneNumber',
  COUNTRY_CODE = 'countryCode',
  PRIMARY_CONTACT = 'primaryContactId',
  PRIMARY_CONTACT_FIRST_NAME = 'primaryContactFirstName',
  PRIMARY_CONTACT_LAST_NAME = 'primaryContactLastName',
  PRIMARY_CONTACT_JOB_TITLE = 'primaryContactJobTitle',
  PRIMARY_CONTACT_EMAIL = 'primaryEmail',
  SECONDARY_CONTACT = 'secondaryContactId',
  SECONDARY_CONTACT_FIRST_NAME = 'secondaryContactFirstName',
  SECONDARY_CONTACT_LAST_NAME = 'secondaryContactLastName',
  SECONDARY_CONTACT_JOB_TITLE = 'secondaryContactJobTitle',
  SECONDARY_CONTACT_EMAIL = 'secondaryEmail',
}

export const companyFormLabels: Partial<Record<CompanyFormFields, string>> = {
  [CompanyFormFields.NAME]: 'Company name',
  [CompanyFormFields.INDUSTRY]: 'Industry',
  [CompanyFormFields.COUNTRY]: 'Country',
  [CompanyFormFields.REGION]: 'State / Province / Region',
  [CompanyFormFields.POSTAL_CODE]: 'ZIP / Postal code',
  [CompanyFormFields.CITY]: 'City / Town',
  [CompanyFormFields.FIRST_ADDRESS_LINE]: 'Address line 1',
  [CompanyFormFields.SECOND_ADDRESS_LINE]: 'Address line 2',
  [CompanyFormFields.PHONE_NUMBER]: 'Phone',
  [CompanyFormFields.PRIMARY_CONTACT_FIRST_NAME]: 'First name',
  [CompanyFormFields.PRIMARY_CONTACT_LAST_NAME]: 'Last name',
  [CompanyFormFields.PRIMARY_CONTACT_JOB_TITLE]: 'Job title',
  [CompanyFormFields.PRIMARY_CONTACT_EMAIL]: 'Email',
  [CompanyFormFields.SECONDARY_CONTACT_FIRST_NAME]: 'First name',
  [CompanyFormFields.SECONDARY_CONTACT_LAST_NAME]: 'Last name',
  [CompanyFormFields.SECONDARY_CONTACT_JOB_TITLE]: 'Job title',
  [CompanyFormFields.SECONDARY_CONTACT_EMAIL]: 'Email',
};
