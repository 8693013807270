export enum ProfileFormFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  JOB_TITLE = 'jobTitle',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  COUNTRY_CODE = 'countryCode',
}

export const profileFormLabels: Partial<Record<ProfileFormFields, string>> = {
  [ProfileFormFields.FIRST_NAME]: 'First name',
  [ProfileFormFields.LAST_NAME]: 'Last name',
  [ProfileFormFields.JOB_TITLE]: 'Job title',
  [ProfileFormFields.EMAIL]: 'Email',
  [ProfileFormFields.PHONE_NUMBER]: 'Phone',
};
