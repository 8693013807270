import { Button, Tag, Tooltip } from 'antd';
import { PresetColorType } from 'antd/es/theme/interface';
import clsx from 'clsx';
import { IconBreakingNews } from 'src/components/icons/breaking-news/IconBreakingNews';
import { IconDelete } from 'src/components/icons/delete/IconDelete';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import Cell from 'src/components/table/cell/Cell';
import Email from 'src/components/table/email/Email';
import File from 'src/components/table/file/File';
import Highlighter from 'src/components/table/highlighter/Highlighter';
import {
  ModalContextActions,
  TModalContextStateAction,
} from 'src/contexts/modal-context';
import { ActionType } from 'src/types/actions';
import styles from 'src/styles/actions.module.scss';
import { IMappedInvoice } from 'src/types/mappings';
import { formatDate } from 'src/utils/date-formatter';
import { TMappedInvoicesListRecord } from 'src/utils/map-invoices-table';

export enum FileStatus {
  NONE = 0,
  FAILED = 100,
  PROCESSED = 200,
  PARTIALLY_PROCESSED = 300,
  PROCESSING = 400,
  UPLOADED = 500,
}

export const fileStatus = {
  [FileStatus.FAILED]: 'Failed',
  [FileStatus.PROCESSING]: 'Processing',
  [FileStatus.PARTIALLY_PROCESSED]: 'Partially processed',
  [FileStatus.PROCESSED]: 'Processed',
  [FileStatus.UPLOADED]: 'Uploaded',
  [FileStatus.NONE]: '',
};

export const fileStatusColor: Partial<
  Record<FileStatus, keyof PresetColorType | undefined>
> = {
  [FileStatus.FAILED]: 'red',
  [FileStatus.PROCESSING]: 'geekblue',
  [FileStatus.PARTIALLY_PROCESSED]: 'orange',
  [FileStatus.UPLOADED]: 'green',
};

export enum InvoicesColumns {
  ORDER = 'purchaseOrderNumber',
  INVOICE = 'fileName',
  STATUS = 'hiveStatus',
  CUSTOMER = 'customer.name',
  SUPPLIER = 'supplier.name',
  CREATED_AT = 'createdAt',
  CREATED_BY = 'createdByFullName',
  CREATED_BY_EMAIL = 'createdByEmail',
  ACTIONS = 'actions',
}

export const defaultInvoicesColumns = [
  InvoicesColumns.ORDER,
  InvoicesColumns.INVOICE,
  InvoicesColumns.STATUS,
  InvoicesColumns.CUSTOMER,
  InvoicesColumns.SUPPLIER,
  InvoicesColumns.CREATED_AT,
  InvoicesColumns.CREATED_BY,
  InvoicesColumns.CREATED_BY_EMAIL,
  InvoicesColumns.ACTIONS,
];

export const invoicesColumnLabel = {
  [InvoicesColumns.ORDER]: 'Order #',
  [InvoicesColumns.INVOICE]: 'Invoice name',
  [InvoicesColumns.STATUS]: 'Hive Status',
  [InvoicesColumns.CUSTOMER]: 'Customer',
  [InvoicesColumns.SUPPLIER]: 'Supplier',
  [InvoicesColumns.CREATED_AT]: 'Uploaded on',
  [InvoicesColumns.CREATED_BY]: 'Uploaded by',
  [InvoicesColumns.CREATED_BY_EMAIL]: 'Email',
  [InvoicesColumns.ACTIONS]: '',
};

export const invoicesColumnWidth = {
  [InvoicesColumns.ORDER]: 120,
  [InvoicesColumns.INVOICE]: 200,
  [InvoicesColumns.STATUS]: 142,
  [InvoicesColumns.CUSTOMER]: 200,
  [InvoicesColumns.SUPPLIER]: 200,
  [InvoicesColumns.CREATED_AT]: 126,
  [InvoicesColumns.CREATED_BY]: 155,
  [InvoicesColumns.CREATED_BY_EMAIL]: 155,
  [InvoicesColumns.ACTIONS]: 60,
};

export const invoicesColumnFixed: Partial<
  Record<InvoicesColumns, 'left' | 'right'>
> = {
  [InvoicesColumns.ORDER]: 'left',
  [InvoicesColumns.INVOICE]: 'left',
  [InvoicesColumns.ACTIONS]: 'right',
};

export const invoicesColumnSortOrder: Partial<
  Record<InvoicesColumns, SortOrder>
> = {
  [InvoicesColumns.CREATED_AT]: SortOrder.DESC,
};

type TGetInvoicesColumnRenderParams = {
  search: string;
  modalDispatch: React.Dispatch<TModalContextStateAction>;
  open: (id: string, name: string, failed: boolean) => void;
};

export const getInvoicesColumnRender = ({
  search,
  modalDispatch,
  open,
}: TGetInvoicesColumnRenderParams) => ({
  [InvoicesColumns.ORDER]: (value: IMappedInvoice[InvoicesColumns.ORDER]) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
  [InvoicesColumns.INVOICE]: (
    value: IMappedInvoice[InvoicesColumns.INVOICE]
  ) => {
    return (
      <Cell isValue={!!value?.fileName}>
        <File search={search} file={value} invoice />
      </Cell>
    );
  },
  [InvoicesColumns.STATUS]: (value: IMappedInvoice[InvoicesColumns.STATUS]) => {
    return (
      <Cell isValue={!!fileStatus[value]}>
        <Tag color={fileStatusColor[value]}>{fileStatus[value]}</Tag>
      </Cell>
    );
  },
  [InvoicesColumns.CUSTOMER]: (
    value: IMappedInvoice[InvoicesColumns.CUSTOMER]
  ) => {
    return (
      <Cell isValue={!!value}>
        <Highlighter value={value} search={search} />
      </Cell>
    );
  },
  [InvoicesColumns.SUPPLIER]: (
    value: IMappedInvoice[InvoicesColumns.SUPPLIER]
  ) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
  [InvoicesColumns.CREATED_AT]: (
    value: IMappedInvoice[InvoicesColumns.CREATED_AT]
  ) => {
    return <Cell isValue={!!value}>{formatDate({ date: value })}</Cell>;
  },
  [InvoicesColumns.CREATED_BY]: (
    value: IMappedInvoice[InvoicesColumns.CREATED_BY]
  ) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
  [InvoicesColumns.CREATED_BY_EMAIL]: (
    value: IMappedInvoice[InvoicesColumns.CREATED_BY_EMAIL]
  ) => {
    return (
      <Cell isValue={!!value}>
        <Email>{value || ''}</Email>
      </Cell>
    );
  },
  [InvoicesColumns.ACTIONS]: (
    value: IMappedInvoice[InvoicesColumns.ACTIONS],
    record: TMappedInvoicesListRecord
  ) => {
    return (
      <div className={clsx(styles.component, styles.end)}>
        {value.canDelete && (
          <Tooltip title="Delete">
            <Button
              size="small"
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                modalDispatch({
                  type: ModalContextActions.SHOW_MODAL,
                  value: {
                    title: 'Delete invoice',
                    description: `Invoice ”${record.fileName?.fileName}” will be permanently deleted.
                This action cannot be undone.`,
                    actionText: 'Delete',
                    action: ActionType.DELETE_INVOICE,
                    dataId: record.key,
                    dataName: record.fileName?.fileName || '-',
                  },
                });
              }}
              icon={
                <IconDelete color={styles.iconFill} height="16" width="16" />
              }
            />
          </Tooltip>
        )}
        {value.canViewResult && (
          <Tooltip title="View parsing result">
            <Button
              size="small"
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                open(
                  record.key,
                  record.fileName.fileName || '',
                  record.hiveStatus !== FileStatus.FAILED &&
                    record.hiveStatus !== FileStatus.PROCESSED
                );
              }}
              icon={
                <IconBreakingNews
                  color={styles.iconFill}
                  height="16"
                  width="16"
                />
              }
            />
          </Tooltip>
        )}
      </div>
    );
  },
});
