import { Form } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  ACCESS_DENIED,
  COMPANY_NOT_FOUND,
  RESET_FAIL,
  WRONG_CREDENTIALS,
} from 'src/constants/validation';
import { useMessageContext } from 'src/contexts/message-context';
import {
  REFRESH_TOKEN,
  UserContextActions,
  useUserContext,
} from 'src/contexts/user-context';
import useIdentity from './use-identity';

const useLoginForm = () => {
  const { validateSignInCompany, loginUser, sendResetEmail } = useIdentity();
  const { showSuccessMessage } = useMessageContext();
  const [params] = useSearchParams();
  const intitalCompany = params.get('company');

  const [step, setStep] = useState(intitalCompany ? 2 : 1);
  const [loading, setLoading] = useState(false);
  const [postError, setPostError] = useState('');
  const [mounted, setMounted] = useState(false);
  const [mountedStep, setMountedStep] = useState(intitalCompany ? 2 : 1);

  const [form] = Form.useForm();

  const company: string = Form.useWatch('company', form);
  const email: string = Form.useWatch('email', form);
  const password: string = Form.useWatch('password', form);
  const resetEmail: string = Form.useWatch('resetEmail', form);

  const [savedCompany, setSavedCompany] = useState(intitalCompany || '');
  const [savedEmail, setSavedEmail] = useState('');

  const submitLabel = useMemo(() => {
    switch (mountedStep) {
      case 1:
        return 'Proceed';
      case 2:
        return 'Sign in';
      case 3:
        return 'Reset password';
      default:
        return '';
    }
  }, [mountedStep]);

  const description = useMemo(() => {
    switch (mountedStep) {
      case 1:
        return "Please enter your company's name to access HivePix platform.";
      case 3:
        return 'Enter the email associated with your account, and we will send you reset instructions.';
      default:
        return '';
    }
  }, [mountedStep]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (intitalCompany && step === 1) {
      form.setFieldValue('company', intitalCompany);
    }
  }, [intitalCompany, step]);

  useEffect(() => {
    if (!mounted) {
      setTimeout(() => {
        setMountedStep(step);
        setMounted(true);
      }, 225);
    }
  }, [mounted, step]);

  const { userContextDispatch } = useUserContext();

  const setStepWithTransition = (value: number) => {
    setStep(value);
    setMounted(false);
    setPostError('');
  };

  const submit = () => {
    form
      .validateFields()
      .then(async () => {
        setLoading(true);
        setPostError('');

        if (step === 1) {
          const result = await validateSignInCompany(company.trim());
          if (result.errors?.response?.status === 404) {
            setPostError(COMPANY_NOT_FOUND);
          } else if (result.result?.status === 200) {
            setSavedCompany(company);
            setStepWithTransition(2);
          } else if (result.errors?.response?.data) {
            setPostError(result.errors.response.data);
          }
        } else if (step === 2) {
          const result = await loginUser({
            company: savedCompany.trim(),
            email: email.trim().toLowerCase(),
            password: password.trim(),
          });

          if (result.errors?.response) {
            setPostError(
              result.errors.status === 403 ? ACCESS_DENIED : WRONG_CREDENTIALS
            );
          } else if (result.result?.status === 200) {
            userContextDispatch({
              type: UserContextActions.SET_ACCESS_TOKEN,
              value: result.result.data.accessToken,
            });
            localStorage.setItem(
              REFRESH_TOKEN,
              result.result.data.refreshToken
            );
          }
        } else {
          const result = await sendResetEmail(resetEmail.trim().toLowerCase());

          if (result.errors) {
            setPostError(result.errors.response?.data || RESET_FAIL);
          }

          if (result.result) {
            setSavedEmail(resetEmail);
            setStepWithTransition(4);
          }
        }
        setLoading(false);
      })
      .catch((errr) => {
        console.error(errr);
      });
  };

  const goBack = () => {
    setStepWithTransition(step - 1 || 1);
  };

  const resendEmail = () => {
    sendResetEmail(savedEmail).then((res) => {
      if (res.result) {
        showSuccessMessage('Email resent');
      }
    });
  };

  return {
    step: mountedStep,
    goBack,
    loading,
    postError,
    form,
    company,
    email,
    password,
    submit,
    mounted,
    resetEmail: resetEmail || savedEmail,
    setStep: setStepWithTransition,
    submitLabel,
    description,
    resendEmail,
  };
};

export default useLoginForm;
