import { Empty } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { IconSort, SortOrder } from 'src/components/icons/sort/IconSort';
import Select from 'src/components/select/Select';
import useFiles from 'src/hooks/use-files';
import useTableWidth from 'src/hooks/use-table-width';
import { ImportEntity } from 'src/types/import';
import styles from '../ImportModalSecondStep.module.scss';

type TUseSecondStepParams = {
  importId: string | null;
  setLoading: (value: boolean) => void;
  setSelectedValues: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  selectedValues: Record<string, string>;
  mode: ImportEntity;
};

type TParsedColumn = {
  key: string;
  value: string;
  isSystemField: boolean;
};

const useSecondStep = ({
  importId,
  setLoading,
  selectedValues,
  setSelectedValues,
  mode,
}: TUseSecondStepParams) => {
  const { getDefaultColumns, getSystemColumns, getXlsxColumns } = useFiles();

  const [parsedColumns, setParsedColumns] = useState<TParsedColumn[]>([]);
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<DefaultOptionType[]>(
    []
  );

  useEffect(() => {
    if (!importId) {
      return;
    }

    const fetchColumns = async () => {
      setLoading(true);

      const parsedColumnsResponse = await getXlsxColumns(importId);
      const systemColumnsResponse = await getSystemColumns(mode);
      const defaultColumnsResponse = await getDefaultColumns(mode);

      if (parsedColumnsResponse.result?.data) {
        const systemColumns = systemColumnsResponse.result?.data;

        setParsedColumns(
          parsedColumnsResponse.result.data.map((el) => ({
            ...el,
            isSystemField: !!systemColumns?.includes(el.key),
          }))
        );
      }

      if (defaultColumnsResponse.result?.data) {
        setOptions(
          defaultColumnsResponse.result.data.map((el) => ({
            label: el.key,
            value: el.value,
          }))
        );
      }

      setLoading(false);
    };

    fetchColumns();
  }, []);

  useEffect(() => {
    setSelectedValues(
      parsedColumns.reduce((acc, el) => {
        if (el.isSystemField) {
          return acc;
        }
        return { ...acc, [el.key]: el.value };
      }, {})
    );
  }, [parsedColumns]);

  useEffect(() => {
    const values = Object.values(selectedValues);

    setFilteredOptions(
      options.filter((el) => !values.includes(el.value as string))
    );
  }, [options, selectedValues]);

  const handleSelectChange = (key: string, value: string) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const columns: ColumnsType = [
    {
      title: 'File column',
      dataIndex: 'key',
      key: 'key',
      width: 232,
      sorter: (a, b) => a.key.localeCompare(b.key),
      sortIcon: (props) => (
        <IconSort width="16" height="16" order={props.sortOrder as SortOrder} />
      ),
    },
    {
      title: 'HivePix attribute',
      dataIndex: 'value',
      key: 'value',
      width: 232,
      sortIcon: (props) => (
        <IconSort width="16" height="16" order={props.sortOrder as SortOrder} />
      ),
      sorter: (a, b) => {
        const valueA = a.isSystemField
          ? 'System field'
          : selectedValues[a.key] || '';
        const valueB = b.isSystemField
          ? 'System field'
          : selectedValues[b.key] || '';
        return valueA.localeCompare(valueB);
      },
      render: (_, record) => {
        if (record.isSystemField) {
          return <span>System field</span>;
        } else {
          return (
            <Select
              className={styles.select}
              size="small"
              placeholder="Select"
              allowClear
              defaultValue={record.value}
              onChange={(value) => handleSelectChange(record.key, value)}
              options={filteredOptions}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No available attributes"
                />
              }
            />
          );
        }
      },
    },
  ];

  const tableWidth = useTableWidth(columns);

  return { tableWidth, columns, parsedColumns };
};

export default useSecondStep;
