import { DefaultOptionType } from 'antd/es/select';
import { City, Country, State } from 'country-state-city';
import { useCallback, useMemo, useState } from 'react';
import Highlighter from 'src/components/table/highlighter/Highlighter';

const useAddressSelects = (
  country: string | null,
  state: string | null,
  city: string | null
) => {
  const [stateSearch, setStateSearch] = useState('');

  const countryCode = useMemo(
    () => Country.getAllCountries().find((el) => el.name === country)?.isoCode,
    [country]
  );

  const stateCode = useMemo(
    () =>
      State.getStatesOfCountry(countryCode).find((el) => el.name === state)
        ?.isoCode,
    [state]
  );

  const states = useMemo(() => {
    if (!countryCode) {
      return [];
    }

    return State.getStatesOfCountry(countryCode).map((el) => {
      return {
        key: `${el.longitude}${el.latitude}${el.isoCode}`,
        value: el.name,
        label: <Highlighter value={el.name} search={stateSearch} />,
      } as DefaultOptionType;
    });
  }, [stateSearch, countryCode]);

  const cities = useMemo(() => {
    if (!countryCode) {
      return [];
    }
    const cities = stateCode
      ? City.getCitiesOfState(countryCode, stateCode)
      : City.getCitiesOfCountry(countryCode) || [];

    return (
      cities
        .map((el) => {
          return {
            key: `${el.longitude} ${el.latitude}`,
            value: el.name,
            label: <Highlighter value={el.name} search={city || ''} />,
          } as DefaultOptionType;
        })
        .filter(
          (el) =>
            !city ||
            (el.value as string)
              .toLowerCase()
              .includes(city.trim().toLowerCase())
        ) || []
    );
  }, [city, countryCode, stateCode]);

  const getStateByCity = useCallback(
    (city: string) => {
      if (!countryCode) {
        return '';
      }
      const stateCode = City.getCitiesOfCountry(countryCode)?.find(
        (el) => el.name === city
      )?.stateCode;

      if (!stateCode) {
        return '';
      }

      return State.getStateByCodeAndCountry(stateCode, countryCode)?.name;
    },
    [countryCode]
  );

  return {
    cities,
    states,
    setStateSearch,
    getStateByCity,
    countryCode,
    stateSearch,
  };
};

export default useAddressSelects;
