import { PropsWithChildren, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  LOGIN_PATHS,
  PROTECTED_PATHS,
  ProtectedRoutes,
  PublicRoutes,
} from 'src/constants/routes';
import { REFRESH_TOKEN, useUserContext } from 'src/contexts/user-context';

const AuthWrapper = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    userContextState: { accessToken },
  } = useUserContext();

  useEffect(() => {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);

    const parsedLocation = location.pathname.slice(1).split('/');

    const rootLocation = parsedLocation.length
      ? `/${parsedLocation[0]}`
      : ProtectedRoutes.DASHBOARD;

    const validateRoutes = async () => {
      if (
        (!!accessToken || !!refreshToken) &&
        LOGIN_PATHS.includes(rootLocation)
      ) {
        console.log(
          `%c⏩\tUser is logged in. Redirecting to the dashboard.`,
          'color: #876800;'
        );
        navigate(ProtectedRoutes.DASHBOARD);
        return;
      }

      if (
        !accessToken &&
        !refreshToken &&
        PROTECTED_PATHS.includes(rootLocation)
      ) {
        console.log(
          `%c⏩\tUser is logged out. Redirecting to the sign in.`,
          'color: #876800;'
        );

        navigate(PublicRoutes.SIGNIN);
        return;
      }
    };

    validateRoutes();
  }, [accessToken, location.pathname]);

  return <>{children}</>;
};

export default AuthWrapper;
