import { HTTP, useNetworkContext } from 'src/contexts/network-context';
import {
  TGetCompanyInfoResponse,
  TGetUserInfoResponse,
  TUpdateCompanyInfoParams,
  TUpdateUserInfoParams,
} from 'src/types/backend';

const useProfile = () => {
  const { fetch } = useNetworkContext();

  const getCompanyInfo = async () => {
    const result = await fetch<TGetCompanyInfoResponse, unknown>({
      url: '/company/profile',
      method: HTTP.GET,
    });

    return result;
  };

  const updateCompanyInfo = async (data: TUpdateCompanyInfoParams) => {
    const result = await fetch({
      url: '/company/profile',
      method: HTTP.PUT,
      data,
    });

    return result;
  };

  const validateCompanyName = async (company: string) => {
    const result = await fetch<string, string>({
      url: `/company/profile/${company}/checkCompanyName`,
      method: HTTP.GET,
    });

    return result;
  };

  const getUserInfo = async () => {
    const result = await fetch<TGetUserInfoResponse, unknown>({
      url: '/user/profile',
      method: HTTP.GET,
    });

    return result;
  };

  const updateUserInfo = async (data: TUpdateUserInfoParams) => {
    const result = await fetch({
      url: '/user/profile',
      method: HTTP.PUT,
      data,
    });

    return result;
  };

  return {
    getCompanyInfo,
    updateCompanyInfo,
    getUserInfo,
    updateUserInfo,
    validateCompanyName,
  };
};

export default useProfile;
