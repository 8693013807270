import { StyleProvider } from '@ant-design/cssinjs';
import { ConfigProvider, theme } from 'antd';
import { PropsWithChildren } from 'react';
import themeStyles from 'src/styles/theme.module.scss';

export const AntdThemeProvider = ({ children }: PropsWithChildren) => {
  const {
    token: { margin, borderRadiusLG },
  } = theme.useToken();
  return (
    <StyleProvider layer>
      <ConfigProvider
        theme={{
          components: {
            Avatar: {
              colorTextPlaceholder: themeStyles.colorTextPlaceholder,
            },
            Pagination: {
              itemActiveBg: themeStyles.colorPrimary,
              colorText: themeStyles.paginationColorText,
            },
            Form: {
              itemMarginBottom: margin,
            },
            Button: {
              primaryColor: themeStyles.colorTextBase,
              colorTextLightSolid: themeStyles.colorTextBase,
              fontWeight: 600,
              colorLink: themeStyles.buttonColorLink,
              colorLinkHover: themeStyles.buttonColorLinkHover,
              colorLinkActive: themeStyles.buttonColorLinkActive,
            },
            Divider: {
              verticalMarginInline: 0,
            },
            Menu: {
              colorBgContainer: themeStyles.menuColorBgContainer,
              itemSelectedBg: themeStyles.menuColorItemBgSelected,
              colorPrimary: themeStyles.menuColorPrimary,
            },
            Layout: {
              siderBg: themeStyles.colorPrimary,
            },
            Input: {
              borderRadius: borderRadiusLG,
              borderRadiusSM: borderRadiusLG,
            },
            InputNumber: {
              borderRadius: borderRadiusLG,
              borderRadiusSM: borderRadiusLG,
            },
            Select: {
              borderRadius: borderRadiusLG,
              borderRadiusSM: borderRadiusLG,
            },
            DatePicker: {
              borderRadius: borderRadiusLG,
              borderRadiusSM: borderRadiusLG,
            },
            Tabs: {
              itemActiveColor: themeStyles.tabSelectedColor,
              itemSelectedColor: themeStyles.tabSelectedColor,
            },
            Empty: {
              colorTextDescription: themeStyles.colorTextDescription,
            },
            Radio: {
              buttonSolidCheckedColor: themeStyles.radioColorActive,
            },
            Progress: {
              defaultColor: themeStyles.colorPrimary,
              colorSuccess: themeStyles.colorPrimary,
            },
            Steps: {
              colorTextLightSolid: themeStyles.colorTextBase,
            },
          },
          token: {
            colorTextBase: themeStyles.colorTextBase,
            colorPrimary: themeStyles.colorPrimary,
            colorPrimaryBg: themeStyles.colorPrimaryBg,
            colorPrimaryBgHover: themeStyles.colorPrimaryBgHover,
            colorPrimaryBorder: themeStyles.colorPrimaryBorder,
            colorPrimaryBorderHover: themeStyles.colorPrimaryBorderHover,
            colorPrimaryHover: themeStyles.colorPrimaryHover,
            colorPrimaryActive: themeStyles.colorPrimaryActive,
            colorPrimaryTextHover: themeStyles.colorPrimaryTextHover,
            colorPrimaryText: themeStyles.colorPrimaryText,
            colorPrimaryTextActive: themeStyles.colorPrimaryTextActive,
            colorLink: themeStyles.colorLink,
            colorLinkHover: themeStyles.colorLinkHover,
            colorLinkActive: themeStyles.colorLinkActive,
            colorBgMask: themeStyles.colorBgMask,
          },
        }}
      >
        {children}
      </ConfigProvider>
    </StyleProvider>
  );
};
