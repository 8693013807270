import {
  Dispatch,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useReducer,
  type FunctionComponent,
} from 'react';
import { IUserInfo } from 'src/types/general';

type TAccessToken = string | null;

export const REFRESH_TOKEN = 'refreshToken';

type TPermissons = {
  isAdmin: boolean;
  isAdvancedUser: boolean;
};

type TUserContextState = {
  user: IUserInfo | null;
  loading: boolean;
  accessToken: TAccessToken;
} & TPermissons;

export enum UserContextActions {
  SET_PERMISSIONS = 'SET_PERMISSIONS',
  SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
  LOGOUT = 'LOGOUT',
  FETCH_USER = 'FETCH_USER',
  SET_USER = 'SET_USER',
}

export type TUserContextStateAction =
  | {
      type: UserContextActions.LOGOUT;
    }
  | {
      type: UserContextActions.SET_ACCESS_TOKEN;
      value: TAccessToken;
    }
  | {
      type: UserContextActions.SET_PERMISSIONS;
      value: TPermissons;
    }
  | {
      type: UserContextActions.FETCH_USER;
    }
  | {
      type: UserContextActions.SET_USER;
      value?: IUserInfo;
    };

export type TUserContextValue = {
  userContextState: TUserContextState;
  userContextDispatch: Dispatch<TUserContextStateAction>;
};

const userContexInitialState: TUserContextState = {
  user: null,
  loading: false,
  accessToken: null,
  isAdmin: false,
  isAdvancedUser: false,
};

const userContextReducer = (
  state: TUserContextState,
  action: TUserContextStateAction
): TUserContextState => {
  const { type } = action;

  switch (type) {
    case UserContextActions.LOGOUT: {
      localStorage.removeItem(REFRESH_TOKEN);

      return userContexInitialState;
    }
    case UserContextActions.SET_ACCESS_TOKEN: {
      if (typeof action.value === 'undefined') {
        return state;
      }

      return {
        ...state,
        accessToken: action.value,
      };
    }
    case UserContextActions.SET_PERMISSIONS: {
      if (typeof action.value === 'undefined') {
        return state;
      }

      return {
        ...state,
        ...action.value,
      };
    }
    case UserContextActions.FETCH_USER: {
      if (state.loading === true) {
        return state;
      }

      return {
        ...state,
        loading: true,
      };
    }
    case UserContextActions.SET_USER: {
      if (typeof action.value === 'undefined') {
        return state;
      }

      return {
        ...state,
        user: action.value,
        loading: false,
      };
    }

    default:
      return state;
  }
};

const UserContext = createContext<TUserContextValue>({
  userContextState: userContexInitialState,
  userContextDispatch: () => null,
});

type UserContextProviderProps = PropsWithChildren<{
  initialState?: TUserContextState;
}>;

export const UserContextProvider: FunctionComponent<
  UserContextProviderProps
> = ({ initialState = userContexInitialState, children }): JSX.Element => {
  const [userContextState, userContextDispatch] = useReducer(
    userContextReducer,
    { ...initialState }
  );

  const contextValue = useMemo(
    () => ({ userContextState, userContextDispatch }),
    [userContextState, userContextDispatch]
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error(
      '"UserContext" context is not in scope. Use this hook in a component that is wrapped with the <UserContextProvider /> component.'
    );
  }

  return context;
};
