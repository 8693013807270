import { Button } from 'antd';
import clsx from 'clsx';
import { IconChevronLeft } from 'src/components/icons/chevron-left/IconChevronLeft';
import Steps from '../../steps/Steps';
import styles from './SignInModalHeader.module.scss';

export type TSignInModalHeaderProps = {
  step: number;
  goBack: () => void;
};

const SignInModalHeader: React.FC<TSignInModalHeaderProps> = ({
  step,
  goBack,
}) => {
  if (step === 4) {
    return null;
  }
  const secondStep = step === 2;
  const thirdStep = step === 3;

  const counterVisible = !thirdStep;
  const buttonVisible = secondStep || thirdStep;

  return (
    <div
      className={clsx(styles.component, {
        [styles.singleItem]: step === 1,
      })}
    >
      {buttonVisible && (
        <Button
          size="small"
          type="text"
          onClick={goBack}
          icon={<IconChevronLeft />}
        >
          Back {thirdStep ? 'to sign in' : ''}
        </Button>
      )}
      {counterVisible && <Steps step={step} steps={2} />}
    </div>
  );
};

export default SignInModalHeader;
