import { useEffect } from 'react';
import { UserContextActions, useUserContext } from 'src/contexts/user-context';
import { UserRoles } from 'src/types/general';
import useIdentity from './use-identity';

const usePermissions = () => {
  const { userContextDispatch } = useUserContext();

  const { getPermissions } = useIdentity();

  useEffect(() => {
    const fetchPermissions = async () => {
      const result = await getPermissions();
      if (result.result?.data) {
        const permissionsList = result.result?.data;
        userContextDispatch({
          type: UserContextActions.SET_PERMISSIONS,
          value: {
            isAdmin: permissionsList
              .map((el) => el.name)
              .includes(UserRoles.ADMIN),
            isAdvancedUser: permissionsList
              .map((el) => el.name)
              .includes(UserRoles.ADVANCED_USER),
          },
        });
      }
    };
    fetchPermissions();
  }, []);
};

export default usePermissions;
