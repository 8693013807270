import { Avatar, Button, Popover, Skeleton } from 'antd';
import clsx from 'clsx';
import { useState } from 'react';
import { getUserIcon } from 'src/constants/user';
import styles from './User.module.scss';
import UserOverlay from './overlay/UserOverlay';
import { useUserContext } from 'src/contexts/user-context';
import { UserRoles } from 'src/types/general';

type TUserProps = {
  hidden: boolean;
  active: boolean;
};

const User = ({ hidden, active }: TUserProps) => {
  const [open, setOpen] = useState(false);

  const {
    userContextState: { user, loading },
  } = useUserContext();

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const name = `${user?.firstName} ${user?.lastName}`;
  const email = user?.email || '–';
  const role = user?.role || UserRoles.BASIC_USER;
  const jobTitle = user?.jobTitle;
  const compnay = user?.companyName || '–';

  const isSkeleton = !user && loading;

  return (
    <div className={styles.container}>
      <Popover
        align={{
          offset: [16, -8],
        }}
        content={
          <UserOverlay
            email={email}
            name={name}
            jobTitle={jobTitle}
            company={compnay}
            role={role}
            close={() => {
              setOpen(false);
            }}
          />
        }
        overlayInnerStyle={{ padding: 4 }}
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
        placement={'right'}
        arrow={false}
      >
        <Button
          className={clsx(styles.button, {
            [styles.active]: active,
          })}
          icon={
            isSkeleton ? (
              <Skeleton
                active
                round
                title={false}
                paragraph={false}
                avatar={{ shape: 'square', size: 'default' }}
              />
            ) : (
              <Avatar shape="square" icon={getUserIcon(role)} />
            )
          }
          type="text"
        >
          <div
            className={clsx(styles.content, {
              [styles.hidden]: hidden,
            })}
          >
            {isSkeleton ? (
              <Skeleton
                className={styles.skeleton}
                active
                round
                paragraph={false}
              />
            ) : (
              <span className={styles.fullName}>{name}</span>
            )}
            {isSkeleton ? (
              <Skeleton
                className={styles.skeleton}
                active
                round
                paragraph={false}
              />
            ) : (
              <span className={styles.email}>{email}</span>
            )}
          </div>
        </Button>
      </Popover>
    </div>
  );
};

export default User;
