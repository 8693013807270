import { Button, Typography, theme } from 'antd';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Logo } from 'src/components/logo/Logo';
import styles from './ResetPasswordValidationModal.module.scss';
import Url from 'src/components/table/url/Url';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from 'src/constants/routes';

type TResetPasswordValidationModalProps = {
  className?: string;
  company: string | null;
};

const ResetPasswordValidationModal = ({
  className,
  company,
}: TResetPasswordValidationModalProps) => {
  const {
    token: { colorBgContainer, boxShadowTertiary, borderRadiusLG, paddingXL },
  } = theme.useToken();

  const navigate = useNavigate();

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div
      className={clsx(
        styles.container,
        {
          [styles.transitionIn]: mounted,
        },
        className
      )}
      style={{
        backgroundColor: colorBgContainer,
        boxShadow: boxShadowTertiary,
        borderRadius: borderRadiusLG,
        padding: paddingXL,
      }}
    >
      <Logo width="188" height="47" />
      <Typography.Title className={styles.title} level={5}>
        Reset link is no longer active
      </Typography.Title>
      <Typography.Text className={styles.description}>
        Please request a password reset again by clicking the 'Forgot password?'
        button on the sign in page.
      </Typography.Text>
      <Button
        size="large"
        type="primary"
        onClick={() => {
          navigate(
            `${PublicRoutes.SIGNIN}?company=${company ? `?company=${company}` : ''}`
          );
        }}
      >
        Go to Sign In
      </Button>
    </div>
  );
};

export default ResetPasswordValidationModal;
