import { FormInstance } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { ColumnsType } from 'antd/es/table';
import { IconSort, SortOrder } from 'src/components/icons/sort/IconSort';
import { BROADCAST_NAME } from 'src/constants/local-storage';
import {
  ProductOffersColumns,
  defaultProductDetailsColumns,
  getProductOffersColumnLabel,
  getProductOffersColumnRender,
  getProductOffersColumnWidth,
  productOffersColumnAlign,
  productOffersColumnFixed,
  productOffersColumnSortOrder,
} from 'src/constants/product-offers';
import {
  ModalContextActions,
  TModalContextStateAction,
} from 'src/contexts/modal-context';
import { ActionType } from 'src/types/actions';
import { TEditAssignedCustomer } from 'src/hooks/use-products';
import { IProductOffer } from 'src/types/general';
import { ArrayElement } from 'src/types/generic';
import { TMappedProductOfferRecord } from './map-assigned-product-table';

type TGetMappedProductDetailsDataParams = {
  modalDispatch: React.Dispatch<TModalContextStateAction>;
  setEditRow: (index: number, record: TMappedProductOfferRecord) => void;
  setLoading: (value: boolean) => void;
  unsetEditRow: (index: number) => void;
  edit: TEditAssignedCustomer;
  productId: string;
  actionsHidden: boolean;
  isEditing: boolean;
  currencies: DefaultOptionType[];
  uoms: DefaultOptionType[];
  form: FormInstance;
  showErrorMessage: (text: string) => void;
  showSuccessMessage: (text: string) => void;
};

export const getMappedProductDetailsData = (
  data: IProductOffer[]
): TMappedProductOfferRecord[] => {
  return data.map((row) => {
    const res: TMappedProductOfferRecord = {
      key: row.id,
      [ProductOffersColumns.GLN]: row.customer?.gln,
      [ProductOffersColumns.CUSTOMER]: row.customer?.name,
      [ProductOffersColumns.PRICE]: row.productOfferPrice?.price || null,
      [ProductOffersColumns.CURRENCY]: row.productOfferPrice?.currency || 0,
      [ProductOffersColumns.QUANTITY]: row.quantity,
      [ProductOffersColumns.UOM]: row.unitOfMeasurementId,
      [ProductOffersColumns.START_DATE]:
        row.productOfferPrice?.startDate || null,
      [ProductOffersColumns.EXPIRATION_DATE]:
        row.productOfferPrice?.expirationDate || null,
      [ProductOffersColumns.CREATED_AT]: row.createdAt,
      [ProductOffersColumns.INDUSTRY]: row.customer?.industry,
      [ProductOffersColumns.ACTIONS]: {
        canDelete: row.canDelete,
        canEdit: row.canEdit,
      },
      isEditing: false,
    };
    return res;
  });
};

export const getProductDetailsColumnSetting = function ({
  modalDispatch,
  setEditRow,
  unsetEditRow,
  edit,
  productId,
  actionsHidden,
  setLoading,
  currencies,
  uoms,
  isEditing,
  form,
  showSuccessMessage,
  showErrorMessage,
}: TGetMappedProductDetailsDataParams) {
  const render = getProductOffersColumnRender({
    search: '',
    currencies,
    uoms,
    disabled: isEditing,
    archiveTooltip: 'Decouple',
    save: async (e, key) => {
      e.stopPropagation();
      setLoading(true);

      const data = form.getFieldsValue();

      const res = await edit(productId, key, {
        price: data[ProductOffersColumns.PRICE] || null,
        currency: data[ProductOffersColumns.PRICE]
          ? data[ProductOffersColumns.CURRENCY]
          : null,
        unitOfMeasurementId: data[ProductOffersColumns.QUANTITY]
          ? data[ProductOffersColumns.UOM]
          : null,
        quantity: data[ProductOffersColumns.QUANTITY] || null,
        expirationDate: data[ProductOffersColumns.EXPIRATION_DATE],
        startDate: data[ProductOffersColumns.START_DATE],
      });
      if (!res.errors) {
        showSuccessMessage('Changes saved');
        const broadcast = new BroadcastChannel(BROADCAST_NAME);
        broadcast.postMessage('refresh');
        broadcast.close();
      } else {
        showErrorMessage('Changes not saved');
      }
    },
    cancel: (e, index) => {
      e.stopPropagation();
      unsetEditRow(index);
    },
    edit: (e, index, record) => {
      e.stopPropagation();
      setEditRow(index, record);
    },
    archive: (e, record) => {
      e.stopPropagation();
      modalDispatch({
        type: ModalContextActions.SHOW_MODAL,
        value: {
          title: 'Decouple customer from product',
          description: `Customer ${record['customer.name']} will be removed from the list.`,
          actionText: 'Decouple',
          action: ActionType.DECOUPLE_PRODUCTS_CUSTOMER,
          dataId: productId,
          subDataId: record.key,
          dataName: record['customer.name'] || '-',
        },
      });
    },
  });
  const result = defaultProductDetailsColumns.map(
    (property): ArrayElement<ColumnsType<TMappedProductOfferRecord>> => {
      switch (property) {
        case ProductOffersColumns.GLN:
        case ProductOffersColumns.CUSTOMER:
        case ProductOffersColumns.PRICE:
        case ProductOffersColumns.CURRENCY:
        case ProductOffersColumns.QUANTITY:
        case ProductOffersColumns.UOM:
        case ProductOffersColumns.START_DATE:
        case ProductOffersColumns.EXPIRATION_DATE:
        case ProductOffersColumns.CREATED_AT:
        case ProductOffersColumns.INDUSTRY:
          return {
            title: getProductOffersColumnLabel(false)[property],
            width: getProductOffersColumnWidth(false, false)[property],
            key: property,
            fixed: productOffersColumnFixed[property],
            ellipsis: true,
            align: productOffersColumnAlign[property],
            defaultSortOrder: productOffersColumnSortOrder[property],
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: render[property],
          };
        case ProductOffersColumns.ACTIONS:
          if (actionsHidden) {
            return { hidden: true };
          }
          return {
            width: getProductOffersColumnWidth(false, false)[property],
            key: property,
            fixed: productOffersColumnFixed[property],
            dataIndex: property,
            render: render[property],
          };

        default:
          return { hidden: true };
      }
    }
  );
  return result;
};
