import { IconAdvanceBee } from 'src/components/icons/advance-bee/IconAdvanceBee';
import { IconBasicBee } from 'src/components/icons/basic-bee/IconBasicBee';
import { IconBeekeeper } from 'src/components/icons/beekeeper/IconBeekeeper';
import { UserRoles } from 'src/types/general';

export const getUserIcon = (permission: UserRoles, size = '20') => {
  switch (permission) {
    case UserRoles.ADMIN:
      return <IconBeekeeper width={size} height={size} />;
    case UserRoles.ADVANCED_USER:
      return <IconAdvanceBee width={size} height={size} />;
    case UserRoles.BASIC_USER:
      return <IconBasicBee width={size} height={size} />;
  }
};
