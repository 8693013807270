import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconBeekeeper.module.scss';
import { TIconProps } from 'src/types/icons';

type IconBeekeeperProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconBeekeeper: FunctionComponent<IconBeekeeperProps> = ({
  className,
  width = 128,
  height = 128,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M32.7537 38.1228V72.2671H102.189V38.1228H32.7537ZM88.5919 59.1044H46.3521V39.4213H88.5919V59.1044Z"
        fill="#FAD20A"
      />
      <path
        d="M111.87 88.4965C109.307 82.8379 106.194 79.0439 101.462 75.8064C98.9843 74.1124 93.6179 71.6869 88.8124 69.663H101.321V30.3125H96.7626C91.4846 16.8979 78.5644 8 64 8C49.4356 8 36.5154 16.8979 31.2374 30.3125H26.6786V69.663H39.1911C34.3804 71.6895 29.007 74.1176 26.5378 75.8064C21.8066 79.0421 18.6934 82.8379 16.1296 88.4965C13.4189 94.4771 12.2 115.07 12.2 119.69H17.408C17.408 114.189 18.7695 95.2883 20.873 90.6464C23.0448 85.854 25.5376 82.8011 29.4778 80.1052C32.269 78.1969 40.7145 74.5752 47.8606 71.7534H80.1403C87.2776 74.5709 95.7188 78.1881 98.5223 80.1052C102.412 82.7653 104.985 85.9179 107.128 90.6464C109.231 95.2909 110.593 114.189 110.593 119.69H115.801C115.8 115.072 114.582 94.4806 111.87 88.4965ZM64 13.208C75.7119 13.208 86.1926 19.9481 91.1163 30.3125H36.8838C41.8074 19.9481 52.2881 13.208 64 13.208ZM44.6161 35.5196H83.3839V52.1595H44.6161V35.5196ZM31.8858 35.5196H41.1441V55.6315H86.855V35.5196H96.1134V64.455H31.8858V35.5196ZM32.1675 96.0635H37.3755V120H32.1675V96.0635ZM91.188 96.0635H96.396V120H91.188V96.0635ZM57.3456 41.7444H60.8176C60.8176 45.4937 57.7683 48.544 54.0189 48.544C50.2695 48.544 47.2201 45.4946 47.2201 41.7444H50.6921C50.6921 43.5784 52.1849 45.072 54.0189 45.072C55.8529 45.072 57.3456 43.5784 57.3456 41.7444ZM78.1759 41.7444H81.6479C81.6479 45.4937 78.5985 48.544 74.8491 48.544C71.1006 48.544 68.0504 45.4946 68.0504 41.7444H71.5224C71.5224 43.5784 73.0151 45.072 74.8491 45.072C76.6831 45.072 78.1759 43.5784 78.1759 41.7444Z"
        fill="#111111"
      />
    </svg>
  );
};
