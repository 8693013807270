import { Alert, Button, Progress, Steps, StepsProps, Typography } from 'antd';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useBeforeUnload } from 'react-router-dom';
import { IconError } from 'src/components/icons/error/IconError';
import Loader from 'src/components/loader/Loader';
import { BROADCAST_NAME } from 'src/constants/local-storage';
import { IMPORT_FIELDS_REQUIRED } from 'src/constants/validation';
import { useMessageContext } from 'src/contexts/message-context';
import useFiles from 'src/hooks/use-files';
import { IImportStatus } from 'src/types/general';
import {
  ImportEntity,
  ImportRequiredFields,
  ImportStatus,
} from 'src/types/import';
import styles from './ImportModalContent.module.scss';
import ImportModalFirstStep from './first-step/ImportModalFirstStep';
import ImportModalSecondStep from './second-step/ImportModalSecondStep';
import ImportModalThirdStep from './third-step/ImportModalThirdStep';

type TImportModalContentProps = {
  onClose: () => void;
  mode: ImportEntity;
  customerId?: string;
};

const INITIAL_STATUS: IImportStatus = {
  id: '',
  status: ImportStatus.NOT_STARTED,
  validationResult: null,
  createdItemsCount: null,
  updatedItemsCount: null,
  numberOfIssueRow: null,
};

const ImportModalContent: React.FC<TImportModalContentProps> = ({
  onClose,
  mode,
  customerId,
}) => {
  const {
    getXlsxStatus,
    processXlsx,
    processXlsxMappings,
    importXlsx,
    deleteXlsx,
  } = useFiles();

  const { showSuccessMessage, showErrorMessage } = useMessageContext();

  const [mountedStep, setMountedStep] = useState(0);
  const [visibleStep, setVisibleStep] = useState(0);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState<{ text?: string; loading: boolean }>({
    loading: false,
  });
  const [progress, setProgress] = useState<number>(0);
  const [stepStatus, setStepStatus] = useState<StepsProps['status']>('process');
  const [poll, setPoll] = useState(false);
  const [importId, setImportId] = useState<string | null>(null);
  const [importStatus, setImportStatus] =
    useState<IImportStatus>(INITIAL_STATUS);
  const [selectedValues, setSelectedValues] = useState<Record<string, string>>(
    {}
  );
  const [importing, setImporting] = useState(false);
  const finalStepLabel =
    importStatus.status === ImportStatus.FAILED_VALIDATION
      ? 'Re-upload File'
      : importStatus.status === ImportStatus.IMPORT_FAILED
        ? 'OK'
        : 'Import';
  const importFailed =
    importStatus.status === ImportStatus.IMPORT_FAILED && mountedStep === 2;

  useEffect(() => {
    setVisibleStep(mountedStep);
  }, [mountedStep]);

  useEffect(() => {
    if (!importId) {
      setImportStatus(INITIAL_STATUS);
    }
  }, [importId]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    async function fetchData() {
      if (!importId) {
        return;
      }
      const response = await getXlsxStatus(importId);
      const status = response.result?.data.status;

      switch (status) {
        case ImportStatus.HEADERS_PARSED:
        case ImportStatus.FAILED_VALIDATION:
        case ImportStatus.SUCCEED_VALIDATION:
          setPoll(false);
          break;
        case ImportStatus.IMPORT_FAILED:
          setPoll(false);
          break;
        case ImportStatus.IMPORT_SUCCEED:
          setPoll(false);
          onClose();
          showSuccessMessage('Import completed');
          const broadcast = new BroadcastChannel(BROADCAST_NAME);
          broadcast.postMessage('refresh');
          broadcast.close();
          break;
      }
      if (response.result?.data) {
        setImportStatus(response.result?.data);
      }
      timeout = setTimeout(fetchData, 5000);
    }

    if (poll && importId) {
      fetchData();
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        setLoading({ loading: false });
        setImporting(false);
      }
    };
  }, [poll]);

  const closeModalWithError = () => {
    showErrorMessage('Import failed.');
    onClose();
  };

  useEffect(() => {
    if (importStatus.status === ImportStatus.IMPORT_FAILED && mountedStep < 2) {
      closeModalWithError();
    }
  }, [mountedStep, importStatus.status]);

  const setStepWithReset = (
    step: number,
    status: StepsProps['status'] = 'process'
  ) => {
    setMountedStep(step);
    setStepStatus(status);
    setPoll(false);
  };

  useEffect(() => {
    switch (importStatus.status) {
      case ImportStatus.HEADERS_PARSED:
        return setStepWithReset(1);
      case ImportStatus.SUCCEED_VALIDATION:
        return setStepWithReset(2, 'finish');
      case ImportStatus.FAILED_VALIDATION:
        return setStepWithReset(2, 'error');
      default:
        return;
    }
  }, [importStatus.status]);

  useBeforeUnload((e) => {
    if (importId) {
      e.preventDefault();
      return true;
    }
  });

  useEffect(() => {
    // Note: reset step when changes
    if (mountedStep === 2) {
      setMountedStep(1);
      setImportStatus((prevStatus) => ({
        ...prevStatus,
        status: ImportStatus.HEADERS_PARSED,
      }));
    }

    if (
      error &&
      Object.values(selectedValues).filter(
        (el) =>
          el === ImportRequiredFields.PRODUCT_ID ||
          el === ImportRequiredFields.PRODUCT_NAME
      ).length === 2
    ) {
      setError(false);
    }
  }, [selectedValues, error]);

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (importing) {
      interval = setInterval(() => {
        setProgress((progress) => (progress === 110 ? -10 : progress + 1));
      }, 100);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
        setProgress(0);
      }
    };
  }, [importing]);

  const submit = async () => {
    if (!importId) {
      return;
    }

    if (mountedStep !== visibleStep) {
      setVisibleStep((step) => step + 1);
      return;
    }

    switch (mountedStep) {
      case 0:
        setLoading({ loading: true, text: 'Processing' });

        const processRes = await processXlsx(importId);

        if (processRes.result) {
          setPoll(true);
        }

        if (processRes.errors) {
          closeModalWithError();
        }
        break;
      case 1:
        const values = Object.values(selectedValues);
        const valid =
          values.filter((el) => {
            switch (el) {
              case ImportRequiredFields.PRODUCT_ID:
              case ImportRequiredFields.PRODUCT_NAME:
              case ImportRequiredFields.PRODUCT_OFFER_ID:
              case ImportRequiredFields.PRODUCT_OFFER_NAME:
                return true;
              default:
                return false;
            }
          }).length === 2;

        if (!valid) {
          setError(true);
          return;
        }

        setLoading({ loading: true, text: 'Processing' });
        setError(false);

        const mappingRes = await processXlsxMappings(
          importId,
          Object.entries(selectedValues)
            .map(([key, value]) => ({ key, value }))
            .filter((el) => !!el.value)
        );

        if (mappingRes.result) {
          setPoll(true);
        }

        if (mappingRes.errors) {
          closeModalWithError();
        }
        break;
      case 2:
        if (importStatus.status === ImportStatus.FAILED_VALIDATION) {
          // Note: hard delete file from first step
          setDisabled(true);

          await deleteXlsx(importId);

          setDisabled(false);
          setImportId('');
          setImportStatus((prevStatus) => ({
            ...prevStatus,
            status: ImportStatus.NOT_STARTED,
          }));
          setStepWithReset(0);
          setSelectedValues({});

          break;
        }

        if (importStatus.status === ImportStatus.IMPORT_FAILED) {
          onClose();
          break;
        }

        setImporting(true);

        const res = await importXlsx(importId);

        if (res.result) {
          setPoll(true);
        }

        if (res.errors) {
          setImporting(false);
        }
        break;
    }
  };

  return (
    <>
      {!importFailed && (
        <Steps
          className={styles.steps}
          current={mountedStep}
          size="small"
          status={stepStatus}
          percent={progress}
          items={[
            {
              title: 'Upload file',
            },
            {
              title: 'Map columns',
            },
            {
              title: 'Data validation',
              className:
                importStatus.status === ImportStatus.SUCCEED_VALIDATION
                  ? styles.successStep
                  : importStatus.status === ImportStatus.FAILED_VALIDATION
                    ? styles.errorStep
                    : undefined,
            },
          ]}
        />
      )}
      {error && (
        <Alert
          className={styles.alert}
          icon={<IconError height="16" width="16" />}
          showIcon
          message={IMPORT_FIELDS_REQUIRED}
          type="error"
        />
      )}

      <ImportModalFirstStep
        className={clsx({ [styles.hidden]: visibleStep !== 0 })}
        importId={importId}
        setStepWithReset={setStepWithReset}
        setProgress={setProgress}
        setImportId={setImportId}
        setDisabled={setDisabled}
        progress={progress}
        mode={mode}
        customerId={customerId}
      />

      {mountedStep >= 1 && (
        <ImportModalSecondStep
          className={clsx({ [styles.hidden]: visibleStep !== 1 })}
          importId={importId}
          setLoading={(loading) => setLoading({ loading })}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          error={error}
          mode={mode}
        />
      )}

      {mountedStep >= 2 && importStatus.validationResult && (
        <>
          <ImportModalThirdStep
            className={clsx({ [styles.hidden]: visibleStep !== 2 })}
            {...importStatus.validationResult}
            error={
              importFailed
                ? {
                    created: importStatus.createdItemsCount || 0,
                    updated: importStatus.updatedItemsCount || 0,
                  }
                : undefined
            }
            importId={importId}
            mode={mode}
            status={importStatus.status}
          />
          {(importing || importFailed) && (
            <div className={styles.progress}>
              <Progress
                percent={
                  importFailed
                    ? (((importStatus.createdItemsCount || 0) +
                        (importStatus.updatedItemsCount || 0)) /
                        importStatus.validationResult.totalEntities) *
                      100
                    : progress
                }
                status={importFailed ? 'exception' : undefined}
                showInfo={importFailed}
              />
              {!importFailed && <Typography.Text>Importing...</Typography.Text>}
            </div>
          )}
          {importFailed && (
            <div className={styles.importError}>
              <Typography.Text className={styles.error}>
                Data import stopped after an issue occurred on row [
                {importStatus.numberOfIssueRow}].
              </Typography.Text>
              <Typography.Text className={styles.errorDescription}>
                Previous rows were successfully imported.
              </Typography.Text>
            </div>
          )}
        </>
      )}

      {!importing && (
        <div className={styles.buttonContainer}>
          {visibleStep !== 0 && (
            <Button
              disabled={disabled}
              className={clsx({ [styles.hidden]: importFailed })}
              onClick={() => {
                setVisibleStep((step) => step - 1 || 0);
              }}
            >
              Back
            </Button>
          )}
          <div className={styles.submitButtonsContainer}>
            <Button
              disabled={disabled}
              className={clsx({ [styles.hidden]: importFailed })}
              onClick={async () => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={disabled || !importId || loading.loading}
              onClick={submit}
              type="primary"
            >
              {visibleStep === 2 ? finalStepLabel : 'Proceed'}
            </Button>
          </div>
        </div>
      )}
      <Loader
        visible={loading.loading}
        text={loading.text}
        className={styles.loader}
      />
    </>
  );
};

export default ImportModalContent;
