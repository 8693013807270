
export enum ActionType {
  ARCHIVE = 'ARCHIVE',
  DECOUPLE_SUPPLIER = 'DECOUPLE_SUPPLIER',
  UNASSIGN_CUSTOMERS_PRODUCT = 'UNASSIGN_CUSTOMERS_PRODUCT',
  DECOUPLE_PRODUCTS_CUSTOMER = 'DECOUPLE_PRODUCTS_CUSTOMER',
  DELETE_INVOICE = 'DELETE_INVOICE',
  DEACTIVATE_USER = 'DEACTIVATE_USER',
  INVITE_USER = 'INVITE_USER',
  RESEND_INVITATION = 'RESEND_INVITATION'
}
