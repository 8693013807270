import { Form } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { CompanyFormFields } from 'src/constants/company';
import { USER_BROADCAST } from 'src/constants/local-storage';
import { ProfileFormFields } from 'src/constants/profile';
import { DEFAULT_COUNTRY_ISO } from 'src/constants/users';
import { useMessageContext } from 'src/contexts/message-context';
import { useUserContext } from 'src/contexts/user-context';
import { IUserInfo } from 'src/types/general';
import { getDialCodeByIso, getPhoneNumber } from 'src/utils/get-dial-code';
import useProfile from './use-profile';

const useUserProfileForm = () => {
  const { updateUserInfo } = useProfile();
  const { showSuccessMessage } = useMessageContext();
  const {
    userContextState: { loading, user },
  } = useUserContext();

  const [form] = Form.useForm<IUserInfo>();

  const firstName = Form.useWatch(ProfileFormFields.FIRST_NAME, form);
  const lastName = Form.useWatch(ProfileFormFields.LAST_NAME, form);
  const jobTitle = Form.useWatch(ProfileFormFields.JOB_TITLE, form);
  const countryCode = Form.useWatch(ProfileFormFields.COUNTRY_CODE, form);
  const phoneNumber = Form.useWatch(ProfileFormFields.PHONE_NUMBER, form);
  const [initialValue, setInitialValue] = useState<IUserInfo | null>(null);

  const dialCode = useMemo(() => {
    return getDialCodeByIso(countryCode || null);
  }, [countryCode]);

  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (!phoneNumber) {
      form.setFieldValue(
        ProfileFormFields.COUNTRY_CODE,
        countryCode || DEFAULT_COUNTRY_ISO
      );
    }
  }, [phoneNumber, countryCode]);

  useEffect(() => {
    const broadcast = new BroadcastChannel(USER_BROADCAST);
    broadcast.postMessage('refresh');
    broadcast.close();
  }, []);

  useEffect(() => {
    if (user) {
      const initialState = {
        ...user,
        phoneNumber: getPhoneNumber(user.phoneNumber, user.countryCode),
      };
      setInitialValue(initialState);
      form.setFieldsValue(initialState);
    }
  }, [user]);

  const isChanged = useMemo(() => {
    if (initialValue?.firstName !== firstName) {
      return true;
    }
    if (initialValue?.lastName !== lastName) {
      return true;
    }
    if (initialValue?.jobTitle !== jobTitle) {
      return true;
    }
    if (initialValue?.phoneNumber !== phoneNumber) {
      return true;
    }
    if (initialValue?.countryCode !== countryCode) {
      return true;
    }

    return false;
  }, [dialCode, phoneNumber, firstName, lastName, jobTitle, initialValue]);

  const resetForm = () => {
    if (!initialValue) {
      return;
    }
    form.setFieldsValue(initialValue);
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(async (data) => {
        setSubmitLoading(true);
        const result = await updateUserInfo({
          ...data,
          [CompanyFormFields.PHONE_NUMBER]: phoneNumber
            ? `${dialCode}${phoneNumber.trim()}`
            : null,
          [CompanyFormFields.COUNTRY_CODE]: phoneNumber
            ? countryCode
            : DEFAULT_COUNTRY_ISO,
        });

        if (!result.errors) {
          const broadcast = new BroadcastChannel(USER_BROADCAST);
          broadcast.postMessage('refresh');
          broadcast.close();
          showSuccessMessage('Changes saved');
        }
        setSubmitLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return {
    resetForm,
    submitForm,
    form,
    dialCode,
    isChanged,
    loading: loading || submitLoading,
    role: user?.role,
  };
};

export default useUserProfileForm;
